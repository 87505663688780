// meed/src/pages/privacy.js
import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import privacyStyles from './styles/privacyStyles';

class Privacy extends Component {
  render() {
    return (
      <div className="container">
        <p>
         To view our privacy policy, please visit the following link:{" "}
          <a
            href="https://app.termly.io/policy-viewer/policy.html?policyUUID=5204406f-a1e5-426e-8f09-9562c283f701"
            target="_blank"
            rel="noopener noreferrer"
          >
             View Privacy Policy
          </a>
        </p>
      </div>
    );
  }
}

Privacy.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(privacyStyles)(Privacy));
