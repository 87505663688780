// meed/src/pages/styles/userStyles.js
import xurl from '../../images/close.png';
import berryBg from '../../images/berryBg-removebg.png';
const userStyles = (theme) => ({
    ...theme.custom,
    userTopElem: {
        overflow: 'hidden'
    },
    wrapper: {
        paddingTop: 69,
        '@media (max-width: 960px)': {
            paddingTop: 57,
        },
    },
    profile: {
        display: 'flex'
    },
    order1: {
        order: 1
    },
    order2: {
        order: 2
    },
    challengeToolBar: {
        position: 'fixed',
        maxWidth: 'inherit',
        width: '100%',
        zIndex: 2,
        '@media (max-width: 960px)': {
            position: 'relative'
        }
    },
    fixed: {
        position: 'fixed !important'
    },
    viewChallengesWrap: {
        zIndex: 0,
    },
    viewChallenges: {
        width: '100%',
        borderRadius: 0,
        background: '#B464FA',
        color: '#fff',
        zIndex: 1,
        transition: 'all .4s',
        display: 'none',
        '&:hover': {
            textShadow: '0 0 black',
            border: 'solid 1px #5B5A57',
            background: '#B464FA'
        },
        '@media (max-width: 960px)': {
            fontSize: 11,
            left: 0,
            display: 'block',
            position: 'fixed',
            width: '50%',
            zIndex: 3,
            bottom: 0
        },
        '@media (max-width: 367px)': {
            fontSize: 8,
        }
    },
    createButton: {
        width: '100%',
        borderRadius: 0,
        background: '#14C7FC',
        color: '#fff',
        zIndex: 1,
        transition: 'all .4s',
        '&:hover': {
            textShadow: '0 0 black',
            border: 'solid 1px #5B5A57',
            background: '#14C7FC'
        },
        '@media (max-width: 864px)': {
            fontSize: '0.675rem',
        },
        '@media (max-width: 960px)': {
            right: 0,
            position: 'fixed',
            zIndex: 3,
            bottom: 0,
            fontSize: '0.875rem'
        },
    },
    filter: {
        width: '100%',
        minHeight: 24,
        borderRadius: 0,
        margin: 0,
        '& div': {
            borderRadius: 0
        }
    },
    filterSummary: {
        margin: 0,
        background: '#6E55D9',
        '& p': {
            color: '#fff'
        },
    },
    filterHeader: {
        fontWeight: 700,
        textTransform: 'uppercase'
    },
    filterSubHeader: {
        fontWeight: 500,
        textTransform: 'uppercase'
    },
    filterOptions: {
        background: '#fff',
        '& p': {
            color: '#5B5A57',
            fill: '#5B5A57',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        '& span': {
            color: '#5B5A57',
            fill: '#5B5A57',
        },
        display: 'flex',
        flexDirection: 'column',
        minHeight: 24,
        borderRadius: 0,
        '& div': {
            borderRadius: 0
        }
    },
    challengeSearch: {
        width: '100%',
        borderRadius: 0,
        borderBottom: 'solid 1px #fff',
        background: '#6E55D9',
        zIndex: 1,
        transition: 'all .4s',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        '& div': {
            cursor: 'pointer',
            color: '#fff',
            padding: '5px',
            textTransform: 'uppercase',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& div:nth-child(2)': {
            fontWeight: 700,
            borderLeft: 'solid 1px #fff',
            borderRight: 'solid 1px #fff',
            padding: '10px 20px',
            '@media (max-width: 864px)': {
                fontSize: '0.675rem',
            },
            '@media (max-width: 960px)': {
                fontSize: '0.875rem'
            }
        },
        '@media (max-width: 960px)': {
            position: 'relative',
        }
    },
    plusIcon: {
        marginRight: 20,
        maxWidth: 15
    },
    plusIcon2: {
        marginRight: 5,
        width: '23px',
        '@media (max-width: 864px)': {
            width: '18px',
        },
        '@media (max-width: 960px)': {
            width: '23px',
        }
    },
    gridSpacingNone: {
        transition: '.25s',
        padding: '0 !important'
    },
    chat: {
        zIndex: 1,
        overflow: 'hidden',
        '&::-webkit-scrollbar': {
            width: '100%',
            height: 4
        },
        '@media (max-width: 960px)': {
            order: 1,
            zIndex: 'unset',
        }
    },
    accordian: {
        backgroundColor: '#fff',
        display: 'none',
        '@media (max-width: 960px)': {
            display: 'block'
        },
        '& .MuiAccordionSummary-expanded': {
            background: 'purple'
        }
    },
    AccordionDetails: {
        backgroundColor: '#E1E0DE',
        padding: 0,
        minHeight: 250,
        '& p': {
            width: '100%'
        }
        // #B464FA
    },
    expandIcon: {
        color: '#fff'
    },
    AccordionSummary: {
        backgroundColor: '#E1E0DE',
        padding: '0 36px',
        '& ..MuiAccordionSummary-expandIcon': {
            right: 17
        }
    },
    challRight: {
        position: 'relative',
        padding: '0 !important',
        '@media (max-width: 960px)': {
            order: 4,
            background: '#E1E0DE'
        }
    },
    hideBg: {
        '@media (max-width: 960px)': {
            height: '32px !important',
            background: 'transparent !important'
        }
    },
    hide: {
        '@media (max-width: 960px)': {
            display: 'none !important'
        }
    },
    hideConfetti: {
        display: 'none'
    },
    challWrapInner: {
        position: 'fixed',
        width: '100%',
        maxWidth: 'inherit',
        background: '#5B5A57',
        height: '100vh',
        overflow: 'auto',
        paddingBottom: 60,
        '&::-webkit-scrollbar': {
            width: '100%',
            height: 4
        },
        '@media (max-width: 960px)': {
            position: 'relative',
            paddingBottom: 36,
        }
    },
    rightChalls: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        paddingBottom: 108,
        marginTop: 125,
        zIndex: 1,
        backgroundImage: `url(${berryBg})`,
        backgroundRepeat: 'repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        '&::-webkit-scrollbar': {
            width: '100%',
            height: 4
        },
        '@media (max-width: 960px)': {
            marginTop: 0,
            paddingBottom: 0,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            position: 'relative',
            backgroundSize: 'unset'
        }
    },
    stream: {
        position: 'relative'
    },
    chatOptions: {
        width: '32px',
        backgroundColor: '#14C7FC',
        position: 'absolute',
        bottom: '-36px',
        right: '0px',
        padding: '5px',
        fontWeight: '700',
        cursor: 'pointer',
        border: 'solid 1px #333',
        '@media (max-width: 960px)': {
            display: 'none',
        }
    },
    challengePanel: {
        transform: 'translateX(100%)',
        position: 'relative',
        height: '100%',
        border: 'solid 1px #E1E0DE',
        '&:hover': {
            border: 'solid 1px #14C7FC',
        },
        '@media (max-width: 960px)': {
            transform: 'translateY(100%)',
            background: '#5B5A57',
            position: 'fixed',
            top: 56,
            left: 0,
            zIndex: 2001,
            width: '100%',
            height: '100%'
        }
    },
    close: {
        '&::before': {
            content: "''",
            display: 'block',
            position: 'absolute',
            zIndex: 1,
            top: 10,
            right: 10,
            width: 30,
            height: 30,
            backgroundImage: `url(${xurl})`,
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer'
        },
    },
    twitch: {
        paddingTop: '56.25%',
        position: 'relative',
        height: 0
    },
    twitchVideo: {
        paddingTop: '56.25%',
        height: 0,

        '& iframe': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0
        }
    },
    twitchChat: {
        height: '100%',
        '& iframe': {
            height: '100%',
            border: 'none'
        },
        '& :first-child': {
            marginBottom: 0
        },
        '@media (max-width: 960px)': {
            '& iframe': {
                height: '100%',
            },
        }
    },
    desktopChat: {
        '@media (max-width: 960px)': {
            display: 'none',
        }
    },
    hideOverflow: {
        overflow: 'hidden'
    },
    challWrap: {
        display: 'flex',
        width: '100%',
        '&:nth-last-child(2)': {
            paddingBottom: 130
        },
        '&:hover': {
            zIndex: 1,
            transition: 'all .4s',
        }
    },
    paddIt: {
        padding: 10
    },
    noTwitch: {
        order: -1,
        height: '100vh',
        '@media (max-width: 960px)': {
            height: 'unset',
        }
    },
    noShot: {
        display: 'none'
    },
    viewing: {
        cursor: 'progress'
    },
    searching: {
        background: '#5B5A57'
    },
    loading: {
        top: 0,
        left: 0,
        zIndex: 3000,
        background: 'rgba(0,0,0,.4)',
        position: 'fixed',
        width: '100%',
        height: '100%'
    },
    progress: {
        position: 'absolute',
        left: 'calc(50%)',
        top: '50%',
        transform: 'translate(50%, -50%)',
        transition: 'none',
        animation: 'none'
    },
    backToTop: {
        display: 'none',
        color: '#fff',
        width: '100%',
        marginTop: 20,
        textAlign: 'center',
        cursor: 'pointer',
        '& span': {
            background: '#5B5A57',
            border: 'solid 1px #fff',
            padding: 5,
        },
        '@media (max-width: 960px)': {
            display: 'block'
        }
    },
    moveIn: {
        transition: '.4s',
        transform: 'translateX(0)',
        '@media (max-width: 960px)': {
            transform: 'translateY(0)',
        }
    },
    moveOut: {
        transform: 'translateX(100%)',
        '@media (max-width: 960px)': {
            transform: 'translateY(100%)',
        }
    },
    searchPadding: {
        paddingTop: 10
    },
    notOnMobile: {
        '@media (max-width: 960px)': {
            display: 'none'
        }
    },
    userIcon: {
        borderRadius: '50%',
        width: 25
    }
});

export default userStyles;