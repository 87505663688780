// meed/src/components/routing/RedirectRoute.tsx
/**
 * @file Custom Route component for handling redirects based on configuration.
 * @summary Provides a flexible and scalable way to manage URL redirects in the Meed application.
 */

import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { redirectMap } from '../../config/redirects';

/**
 * Props for the RedirectRoute component.
 */
interface RedirectRouteProps extends RouteProps {
  fallback?: string;
}

/**
 * A custom Route component that handles redirects based on the configuration in redirects.ts.
 * If no matching redirect is found, it redirects to the specified fallback route.
 *
 * @param props - The component props.
 * @param props.fallback - The fallback route to redirect to if no match is found.
 * @returns A Route component that handles redirection.
 */
const RedirectRoute: React.FC<RedirectRouteProps> = ({ fallback = '/waitlist', ...rest }) => (
  <Route
    {...rest}
    render={({ location }) => {
      try {
        const redirectTo = redirectMap.get(location.pathname.toLowerCase());
        return redirectTo ? <Redirect to={redirectTo} /> : <Redirect to={fallback} />;
      } catch (error) {
        console.error('Error in RedirectRoute:', error);
        return <Redirect to={fallback} />;
      }
    }}
  />
);

export default RedirectRoute;