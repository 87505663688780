// meed/src/redux/reducers/userReducer.ts
import {
  SET_USER,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  LOADING_USER,
  LOADED_USER,
  LIKE_CHALLENGE,
  UNLIKE_CHALLENGE,
  MARK_NOTIFICATIONS_READ,
  SET_CSRF_TOKEN,
  CLEAR_CSRF_TOKEN,
  SET_FIREBASE_USER,
  ADD_TO_WAITLIST,
} from '../types';

// Define types for the state
interface UserState {
  authenticated: boolean;
  loading: boolean;
  credentials: Record<string, any>;
  likes: Array<{
    userHandle: string;
    bio: string;
    challengeId: string;
  }>;
  notifications: Array<{
    read: boolean;
    [key: string]: any;
  }>;
  firebaseUser: any | null;
  modStatuses: Record<string, any>;
  csrfToken?: string | null;
  waitlistEntries: Array<{ email: string }>; // New field for waitlist entries
}

// Define the initial state
const initialState: UserState = {
  authenticated: false,
  loading: true,
  credentials: {},
  likes: [],
  notifications: [],
  firebaseUser: null,
  modStatuses: {},
  waitlistEntries: [], // Initialize waitlist entries as an empty array
};

// Define a type for all possible actions
type UserAction = 
  | { type: typeof SET_AUTHENTICATED }
  | { type: typeof SET_UNAUTHENTICATED }
  | { type: typeof SET_USER; payload: Partial<UserState> }
  | { type: typeof SET_FIREBASE_USER; payload: any }
  | { type: typeof LOADING_USER }
  | { type: typeof LOADED_USER }
  | { type: typeof LIKE_CHALLENGE; payload: { challengeId: string } }
  | { type: typeof UNLIKE_CHALLENGE; payload: { challengeId: string } }
  | { type: typeof MARK_NOTIFICATIONS_READ }
  | { type: typeof SET_CSRF_TOKEN; payload: string }
  | { type: typeof CLEAR_CSRF_TOKEN }
  | { type: typeof ADD_TO_WAITLIST; payload: { email: string } };

function userReducer(state = initialState, action: UserAction): UserState {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true
      };
    case SET_UNAUTHENTICATED:
      return {
        ...state,
        authenticated: false
      };
    case SET_USER:
      return {
        ...state,
        authenticated: true,
        loading: false,
        ...action.payload
      };
    case SET_FIREBASE_USER:
      return {
        ...state,
        firebaseUser: action.payload
      };
    case LOADING_USER:
      return {
        ...state,
        loading: true
      };
    case LOADED_USER:
      return {
        ...state,
        loading: false
      };
    case LIKE_CHALLENGE:
      return {
        ...state,
        likes: [
          ...state.likes,
          {
            userHandle: state.credentials.handle,
            bio: state.credentials.bio,
            challengeId: action.payload.challengeId
          }
        ]
      };
    case UNLIKE_CHALLENGE:
      return {
        ...state,
        likes: state.likes.filter(
          (like) => like.challengeId !== action.payload.challengeId
        )
      };
    case MARK_NOTIFICATIONS_READ:
      return {
        ...state,
        notifications: state.notifications.map(not => ({ ...not, read: true }))
      };
    case SET_CSRF_TOKEN:
      return {
        ...state,
        csrfToken: action.payload
      };
    case CLEAR_CSRF_TOKEN:
      return {
        ...state,
        csrfToken: null
      };
    case ADD_TO_WAITLIST:
      return {
        ...state,
        waitlistEntries: [...state.waitlistEntries, action.payload]
      };
    default:
      return state;
  }
}

export default userReducer;