// meed/src/components/layout/styles/SearchUserStyles.js
const SearchUserStyles = {
    active: {
        background: '#FFDF57 !important',
        color: '#5B5A57 !important'
    },
    searchHeader: {
        background: '#B464FA',
        color: '#fff',
        fontWeight: 700,
        textTransform: 'uppercase',
        width: '100%',
        marginTop: 64,
        display: 'flex',
        flexDirection: 'row',
        padding: '10px 32px',
        '& div': {
            padding: '6px 8px'
        },
        '& button': {
            marginLeft: 10,
            background: '#5D5294',
            color: '#B464FA',
            borderRadius: 0
        },
    },
    card: {
        position: 'relative',
        display: 'flex',
        marginBottom: 20,
        borderRadius: '20px'
    },
    image: {
        minWidth: 200
    },
    // content: {
    //   padding: 25,
    //   objectFit: 'cover'
    // },
    liveTip: {
        backgroundColor: '#FFDF57',
        position: 'absolute',
        left: '32px',
        top: '52px',
        padding: '5px',
        fontWeight: '700'
    },
    challWrap: {
        width: '100%',
        position: 'absolute',
        height: '100%',
    },
    challs: {
        width: '100%',
        flexDirection: 'column',
        height: '100%',
    },
    challsBox: {
        marginTop: 20,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
        // '&::-webkit-scrollbar': {
        //   width: '100%',
        //   height: 4
        // },
        // '&::-webkit-scrollbar-track': {
        //   '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        //   outline: '1px solid slategrey'
        // },
        // '&::-webkit-scrollbar-thumb': {
        //   backgroundColor: '#B464FA',
        //   outline: '1px solid slategrey'
        // }
    },
    userCard: {
        border: 'solid 2px #7B5DF9',
        position: 'relative',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '0px',
        minWidth: '164px',
        minHeight: '146px',
        width: '100%',
        height: '100%',
        color: '#fff',
        transition: 'all .4s',
        '&::before': {
            zIndex: 1,
            position: 'absolute',
            left: 0,
            top: 0,
            content: '""',
            width: '100%',
            height: '100%',
            background: 'transparent linear-gradient(180deg, #6B55D200 0%, #3F3184 100%) 0% 0% no-repeat padding-box'
        }
    },
    yellowCircle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFDF57',
        color: '#5B5A57',
        borderRadius: '50%',
        position: 'absolute',
        top: '10px',
        right: '10px',
        width: '50px',
        height: '50px'
    },
    hideOverflow: {
        position: 'absolute',
        bottom: 20,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#fff',
        paddingRight: '55px',
        fontWeight: '500',
        fontSize: '1.5rem'
    },
    verifiedUser: {
        display: 'flex',
        marginBottom: '5px',
        color: '#fff',
        textTransform: 'uppercase'
    },
    profileImage: {
        position: 'absolute',
        width: '100%',
        objectFit: 'cover',
        cursor: 'pointer'
    },
    profileImage2: {
        background: '#000',
        position: 'absolute',
        width: '100%',
        objectFit: 'cover',
        cursor: 'pointer'
    },
    content: {
        zIndex: 1,
        objectFit: 'cover',
        overflow: 'hidden',
        paddingBottom: '6px !important',
        padding: '16px',
        color: '#fff'
    },
    horizLine: {
        background: '#B464FA',
        width: '100%',
        height: 2
    },
    homeTitle: {
        padding: '5px 20px',
        fontSize: 20,
        textTransform: 'uppercase',
        marginBottom: 0,
        color: '#FFDF57'
    },
    searchUser: {
        background: '#B464FA',
        padding: 20,
        color: '#fff',
        height: '100%',
        textAlign: 'center'
    },
    searchInputDad: {
        width: '100%',
        textAlign: 'left',
    },
    searchInput: {
        padding: '0 20px',
        '& li': {
            width: '100%'
        },
        '& .ais-Hits-list': {
            listStyleType: 'none !important',
            margin: 0,
            padding: 0,
        },
        '& .ais-Hits-item': {
            width: 'unset'
        },
        '& button': {
            display: 'none'
        },
        '& input': {
            borderRadius: 27,
            padding: 15,
            color: '#fff !important',
            border: 'solid 1px rgba(0, 0, 0, 0.23)',
            background: '#6B55D2',
            height: 54,
            width: '100%',
            margin: 10,
            marginLeft: 0,
            outline: 'none',
            fontSize: '1rem',
            '@media (max-width: 600px)': {
                width: '100%'
            },
            '&::placeholder': {
                color: '#fff',
                opacity: 1, /* Firefox */
            },

            '&::-ms-input-placeholder': { /* Internet Explorer 10-11 */
                color: '#fff',
            },

            '&::-ms-input-placeholder': { /* Microsoft Edge */
                color: '#fff',
            },
        },

    },
    results: {
        width: 'unset',
        padding: '0 20px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row !important',
        overflow: 'hidden',
        transition: 'all .4s',
        '&::-webkit-scrollbar': {
            width: '100%',
            height: 4
        },
        '& ul': {
            justifyItems: 'center',
            display: 'grid !important',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            rowGap: '10px',
            columnGap: '10px',
            listStyleType: 'none',
            margin: 0,
            padding: 0,
            maxHeight: 146
        }
    },
    resultsExpanded: {
        width: 'unset',
        padding: '0 20px',
        paddingBottom: 20,
        zIndex: 1,
        background: '#7B5DF9',
        maxHeight: '430px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row !important',
        overflow: 'auto',
        transition: 'all .4s',
        '&::-webkit-scrollbar': {
            background: '#6B55D2',
            height: 4,
            width: 12
        },
        /* Track */
        '&::-webkit-scrollbar-track': {
            background: '#6B55D2',
        },

        /* Handle */
        '&::-webkit-scrollbar-thumb': {
            background: '#E3E3E3',
        },
        '& ul': {
            justifyItems: 'center',
            display: 'grid !important',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            rowGap: '10px',
            columnGap: '10px',
            listStyleType: 'none',
            margin: 0,
            padding: 0,
            height: '100%',
        }
    },
    active: {
        border: 'solid 2px #fff !important',
    },
    searchCategory: {
        marginTop: 40,
        marginBottom: 8,
        '& p': {
            color: '#E3E3E3',
            padding: '0 20px',
            margin: 0
        }
    },
    caret: {
        marginLeft: 10,
        padding: 2,
        cursor: 'pointer'
    },
    rotate180: {
        transform: 'rotate(180deg)'
    },
    overflow: {
        overflow: 'auto'
    }
};

export default SearchUserStyles;