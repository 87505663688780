// meed/src/pages/styles/integrationsDiscordStyles.js
import thumb from '../../images/topBanner.jpg';


const integrationsDiscordStyles = (theme) => ({
  ...theme.custom,
  contactSection: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  contactButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  contactButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  discordButton: {
    backgroundColor: '#7289DA', // Discord color
    '&:hover': {
      backgroundColor: '#5b73c7',
    }
  },

  // Main Container
  team: {
    background: "#9322D4",
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch", // Changed from "center" to "stretch"
  },

  // Reel Wrapper
  reelWrap: {
    position: "relative",
    width: "100%",
    // Removed height and overflow to allow content to dictate the size
  },

  // Grid Spacing None
  gridSpacingNone: {
    // Assuming this is defined in theme.custom or elsewhere
  },

  // Top Joint
  topJoint: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "50vh", // Allows content to expand beyond 50vh if necessary
    backgroundImage: `url(${thumb})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "relative",
  },
  // Main Grid
  mainGrid: {
    height: "100%",
    position: "relative",
    padding: "50px 100px",
    "& div": {
      boxShadow: "none",
    },
    "@media (max-width: 960px)": {
      padding: 20,
    },
    "@media (max-width: 600px)": {
      width: "100%",
      textAlign: "center",
    },
  },

  // CTA Section
  cta: {
    width: "100%",
    maxWidth: "1200px",
    color: "#FFF",
    textAlign: "center",
    padding: "20px",
    // Removed absolute positioning
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& h1": {
      fontFamily: '"Roboto", sans-serif',
      fontSize: "3em",
      margin: 0,
      "@media (max-width: 960px)": {
        fontSize: "2em",
      },
      "@media (max-width: 600px)": {
        fontSize: "1.5em",
      },
    },
  },

  // CTA Text
  ctaText: {
    "& h1": {
      fontFamily: '"Roboto", sans-serif',
      fontSize: "4em", // Slightly larger for more impact
      fontWeight: 700,
      margin: 0,
      letterSpacing: "0.02em",
      textShadow: "2px 2px 4px rgba(0,0,0,0.5)", // Adds depth
      "@media (max-width: 960px)": {
        fontSize: "3em",
      },
      "@media (max-width: 600px)": {
        fontSize: "2.5em",
      },
    },
    "& h2": {
      fontFamily: '"Roboto", sans-serif',
      fontSize: "1.5em",
      fontWeight: 400,
      margin: "15px 0 0 0",
      opacity: 0.9, // Slightly less prominent than h1
      maxWidth: "80%", // Ensures the subtitle doesn't stretch too wide
      lineHeight: 1.4,
      "@media (max-width: 960px)": {
        fontSize: "1.2em",
        maxWidth: "90%",
      },
      "@media (max-width: 600px)": {
        fontSize: "1em",
        maxWidth: "100%",
      },
    },
    animation: "$fadeInUp 1.5s ease-out",
  },

  // If you want to add a subtle animation to draw attention
"@keyframes fadeInUp": {
  from: {
    opacity: 0,
    transform: "translateY(20px)"
  },
  to: {
    opacity: 1,
    transform: "translateY(0)"
  }
},

  // CTA Section
  ctaSection: {
    fontWeight: 700,
    justifyContent: "center",
    margin: "40px",
  },

  // Submit Button
  submitButton: {
    minWidth: 100,
    background:
      "transparent linear-gradient(270deg, #00B4FF 0%, #565FFF 47%, #B600FF 100%) 0% 0% no-repeat padding-box",
    color: "#fff",
    padding: "16px 36px",
    opacity: 1,
    transition: "all .4s",
    borderRadius: 80,
    fontSize: ".75rem",
    "&:hover": {
      opacity: 0.9,
    },
    "@media (max-width: 960px)": {
      padding: "12px 24px",
      fontSize: ".7rem",
    },
    // Removed absolute positioning
    marginTop: "20px", // Added margin for spacing
  },

  // More Wrap
  moreWrap: {
    width: "100%",
    background: "rgba(236, 236, 236, 1)",
    padding: "40px 0", // Remove horizontal padding
    display: "flex",
    justifyContent: "center",
    alignItems: 'center'
  },

  // Need Help Description Format
  needHelpDescFormat: {
    maxWidth: "600px",
    padding: "0 20px",
    "@media (max-width: 960px)": {
      maxWidth: "100%",
      padding: "0 20px 20px",
    },
  },

  // pTextSize
  pTextSize: {
    fontSize: "1.15em !important",
    fontFamily: '"Roboto", sans-serif',
    fontWeight: 500,
    textAlign: "left",
  },

  // pTextSizeBold
  pTextSizeBold: {
    fontSize: "1.15em !important",
    fontFamily: '"Roboto", sans-serif',
    fontWeight: 700,
    textAlign: "left",
  },

  // Need Help Integration Format
  needHelpIntegFormat: {
    maxWidth: "600px",
    padding: "0 20px",
    "@media (max-width: 960px)": {
      maxWidth: "100%",
      padding: "20px 20px 0",
    },
  },

  // Integration Section
  integrationSection: {
    fontWeight: 700,
    verticalAlign: "middle",
    padding: "40px",
    minWidth: 100,
    display: 'flex',
    alignItems: 'center',
    background: "#7B5DF9",
    color: "#fff",
    opacity: 1,
    borderRadius: "80px",
    fontSize: "1.25em",
    "@media (max-width: 1450px)": {
      display: "flex",
      flexDirection: "column",
      flexWrap: "unset",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  // Integration Section Text 1
  integrationSectionText1: {
    minWidth: 100,
    maxWidth: "100%",
    opacity: 1,
    borderRadius: 8,
    fontSize: "1em",
  },

  // Integration Section Text 2
  integrationSectionText2: {
    display: "flex",
    justifyContent: "center !important",
    minWidth: 215,
    maxWidth: "100%",
    color: "#fff",
    padding: "8px 20px",
    opacity: 1,
    borderRadius: 8,
    fontSize: "1.3em",
  },

  // Start Integration Button
  startIntegrationButton: {
    minWidth: 100,
    maxWidth: 250,
    width: '100%',
    background:
      "transparent linear-gradient(270deg, #947BFF 0%, #947BFF 47%, #947BFF 100%) 0% 0% no-repeat padding-box",
    color: "#fff",
    padding: "16px 20px", // Fixed '16x' to '16px'
    opacity: 1,
    transition: "all .4s",
    borderRadius: 80,
    fontSize: ".6em",
    fontWeight: 'bold',
    boxShadow: "none",
  },

  // Command Container Format 1
  commandContainerFormat1: {
    width: "100%",
    backgroundColor: "rgba(236,236,236,1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  // Command Header Button
  commandHeaderButton: {
    minWidth: '100px',
    height: 50,
    background: "#D5D5D5",
    color: "#fff",
    padding: "0 15px",
    marginRight: "15px",
    opacity: 1,
    borderRadius: "35px",
    fontSize: "0.9em",
    textTransform: "none",
    whiteSpace: 'nowrap',
    flexShrink: 0, // Prevent shrinking
    '&:last-child': {
      marginRight: 0, // Remove margin from last button
    },
    '@media (max-width: 960px)': {
      fontSize: '0.85em',
      padding: "0 12px",
      marginRight: "10px",
    },
    '@media (max-width: 600px)': {
      fontSize: '0.8em',
      padding: "0 10px",
    },
  },

  // Command Active Button
  commandActiveButton: {
    minWidth: '100px',
    width: 'auto',
    padding: "0 15px",
    fontSize: "0.9em",
    whiteSpace: 'nowrap',
    background: "#7B5DF9",
    color: "#fff !important",
    marginRight: "15px",
    opacity: 1,
    borderRadius: "35px",
    textTransform: "none",
    '@media (max-width: 960px)': {
      fontSize: '0.85em',
    },
    '@media (max-width: 600px)': {
      fontSize: '0.8em',
    },
  },

  // Tab Header
  tabHeader: {
    boxShadow: 'none',
    width: '100%',
    backgroundColor: "rgba(236,236,236,1)",
    '& .MuiTabs-flexContainer': {
      justifyContent: 'center', // Center on desktop
    },
    '& .MuiTabs-scroller': {
      overflow: 'hidden !important', // Hide scrollbar on desktop
    },
    '@media (max-width: 960px)': {
      '& .MuiTabs-flexContainer': {
        justifyContent: 'flex-start', // Allow scrolling on mobile
      },
      '& .MuiTabs-scroller': {
        overflowX: 'auto !important', // Enable horizontal scrolling on mobile
      },
    },
  },

  // Command List Format
  commandListFormat: {
    padding: '30px 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center', // Center on desktop
    '@media (max-width: 960px)': {
      justifyContent: 'flex-start', // Align to start on mobile
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        height: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.2)',
        borderRadius: '3px',
      },
    },
  },
  // Tab Panel
  tabPanel: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },

  // Mid
  mid: {
    height: "100%",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    justifyContent: "center !important",
    "& p": {
      fontSize: "2em",
      "@media (max-width: 960px)": {
        fontSize: "1.05em",
      },
      "@media (max-width: 600px)": {
        fontSize: "1.5em",
      },
    },
    "@media (max-width: 600px)": {
      height: "unset",
    },
  },

  // Command Description
commandDescription: {
  paddingBottom: "25px",
  fontSize: "1.25em !important",
  paddingRight: "50px",
  lineHeight: "1.6",  // Add this line for better readability
  "& br": {
    content: '""',
    display: "block",
    marginBottom: "0.5em",  // Adjust this value to control spacing between lines
  },
},

  // Command Example
  commandExample: {
    justifyContent: "center !important",
    minWidth: 100,
    maxWidth: 250,
    width: '100%',
    background: "#886EFE",
    color: "#fff",
    padding: "8px 20px",
    opacity: 1,
    borderRadius: 8,
    fontSize: "1.0em",
    fontWeight: 'bold',
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    textTransform: "none",
  },

  // Command Container Format
  commandContainerFormat: {
    width: '100%',
    maxWidth: '1200px',
    padding: '0 20px',
    "@media (max-width: 960px)": {
      padding: '0 10px',
    },
  },

  // Command Format 1
  commandFormat1: {
    padding: 0,
    paddingTop: 0,
    paddingLeft: "120px",
    paddingRight: "120px",
  },

  // Command Format 2
  commandFormat2: {
    paddingLeft: "25px",
    paddingRight: "25px",
    background: "#F4F4F4",
    marginBottom: "20px",
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 960px)": {
      display: "none",
    },
  },

  // Command Format 3
  commandFormat3: {
    padding: "15px 90px",
    marginBottom: "20px",
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    background:
      "transparent linear-gradient(180deg, #7A5DF9 0%, #6C4FEA 100%) 0% 0% no-repeat padding-box",
    "@media (max-width: 960px)": {
      padding: "15px 20px",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      flexWrap: "unset",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  // Jobs (Image styling)
  jobs: {
    width: 82,
    height: 82,
  },

  // Thick (Title styling)
  thick: {
    fontSize: "3em !important",
    fontWeight: 600,
    margin: "15px 0px !important",
  },

  // CTA Text Card
  ctaTextCard: {
    width: "100%",
    zIndex: 1,
    color: "#fff", // Ensures white color for text
    fontSize: ".785em",
    textAlign: "left",
    "& img": {
      width: 50,
    },
    "& p": {
      textAlign: "left",
    },
  },
});

export default integrationsDiscordStyles;

