// meed/src/util/banLevelValues.ts

/**
 * Defines the structure for ban level descriptions.
 */
type BanLevelDescriptions = {
    [key: string]: string;
  };
  
  /**
   * Mapping of ban level identifiers to user-friendly descriptions.
   */
  const banLevelDescriptions: BanLevelDescriptions = {
    "30min": "30 Minutes",
    "1day": "1 Day",
    "1week": "1 Week",
    "1month": "1 Month",
    "permanent": "Permanent"
  };
  
  /**
   * Converts a ban level identifier from the backend into a user-friendly description.
   * 
   * @param level - The level of the ban as identified in the backend system.
   * @returns A user-friendly description of the ban duration. Returns "Unknown Duration"
   *          if the provided level does not match any known ban levels.
   */
  function getBanLevelDescription(level: string): string {
    return banLevelDescriptions[level] || "Unknown Duration";
  }
  
  export { getBanLevelDescription };