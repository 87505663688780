// src/redux/actions/notificationActions.ts

import axios from 'axios';
import { Dispatch } from 'redux';
import { 
  LOADING_UI, 
  STOP_LOADING_UI, 
  SHOW_TOAST 
} from '../types';
import { API_BASE_URL } from '../../util/config';
import handleError, { ExtendedError } from '../../util/errorHandler';
axios.defaults.withCredentials = true;
// Types for our action payloads
interface UnsubscribeResponse {
  success: boolean;
  message: string;
  data: {
    email: string;
    category: string;
    timestamp: string;
  };
}

// Define action types
interface LoadingUIAction {
  type: typeof LOADING_UI;
}

interface StopLoadingUIAction {
  type: typeof STOP_LOADING_UI;
}

interface ShowToastAction {
  type: typeof SHOW_TOAST;
  payload: string;
}

// Union type for all possible actions
type NotificationActionTypes = 
  | LoadingUIAction 
  | StopLoadingUIAction 
  | ShowToastAction;

/**
 * Process an unsubscribe request using the provided token.
 *
 * @param {string} token - The unsubscribe token from the email link
 * @returns {Function} - Async function that dispatches actions and returns a Promise
 */
export const processUnsubscribe = (token: string) => 
  async (dispatch: Dispatch<NotificationActionTypes>): Promise<UnsubscribeResponse> => {
    dispatch({ type: LOADING_UI });

    try {
      const res = await axios.get<UnsubscribeResponse>(
        `${API_BASE_URL}/users/unsubscribe`,
        {
          params: { token }
        }
      );
      
      if (res.data) {
        dispatch({
          type: SHOW_TOAST,
          payload: 'Successfully unsubscribed from notifications.'
        });
        return res.data;
      }

      throw new Error('No data received from server');
    } catch (error) {
      console.error('Error processing unsubscribe:', error);
      handleError(error as ExtendedError, dispatch, 'processUnsubscribe');
      throw error;
    } finally {
      dispatch({ type: STOP_LOADING_UI });
    }
  };

// Export types for use in components and reducers
export type { 
  UnsubscribeResponse,
  NotificationActionTypes
};