// meed/src/config/authConfig.ts

import { 
  DISCORD_CLIENT_ID, 
  DISCORD_REDIRECT_URI, 
  STREAMLABS_CLIENT_ID, 
  STREAMLABS_REDIRECT_URI, 
  TWITCH_CLIENT_ID, 
  TWITCH_REDIRECT_URI 
} from "../util/config";

/**
 * Interface for OAuth authentication configuration.
 */
interface AuthConfig {
  clientId: string;
  redirectUri: string;
}

/**
 * Type for platforms supported in the auth configuration.
 */
type SupportedPlatform = 'twitch' | 'discord' | 'streamlabs' | 'tiktok';

/**
 * Configuration for OAuth authentication for various platforms.
 */
const authConfig: Record<SupportedPlatform, AuthConfig> = {
  twitch: {
    clientId: TWITCH_CLIENT_ID,
    redirectUri: TWITCH_REDIRECT_URI,
  },
  discord: {
    clientId: DISCORD_CLIENT_ID,
    redirectUri: DISCORD_REDIRECT_URI,
  },
  streamlabs: {
    clientId: STREAMLABS_CLIENT_ID,
    redirectUri: STREAMLABS_REDIRECT_URI,
  },
  tiktok: {
    get clientId(): string {
      throw new Error("TikTok clientId not implemented. Please provide the clientId.");
    },
    get redirectUri(): string {
      throw new Error("TikTok redirectUri not implemented. Please provide the redirectUri.");
    },
  },
  // Add other platforms as needed
};

export default authConfig;