// meed/src/redux/types.ts
// User reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';
export const LOADED_USER = 'LOADED_USER';
export const SET_FIREBASE_USER = 'SET_FIREBASE_USER';
export const SET_INFLUENCER = 'SET_INFLUENCER';
export const LOADING_INFLUENCER = 'LOADING_INFLUENCER';
export const MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATIONS_READ';
export const ADD_TO_WAITLIST = 'ADD_TO_WAITLIST';
// UI reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_DATA = 'LOADING_DATA';
export const LOADED_DATA = 'LOADED_DATA';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';
export const SHOW_NAV = 'SHOW_NAV';
export const HIDE_NAV = 'HIDE_NAV';
// Data reducer types
export const SET_CHALLENGES = 'SET_CHALLENGES';
export const SET_CHALLENGE = 'SET_CHALLENGE';
export const SET_ACCEPTING_USERS = 'SET_ACCEPTING_USERS';
export const SET_COMPLETED_CHALLENGES = 'SET_COMPLETED_CHALLENGES';
export const SET_STAFF_CHALLENGES = 'SET_STAFF_CHALLENGES';
export const LIKE_CHALLENGE = 'LIKE_CHALLENGE';
export const UNLIKE_CHALLENGE = 'UNLIKE_CHALLENGE';
export const DELETE_CHALLENGE = 'DELETE_CHALLENGE';
export const POST_CHALLENGE = 'POST_CHALLENGE';
export const POST_SUGGESTION = 'POST_SUGGESTION';
export const SUBMIT_COMMENT = 'SUBMIT_COMMENT';
export const GET_TOTAL_RAISED = 'GET_TOTAL_RAISED';
export const ACCEPT_CHALLENGE = 'ACCEPT_CHALLENGE';
export const DECLINE_CHALLENGE = 'DECLINE_CHALLENGE';
export const COMPLETE_CHALLENGE = 'COMPLETE_CHALLENGE';
export const FAIL_CHALLENGE = 'FAIL_CHALLENGE';
export const CANCEL_CHALLENGE = 'CANCEL_CHALLENGE';
export const BOOST_CHALLENGE = 'BOOST_CHALLENGE';
export const REPORT_CHALLENGE = 'REPORT_CHALLENGE';
export const OTHERS_DECIDE_CHALLENGE = 'OTHERS_DECIDE_CHALLENGE';
export const CAST_PASS_VOTE = 'CAST_PASS_VOTE';
export const CAST_FAIL_VOTE = 'CAST_FAIL_VOTE';
export const REMOVE_VOTE = 'REMOVE_VOTE';
// Proof
export const SUBMIT_PROOF_SUCCESS = 'SUBMIT_PROOF_SUCCESS';
export const SUBMIT_PROOF_FAIL = 'SUBMIT_PROOF_FAIL';
export const ADD_PROOF_DATA = 'ADD_PROOF_DATA';
export const CLEAR_PROOF_DATA = 'CLEAR_PROOF_DATA';
// CSRF tokens
export const SET_CSRF_TOKEN = 'SET_CSRF_TOKEN';
export const CLEAR_CSRF_TOKEN = 'CLEAR_CSRF_TOKEN';
export const CSRF_FAILURE = 'CSRF_FAILURE';
// Rate limiter actions
export const RATE_LIMIT_FAILURE = 'RATE_LIMIT_FAILURE';
export const SHOW_TOAST = "SHOW_TOAST";
export const CLEAR_TOAST_MESSAGE = 'CLEAR_TOAST_MESSAGE';
// Games (from search)
export const SET_GAMES = 'SET_GAMES';
// Mass Challenge action types
export const CREATE_MASS_CHALLENGE = 'CREATE_MASS_CHALLENGE';
export const ACCEPT_MASS_CHALLENGE = 'ACCEPT_MASS_CHALLENGE';
export const DECLINE_MASS_CHALLENGE = 'DECLINE_MASS_CHALLENGE';
export const SET_MASS_CHALLENGE_DETAILS = 'SET_MASS_CHALLENGE_DETAILS';
export const SET_PARTICIPANT_STATUS = 'SET_PARTICIPANT_STATUS';
export const SET_MASS_CHALLENGES = 'SET_MASS_CHALLENGES'; // For listing mass challenges
export const LOADING_MASS_CHALLENGE = 'LOADING_MASS_CHALLENGE';
export const LOADED_MASS_CHALLENGE = 'LOADED_MASS_CHALLENGE';

export type ActionType =
  | 'SET_AUTHENTICATED'
  | 'SET_UNAUTHENTICATED'
  | 'SET_USER'
  | 'LOADING_USER'
  | 'LOADED_USER'
  | 'SET_FIREBASE_USER'
  | 'SET_INFLUENCER'
  | 'LOADING_INFLUENCER'
  | 'MARK_NOTIFICATIONS_READ'
  | 'SET_ERRORS'
  | 'LOADING_UI'
  | 'CLEAR_ERRORS'
  | 'LOADING_DATA'
  | 'LOADED_DATA'
  | 'STOP_LOADING_UI'
  | 'SHOW_NAV'
  | 'HIDE_NAV'
  | 'SET_CHALLENGES'
  | 'SET_CHALLENGE'
  | 'SET_ACCEPTING_USERS'
  | 'SET_COMPLETED_CHALLENGES'
  | 'SET_STAFF_CHALLENGES'
  | 'LIKE_CHALLENGE'
  | 'UNLIKE_CHALLENGE'
  | 'DELETE_CHALLENGE'
  | 'POST_CHALLENGE'
  | 'POST_SUGGESTION'
  | 'SUBMIT_COMMENT'
  | 'GET_TOTAL_RAISED'
  | 'ACCEPT_CHALLENGE'
  | 'DECLINE_CHALLENGE'
  | 'COMPLETE_CHALLENGE'
  | 'FAIL_CHALLENGE'
  | 'CANCEL_CHALLENGE'
  | 'BOOST_CHALLENGE'
  | 'REPORT_CHALLENGE'
  | 'OTHERS_DECIDE_CHALLENGE'
  | 'CAST_PASS_VOTE'
  | 'CAST_FAIL_VOTE'
  | 'REMOVE_VOTE'
  | 'SUBMIT_PROOF_SUCCESS'
  | 'SUBMIT_PROOF_FAIL'
  | 'ADD_PROOF_DATA'
  | 'CLEAR_PROOF_DATA'
  | 'SET_CSRF_TOKEN'
  | 'CLEAR_CSRF_TOKEN'
  | 'CSRF_FAILURE'
  | 'RATE_LIMIT_FAILURE'
  | 'SHOW_TOAST'
  | 'CLEAR_TOAST_MESSAGE'
  | 'SET_GAMES'
  // Mass Challenge action types
  | 'CREATE_MASS_CHALLENGE'
  | 'ACCEPT_MASS_CHALLENGE'
  | 'DECLINE_MASS_CHALLENGE'
  | 'SET_MASS_CHALLENGE_DETAILS'
  | 'SET_PARTICIPANT_STATUS'
  | 'SET_MASS_CHALLENGES'
  | 'LOADING_MASS_CHALLENGE'
  | 'LOADED_MASS_CHALLENGE';