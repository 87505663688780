// meed/src/util/ProtectedRoute.js
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/**
 * A higher-order component for handling protected routes in a React application.
 * It wraps around the `Route` component from `react-router-dom` and uses Redux
 * to determine if the user is authenticated. If the user is not authenticated,
 * it redirects them to the signup page, otherwise, it renders the intended
 * component associated with the route.
 *
 * @param {Object} props - The props passed to the ProtectedRoute component.
 * @param {React.Component} props.component - The component that should be rendered for the protected route.
 * @param {boolean} props.authenticated - The authentication status of the user, derived from Redux state.
 * @param {Object} rest - Additional props passed to the Route component (e.g., path, exact).
 * @returns {React.Component} - A Route component that conditionally renders the passed component or redirects based on authentication status.
 */
const ProtectedRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authenticated !== true ? <Redirect to="/signup" /> : <Component {...props} />
    }
  />
);

// Maps Redux state to component props to provide the authentication status.
const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated
});

// PropTypes validation for the ProtectedRoute component.
ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  authenticated: PropTypes.bool.isRequired
};

// Connects the ProtectedRoute component to the Redux store.
export default connect(mapStateToProps)(ProtectedRoute);
