// meed/src/util/AuthRoute.js
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/**
 * A higher-order component for handling routes that should only be accessible by unauthenticated users.
 * It wraps around the `Route` component from `react-router-dom` and uses Redux to determine if the user is
 * authenticated. If the user is authenticated, it redirects them to the home page, otherwise, it renders
 * the intended component associated with the route.
 *
 * This is typically used for routes like login, signup, and forgot password, where authenticated users
 * should not have access and should be redirected to a default authenticated route instead (e.g., the home page).
 *
 * @param {Object} props - The props passed to the AuthRoute component.
 * @param {React.Component} props.component - The component that should be rendered for the route if the user is unauthenticated.
 * @param {boolean} props.authenticated - The authentication status of the user, derived from Redux state.
 * @param {Object} rest - Additional props passed to the Route component (e.g., path, exact).
 * @returns {React.Component} - A Route component that conditionally renders the passed component or redirects based on authentication status.
 */
const AuthRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authenticated === true ? <Redirect to="/home" /> : <Component {...props} />
    }
  />
);

// Maps Redux state to component props to provide the authentication status.
const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated
});

// PropTypes validation for the AuthRoute component.
AuthRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  authenticated: PropTypes.bool.isRequired
};

// Connects the AuthRoute component to the Redux store.
export default connect(mapStateToProps)(AuthRoute);
