// meed/src/util/admin.ts
/**
 * @file Firebase and Axios initialization module for the Meed platform.
 * This module handles the initialization of Firebase services and configures
 * global Axios settings based on the current environment configuration.
 * 
 * @module admin
 * @requires firebase/app
 * @requires firebase/analytics
 * @requires firebase/storage
 * @requires firebase/auth
 * @requires axios
 * 
 * @example
 * import { auth, storageRef } from '../util/admin';
 * 
 * // Use Firebase auth
 * const user = auth.currentUser;
 * 
 * // Use Firebase storage
 * const fileRef = ref(storageRef, 'path/to/file');
 */

import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { environments } from '../config/environments';

/**
 * Current environment type from environment variables
 * @type {string}
 * @default 'local'
 */
const currentEnv = process.env.REACT_APP_ENV_TYPE || 'local';
const config = environments[currentEnv];

if (!config) {
  throw new Error(`Invalid environment: ${currentEnv}`);
}

/**
 * Firebase app instance
 * @type {import('firebase/app').FirebaseApp}
 */
let app;

/**
 * Firebase storage reference
 * @type {import('firebase/storage').FirebaseStorage}
 */
let storageRef;

try {
  console.log("REACT_APP_ENV_TYPE:", currentEnv);
  
  // Initialize Firebase
  app = initializeApp(config.firebase);
  storageRef = getStorage(app);
  getAnalytics(app);

  // Configure axios
  axios.defaults.baseURL = config.api.baseUrl;
  axios.defaults.withCredentials = true;

} catch (err) {
  console.error('Error initializing app:', err);
}

/**
 * Firebase Authentication instance
 * @type {import('firebase/auth').Auth}
 */
const auth = getAuth(app);

/**
 * @exports storageRef - Firebase Storage reference for file operations
 * @type {import('firebase/storage').FirebaseStorage}
 */

/**
 * @exports auth - Firebase Authentication instance for user management
 * @type {import('firebase/auth').Auth}
 */
export {
  storageRef,
  auth
};