// meed/src/redux/store.ts
import { createStore, combineReducers, applyMiddleware, compose, Store, AnyAction } from 'redux';
import { thunk, ThunkMiddleware, ThunkAction, ThunkDispatch }  from 'redux-thunk';

import userReducer from './reducers/userReducer';
import dataReducer, { DataState } from './reducers/dataReducer';
import uiReducer from './reducers/uiReducer';
import { errorHandlingMiddleware } from './middleware/errorHandlingMiddleware';

export interface RootState {
  user: ReturnType<typeof userReducer>;
  data: DataState;
  UI: ReturnType<typeof uiReducer>;
}

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;

const middleware = [
  thunk as ThunkMiddleware<RootState, AnyAction>,
  errorHandlingMiddleware as ThunkMiddleware<RootState, AnyAction>
];

const reducers = combineReducers({
  user: userReducer,
  data: dataReducer,
  UI: uiReducer
});

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers =
  (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const enhancer = composeEnhancers(applyMiddleware(...middleware));

const store: Store<RootState, AnyAction> = createStore(reducers, {}, enhancer);

export type AppDispatch = ThunkDispatch<RootState, unknown, AnyAction> & typeof store.dispatch;

export default store;