// meed/src/pages/integrationsDiscord.js
/**
 * @file integrationsDiscord.js
 * @summary This file manages the integration of MeedBot commands with Discord. It provides a user interface for displaying various
 * MeedBot commands and their descriptions in a tabbed layout, allowing users to interact with and understand the MeedBot functionality.
 * Each tab represents different categories of commands such as challenges, proof submission, user verification, and more.
 */
import React, { Component, Fragment } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
// MUI Stuff
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../components/layout/TabPanel';
// Redux stuff
import { connect } from 'react-redux';
import swords from '../images/swords.png';

import integrationsDiscordStyles from './styles/integrationsDiscordStyles';
import { Link } from 'react-router-dom';
import { DISCORD_MEED_SERVER } from '../util/config';

/**
 * Tab labels corresponding to their indices.
 * These labels define the categories of commands that will be displayed in each tab.
 */
const tabLabels = [
  'All',
  'Quick Start',
  'Challenge Management',
  'Proof Handling',
  'User Management',
  'Help'
];

/**
 * Command data array containing the list of MeedBot commands, their descriptions, and which tabs they appear in.
 */
const commands = [
  {
    id: 1,
    tabs: [0, 1, 2], // 'All', 'Quick Start', and 'Challenge Management' tabs
    title: '/challenge',
    description: "Create a new challenge for another user. Set the challenge details and duration. Once the challenge is marked complete or failed, it enters a 24-hour voting period. If there are more official 'pass' votes than 'fail' votes after voting, the challenge succeeds.",
    example: '/challenge [@user] [challenge description]',
    icon: swords,
  },
  {
    id: 2,
    tabs: [0, 5], // 'All' and 'Help' tabs
    title: '/meed',
    description: "Get a quick overview of Meed, including core concepts and useful links.",
    example: '/meed',
    icon: swords,
  },
  {
    id: 3,
    tabs: [0, 1, 3], // 'All', 'Quick Start', and 'Proof Handling' tabs
    title: '/proof',
    description: "Submit proof for a challenge. Attach an image or video and add an optional description.",
    example: '/proof [challengeid] [proof] [description]',
    icon: swords,
  },
  {
    id: 4,
    tabs: [0, 3], // 'All' and 'Proof Handling' tabs
    title: '/view-proofs',
    description: "See all proofs submitted for a specific challenge. Great for reviewing progress!",
    example: '/view-proofs [challengeid]',
    icon: swords,
  },
  {
    id: 5,
    tabs: [0, 4], // 'All' and 'User Management' tabs
    title: '/user',
    description: "Check if a user is on Meed and get a link to their profile. Quick way to see someone's Meed status!",
    example: '/user [@user]',
    icon: swords,
  },
  {
    id: 6,
    tabs: [0, 1, 2], // 'All', 'Quick Start', and 'Challenge Management' tabs
    title: '/info',
    description: "Get details about a specific challenge. Use this to quickly check challenge info.",
    example: '/info [challengeid]',
    icon: swords,
  },
  {
    id: 7,
    tabs: [0, 4], // 'All' and 'User Management' tabs
    title: '/meed-ban',
    description: "Prevent a user from challenging you on Meed only. This doesn't affect their Discord access or server membership. Use this if someone's sending too many Meed challenges!",
    example: '/meed-ban [@user] [duration] [reason]',
    icon: swords,
  },
  {
    id: 8,
    tabs: [0, 4], // 'All' and 'User Management' tabs
    title: '/meed-unban',
    description: "Remove a Meed-specific ban on a user, allowing them to challenge you again on Meed. This only affects Meed interactions, not Discord permissions.",
    example: '/meed-unban [@user]',
    icon: swords,
  },
  {
    id: 9,
    tabs: [0, 1, 4], // 'All', 'Quick Start', and 'User Management' tabs
    title: '/settings',
    description: "Manage your Meed preferences. Control who can challenge you and how you interact with Meed on Discord.",
    example: '/settings',
    icon: swords,
  },
  {
    id: 10,
    tabs: [0, 1, 2], // 'All', 'Quick Start', and 'Challenge Management' tabs
    title: '/mass-challenge',
    description: [
      "Create a challenge that anyone can participate in! Perfect for community events, competitions, or global initiatives.",
      "• Everyone can submit proof",
      "• Check if others have completed the challenge",
      "• Great for events like voting, fitness challenges, or community goals",
      "• Reply to any mass challenge with @username to see if they've completed it!"
    ],
    example: '/mass-challenge [challenge] [description] [duration]',
    icon: swords,
},
  {
    id: 11,
    tabs: [0, 1, 2],
    title: 'Voting Types',
    description: [
      "Voting lasts for 24 hours. Here are the different voting types for challenges:",
      "• Target: Only the challenged user's vote is official.",
      "• All: Every user's vote counts towards the outcome.",
      "• Both: Only the creator and target's votes count.",
      "• Boost: Only users who boosted the challenge with money get an official vote.",
      "• Creator: Only the challenge creator gets an official vote.",
      "• Custom: Only users selected by the creator get an official vote."
    ],
    example: 'Informational',
    icon: swords,
    isInfoCard: true
  },
  {
    id: 12,
    tabs: [0, 1, 2],
    title: 'Challenge Durations',
    description: [
      "Available durations for challenges:",
      "• 1 hour",
      "• 6 hours",
      "• 1 day",
      "• 3 days",
      "• 6 days (default)",
      "Please note that voting lasts for 24 hours after the challenged user clicks 'Complete', 'Fail', or 'Let Others Decide'."
    ],
    example: 'Informational',
    icon: swords,
    isInfoCard: true
  },
  {
    id: 13,
    tabs: [0, 1, 2], // 'All', 'Quick Start', and 'Challenge Management' tabs
    title: 'Payments and Boosts',
    description: "Users can donate or boost a challenge with money. For boosts, the challenged user only receives the money if they complete the challenge successfully, as determined by the official vote tally.\n\n" +
      "NOTE: This feature is COMING SOON and not fully implemented yet.",
    example: 'Informational',
    icon: swords,
    isInfoCard: true
  },
  {
    id: 14,
    tabs: [0, 5], // 'All' and 'Help' tabs
    title: '/contact',
    description: [
      "Need help or want to connect with the Meed community? Here are your options:",
      {
        type: 'link',
        text: "Visit our Contact Us page",
        url: "/contact",
        isExternal: false
      },
      {
        type: 'link',
        text: "Join the Meed Discord server",
        url: DISCORD_MEED_SERVER,
        isExternal: true
      },
      "In our Discord, you can ask questions, share feedback, or even challenge us!",
      "We're always excited to hear from our community and ready for a good challenge."
    ],
    example: 'Informational',
    icon: swords,
    isInfoCard: true
  },

];


/**
 * CommandComponent
 * @description Renders an individual command card with title, description, and example.
 * @param {Object} props - Contains the command object and the classes for styling.
 * @returns JSX element for rendering a single command's card.
 */
const CommandComponent = ({ command, classes }) => {
  const renderDescription = (description) => {
    if (typeof description === 'string') {
      return description;
    }
    return description.map((item, index) => {
      if (typeof item === 'string') {
        return (
          <React.Fragment key={index}>
            {item}
            {index < description.length - 1 && <br />}
          </React.Fragment>
        );
      }
      if (item && typeof item === 'object' && item.type === 'link') {
        return item.isExternal ? (
          <React.Fragment key={index}>
            <a href={item.url} target="_blank" rel="noopener noreferrer">{item.text}</a>
            <br />
          </React.Fragment>
        ) : (
          <React.Fragment key={index}>
            <Link to={item.url}>{item.text}</Link>
            <br />
          </React.Fragment>
        );
      }
      return null;
    });
  };

  return (
    <Grid container className={`${classes.commandContainerFormat}`}>
      <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
        <img className={`${classes.jobs}`} alt="Icon" src={command.icon} />
      </Grid>
      <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
        <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>
          <div className={`${classes.thick}`}>{command.title}</div>
          <div className={`${classes.commandDescription}`}>
            {renderDescription(command.description)}
          </div>
        </Grid>
        <Grid className={`${classes.mid}`} item sm={4} xs={12}>
          <Button
            className={`${classes.commandExample}`}
            disabled={command.isInfoCard}
          >
            {command.example}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

/**
 * IntegrationsDiscord Component
 * @description Main component for managing and rendering the integrations page for Discord.
 * Displays available commands in a tabbed layout, allowing users to navigate and explore different categories of commands.
 */
class integrationsDiscord extends Component {
  /**
   * @constructor
   * Initializes the state for the component, including tab selection and UI state management.
   */
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {},
      submitted: false,
      open1: false,
      matches: window.matchMedia('(min-width: 768px)').matches,
      Twitch: window.Twitch,
      searchChall: true,
      socialName: '',
      payAmount: 0,
      open: false,
      body: '',
      endDate: 'xxlarge',
      completeByDate: 'xxlarge',
      influencer: '',
      timeout: null,
      loading: false,
      userHandleUrl: null,
      challengeCost: 0,
      mpChecked: true,
      tChecked: false,
      disabled: false,
      goal: 'No',
      once: false,
      // New values
      tabValue: 0,
      contentHeight: 0,
    };
    this.contentRef = React.createRef();
  }

  /**
   * @function componentWillReceiveProps
   * @description Lifecycle method that is invoked when the component receives new props. It updates the state with errors if present in nextProps.
   * @param {Object} nextProps - The new props passed to the component.
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }

  /**
   * Handles the opening of a dialog or modal when a specific challenge is clicked.
   * @param {Object} e - The event triggered by clicking.
   * @param {Object} challenge - The challenge object associated with the click.
   */
  handleClickOpen = async (e, challenge) => {
    e.stopPropagation();
    e.preventDefault();
    await this.setState({
      open1: true,
    });
  };

  /**
   * @function a11yProps
   * @description Provides accessibility props for the tabs.
   * @param {number} index - The index of the current tab.
   * @returns {Object} The accessibility properties for the tab.
   */
  a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

   /**
   * @function handleChangeTab
   * @description Updates the state to reflect the currently selected tab.
   * @param {Object} event - The event object triggered by tab selection.
   * @param {number} newValue - The index of the selected tab.
   */
   handleChangeTab = (event, newValue) => {
    this.setState({ tabValue: newValue }, () => {
      this.updateContentHeight();
    });
  };

  /**
   * Scrolls the page to the top of the command list when called.
   */
  scrollToTopOfList = () => {
    const headerElement = document.getElementById('topOfList');
    if (headerElement) {
      headerElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  /**
   * @function componentDidMount
   * @description Lifecycle method that runs after the component is mounted. Ensures the page scrolls to the top when loaded.
   */
  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateContentHeight();
    window.addEventListener('resize', this.updateContentHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateContentHeight);
  }

  updateContentHeight = () => {
    if (this.contentRef.current) {
      const height = this.contentRef.current.clientHeight;
      this.setState({ contentHeight: height });
    }
  }

  /**
   * @function render
   * @description Renders the component's UI, including the tab navigation and the command cards.
   * @returns JSX element for rendering the entire integrationsDiscord component.
   */
  render() {
    const { classes } = this.props;
    const { contentHeight } = this.state;

    return (
      <div className={classes.team}>
        <Grid container className={classes.reelWrap}>
          {/* <div className={classes.bgThumb}></div> */}
          <Grid
            className={`${classes.gridSpacingNone} ${classes.topJoint} ${classes.mainGrid}`}
            xs={12}
          >
            <div className={classes.cta}>
              <div className={classes.ctaText}>
                <h1>Meed on Discord</h1>
                <h2>Explore Our Bot Commands</h2>
              </div>
              <div className={classes.ctaSection}>
              <Button
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  onClick={this.scrollToTopOfList}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34.875"
                    height="34.875"
                    viewBox="0 0 34.875 34.875"
                  >
                    <path
                      id="Icon_awesome-chevron-circle-down"
                      data-name="Icon awesome-chevron-circle-down"
                      d="M35.438,18A17.438,17.438,0,1,1,18,.563,17.434,17.434,0,0,1,35.438,18ZM19.2,26.009l9.527-9.527a1.681,1.681,0,0,0,0-2.384l-1.2-1.2a1.681,1.681,0,0,0-2.384,0L18,20.046,10.856,12.9a1.681,1.681,0,0,0-2.384,0l-1.2,1.2a1.681,1.681,0,0,0,0,2.384L16.8,26.009A1.689,1.689,0,0,0,19.2,26.009Z"
                      transform="translate(-0.563 -0.563)"
                      fill="#fff"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.moreWrap}>
          <Grid
            className={`${classes.gridSpacingNone} ${classes.mainGrid} ${classes.needHelpDescFormat}`}
            item
            sm={6}
            xs={12}
          >
            <div className={`${classes.pTextSize}`}>
              <h2 className={classes.pTextSizeBold}>Welcome to Meed for Discord</h2>
              <p>
                Meed turns your Discord server into a hub for engaging challenges and friendly competition. Here's how to get started:
              </p>
              <ol>
                <li>Click the "Start Integration" button to add MeedBot to your server.</li>
                <li>Use the '/challenge' command to create your first challenge.</li>
                <li>Invite friends to participate and vote on challenge outcomes.</li>
              </ol>
              <p>
                Scroll down to explore all available commands and features. Need help? Use the '/meed' command in your server for quick assistance.
              </p>
            </div>
          </Grid>
          <Grid
            className={`${classes.gridSpacingNone} ${classes.needHelpIntegFormat}`}
            item
            sm={6}
            xs={12}
          >
            <Grid container className={classes.integrationSection}>
              <Grid className={classes.integrationSectionText1} item md={6} xs={12}>
                {' '}
                Add MeedBot to Discord{' '}
              </Grid>
              <Grid className={classes.integrationSectionText2} item md={6} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.startIntegrationButton}
                  href="https://discord.com/oauth2/authorize?client_id=1039218567297646654&scope=identify%20bot%20applications.commands&permissions=2146958591"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Start Integration
                </Button>
              </Grid>

              {/*<img className={classes.needHelpImg} src={thumb}></img>*/}
            </Grid>
          </Grid>
        </Grid>
        <Fragment>
          <div id="topOfList" className={classes.commandContainerFormat1} style={{ minHeight: `${contentHeight}px` }}>
            <AppBar
              className={`${classes.tabHeader} ${classes.commandListFormat}`}
              position="static"
              color="default"
            >
              <Tabs
                value={this.state.tabValue}
                onChange={this.handleChangeTab}
                indicatorColor="transparent"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {tabLabels.map((label, index) => (
                  <Tab
                    key={index}
                    className={`${classes.commandHeaderButton} ${
                      this.state.tabValue === index ? classes.commandActiveButton : ''
                    }`}
                    label={label}
                    {...this.a11yProps(index)}
                  />
                ))}
              </Tabs>
            </AppBar>
            <div ref={this.contentRef}>
              {tabLabels.map((label, index) => (
                <TabPanel
                  value={this.state.tabValue}
                  index={index}
                  key={index}
                  className={classes.tabPanel}
                >
                  {commands
                    .filter((command) => index === 0 || command.tabs.includes(index))
                    .map((command) => (
                      <CommandComponent key={command.id} command={command} classes={classes} />
                    ))}
                </TabPanel>
              ))}
            </div>
          </div>
        </Fragment>
      </div>
    );
  }
}

/**
 * PropTypes validation for integrationsDiscord component.
 */
integrationsDiscord.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

/**
 * mapStateToProps
 * @description Maps Redux state to component props.
 * @param {Object} state - The Redux state object.
 * @returns {Object} Mapped state properties.
 */
const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {};

// Export the connected component with styles applied
export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(integrationsDiscordStyles)(integrationsDiscord));
