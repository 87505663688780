// meed/src/redux/reducers/dataReducer.ts
/**
 * @file dataReducer.ts
 * @description Redux reducer responsible for handling the state updates related to challenges, mass challenges, proofs, users, and games. 
 * This reducer manages loading states, CRUD operations for challenges, and mass challenge-specific actions. 
 * The reducer follows standard Redux practices and uses action types to manage state changes in a type-safe way.
 */

import { SearchableGame } from '../../types/gameTypes';
import {
  SET_CHALLENGES,
  SET_ACCEPTING_USERS,
  SET_COMPLETED_CHALLENGES,
  SET_STAFF_CHALLENGES,
  UNLIKE_CHALLENGE,
  LOADING_DATA,
  DELETE_CHALLENGE,
  POST_CHALLENGE,
  POST_SUGGESTION,
  SET_CHALLENGE,
  SUBMIT_COMMENT,
  GET_TOTAL_RAISED,
  ADD_PROOF_DATA,
  CLEAR_PROOF_DATA,
  SUBMIT_PROOF_SUCCESS,
  SUBMIT_PROOF_FAIL,
  LOADED_DATA,
  SET_GAMES,
  CREATE_MASS_CHALLENGE,
  ACCEPT_MASS_CHALLENGE,
  DECLINE_MASS_CHALLENGE,
  SET_MASS_CHALLENGE_DETAILS,
  SET_PARTICIPANT_STATUS,
  SET_MASS_CHALLENGES,
  LOADING_MASS_CHALLENGE,
  LOADED_MASS_CHALLENGE,
} from '../types';

// Define interfaces for the state and its components
/**
 * @typedef {Object} Challenge
 * @property {string} challengeId - The unique identifier of the challenge.
 * @property {any[]} [comments] - Optional comments related to the challenge.
 */
interface Challenge {
  challengeId: string;
  comments?: any[];
  [key: string]: any;  // Allow for other properties
}

/**
 * @typedef {Object} MassChallenge
 * @property {string} massChallengeId - The unique identifier of the mass challenge.
 * @property {string} creatorId - The ID of the user who created the mass challenge.
 * @property {string} challengeName - The name of the mass challenge.
 * @property {string} [description] - Optional description of the mass challenge.
 */
interface MassChallenge {
  massChallengeId: string;
  creatorId: string;
  challengeName: string;
  description?: string;
  // ... other properties ...
}

/**
 * @typedef {Object} ParticipantStatus
 * @property {string} massChallengeId - The ID of the mass challenge.
 * @property {string} participantId - The ID of the participant.
 * @property {string} status - The current status of the participant (e.g., accepted, declined).
 */
interface ParticipantStatus {
  massChallengeId: string;
  participantId: string;
  status: string;
  // ... other properties ...
}

/**
 * @typedef {Object} DataState
 * @property {Challenge[]} challenges - List of active challenges.
 * @property {Challenge[]} completed - List of completed challenges.
 * @property {any[]} users - List of users involved in the challenges.
 * @property {Partial<Challenge>} challenge - Details of a specific challenge.
 * @property {boolean} loading - Boolean indicating if data is currently loading.
 * @property {Challenge[]} [staff] - List of staff-specific challenges.
 * @property {string} [stripeSession] - Optional Stripe session ID.
 * @property {any[]} [proofData] - Optional proof data for challenges.
 * @property {'success'|'fail'} [submitStatus] - Status of proof submission.
 * @property {SearchableGame[]} games - List of searchable games.
 * @property {MassChallenge[]} massChallenges - List of mass challenges.
 * @property {MassChallenge} massChallenge - Details of a specific mass challenge.
 * @property {Partial<ParticipantStatus>} participantStatus - Status of a participant in a mass challenge.
 * @property {boolean} loadingMassChallenge - Boolean indicating if mass challenge data is currently loading.
 */
export interface DataState {
  challenges: Challenge[];
  completed: Challenge[];
  users: any[];
  challenge: Partial<Challenge>;
  loading: boolean;
  staff?: Challenge[];
  stripeSession?: string;
  proofData?: any[];
  submitStatus?: 'success' | 'fail';
  games: SearchableGame[];
  massChallenges: MassChallenge[];
  massChallenge: MassChallenge;
  participantStatus: Partial<ParticipantStatus>;
  loadingMassChallenge: boolean;
}

const initialState: DataState = {
  challenges: [],
  completed: [],
  users: [],
  challenge: {},
  loading: false,
  games: [],
  massChallenges: [],
  massChallenge: {
    massChallengeId: '',
    creatorId: '',
    challengeName: ''
  },
  participantStatus: {},
  loadingMassChallenge: false,
};

interface Game {
  id: string;
  name: string;
  hasRepeatables: boolean;
  hasAchievements: boolean;
}

// Define types for all possible actions
/**
 * @typedef {Object} DataAction
 * @description Represents the types of actions that can be dispatched to the data reducer.
 */
type DataAction = 
  | { type: typeof LOADING_DATA }
  | { type: typeof LOADED_DATA }
  | { type: typeof SET_CHALLENGES; payload: Challenge[] }
  | { type: typeof SET_COMPLETED_CHALLENGES; payload: Challenge[] }
  | { type: typeof SET_STAFF_CHALLENGES; payload: Challenge[] }
  | { type: typeof SET_ACCEPTING_USERS; payload: any[] }
  | { type: typeof SET_CHALLENGE; payload: Challenge }
  | { type: typeof UNLIKE_CHALLENGE; payload: Challenge }
  | { type: typeof DELETE_CHALLENGE; payload: string }
  | { type: typeof POST_CHALLENGE; payload: { sessionId: string } }
  | { type: typeof POST_SUGGESTION }
  | { type: typeof SUBMIT_COMMENT; payload: any }
  | { type: typeof GET_TOTAL_RAISED; payload: Partial<Challenge> }
  | { type: typeof ADD_PROOF_DATA; payload: any }
  | { type: typeof CLEAR_PROOF_DATA }
  | { type: typeof SUBMIT_PROOF_SUCCESS }
  | { type: typeof SUBMIT_PROOF_FAIL }
  | { type: typeof SET_GAMES; payload: Game[] }
  | { type: typeof CREATE_MASS_CHALLENGE; payload: string }
  | { type: typeof ACCEPT_MASS_CHALLENGE; payload: string }
  | { type: typeof DECLINE_MASS_CHALLENGE; payload: string }
  | { type: typeof SET_MASS_CHALLENGE_DETAILS; payload: MassChallenge }
  | { type: typeof SET_PARTICIPANT_STATUS; payload: ParticipantStatus }
  | { type: typeof SET_MASS_CHALLENGES; payload: MassChallenge[] }
  | { type: typeof LOADING_MASS_CHALLENGE }
  | { type: typeof LOADED_MASS_CHALLENGE };

/**
 * @function dataReducer
 * @description The reducer function that handles updates to the `DataState` based on the dispatched actions.
 * @param {DataState} state - The current state of the data.
 * @param {DataAction} action - The action dispatched to the reducer.
 * @return {DataState} The updated state after applying the dispatched action.
 */
function dataReducer(state = initialState, action: DataAction): DataState {
  let index: number;

  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loading: true
      };
    case LOADED_DATA:
      return {
        ...state,
        loading: false
      };
    case SET_CHALLENGES:
      return {
        ...state,
        challenges: action.payload,
        loading: false
      };
    case SET_COMPLETED_CHALLENGES:
      return {
        ...state,
        completed: action.payload,
        loading: false
      };
    case SET_STAFF_CHALLENGES:
      return {
        ...state,
        staff: action.payload,
        loading: false
      };
    case SET_ACCEPTING_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false
      };
    case SET_CHALLENGE:
      return {
        ...state,
        challenge: action.payload
      };
    case UNLIKE_CHALLENGE:
      index = state.challenges.findIndex(
        (challenge) => challenge.challengeId === action.payload.challengeId
      );
      return {
        ...state,
        challenges: [
          ...state.challenges.slice(0, index),
          action.payload,
          ...state.challenges.slice(index + 1)
        ],
        challenge: state.challenge.challengeId === action.payload.challengeId ? action.payload : state.challenge
      };
    case DELETE_CHALLENGE:
      index = state.challenges.findIndex(
        (challenge) => challenge.challengeId === action.payload
      );
      return {
        ...state,
        challenges: [
          ...state.challenges.slice(0, index),
          ...state.challenges.slice(index + 1)
        ]
      };
    case POST_CHALLENGE:
      return {
        ...state,
        stripeSession: action.payload.sessionId
      };
    case POST_SUGGESTION:
      return {
        ...state
      };
    case SUBMIT_COMMENT:
      return {
        ...state,
        challenge: {
          ...state.challenge,
          comments: [action.payload, ...(state.challenge.comments || [])]
        }
      };
    case GET_TOTAL_RAISED:
      index = state.challenges.findIndex(
        (challenge) => challenge.challengeId === action.payload.challengeId
      );
      return {
        ...state,
        challenges: [
          ...state.challenges.slice(0, index),
          { ...state.challenges[index], ...action.payload },
          ...state.challenges.slice(index + 1)
        ]
      };
    case ADD_PROOF_DATA:
      return {
        ...state,
        loading: false,
        proofData: [...(state.proofData || []), action.payload],
      };
    case CLEAR_PROOF_DATA:
      return {
        ...state,
        proofData: [],
      };
    case SUBMIT_PROOF_SUCCESS:
      return {
        ...state,
        submitStatus: 'success',
        loading: false,
      };
    case SUBMIT_PROOF_FAIL:
      return {
        ...state,
        submitStatus: 'fail',
        loading: false,
      };
    case SET_GAMES:
      return {
        ...state,
        games: action.payload,
        loading: false
      };
    case LOADING_MASS_CHALLENGE:
      return {
        ...state,
        loadingMassChallenge: true,
      };
    case LOADED_MASS_CHALLENGE:
      return {
        ...state,
        loadingMassChallenge: false,
      };
    case SET_MASS_CHALLENGES:
      return {
        ...state,
        massChallenges: action.payload,
        loading: false,
      };
    case SET_MASS_CHALLENGE_DETAILS:
      return {
        ...state,
        massChallenge: action.payload,
        loadingMassChallenge: false,
      };
    case SET_PARTICIPANT_STATUS:
      return {
        ...state,
        participantStatus: action.payload,
        loading: false,
      };
    case CREATE_MASS_CHALLENGE:
      return {
        ...state,
        massChallenges: [
          ...state.massChallenges,
          { ...state.massChallenge, massChallengeId: action.payload },
        ],
      };
    case ACCEPT_MASS_CHALLENGE:
      return {
        ...state,
        participantStatus: {
          ...state.participantStatus,
          status: 'accepted',
        },
      };
    case DECLINE_MASS_CHALLENGE:
      return {
        ...state,
        participantStatus: {
          ...state.participantStatus,
          status: 'declined',
        },
      };
    default:
      return state;
  }
}

export default dataReducer;
