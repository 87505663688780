// meed/src/components/profile/styles/StaticProfileStyles.js
const StaticProfileStyles = (theme) => ({
   
  ...theme.custom,
  logout: {
    textAlign: 'left',
    marginTop: '10px',
    background: '#F1B6D4',
    color: '#fff'
  },
  paper: {
    boxShadow: 'none',
    background: '#F3F3F3'
  },
  userIcon: {
    display:'inline-block',
    '& img': {
      borderRadius: '50%',
      width: 100,
      height: 100
    }
  },
  profileImage: {
    backgroundColor: '#5B5A57',
    padding: 2,
    position: 'relative',
    margin: '10px 0',
    marginTop: 0,
    marginLeft: 0,
    width: '100px',
    height: '100px',
    borderRadius: '100%',
    border: 'solid 1px #14C7FC',
    objectFit: 'contain',
    objectPosition: 'center',
    cursor: 'pointer'
  },
  yellowCircle: {
    display:'inline-block',
    background: '#54148B',
    width: 80,
    height: 80,
    borderRadius: '50%'
  },
  userHead: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '@media (max-width: 960px)': {
      padding: 5,
      flexWrap: 'wrap',
      justifyContent: 'space-evenly'
    }
  },
  userMeta: {
    display: 'inline-block',
    marginLeft: 40,
    '& a': {
      fontSize: '1.2rem'
    },
    '@media (max-width: 960px)': {
      marginLeft: 10,
    }
  },
  toggleStream: {
    color: '#5B5A57',
    position: 'absolute',
    top: 60,
    right: 10,
    opacity: 1,
    transition: 'all .4s',
    border: 'solid 1px transparent',
    '&:hover': {
      opacity: .9,
      border: 'solid 1px #5B5A57',
    },
    '@media (max-width: 960px)': {
      display: 'none',
      fontSize: '0.7rem',
      padding: 6,
      marginTop: 20,
      position: 'relative',
      top: 'unset',
      right: 'unset'
    },
    '@media (max-width: 512px)': {
      minWidth: 250
    }
  },
  buttonProfileWrap: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: '100%',
    textAlign: 'right'
  },
  donate: {
    marginRight: 10,
    background: '#14C7FC',
    color: '#fff',
    opacity: 1,
    transition: 'all .4s',
    border: 'solid 1px #14C7FC',
    '&:hover': {
      opacity: .9,
      border: 'solid 1px #5B5A57',
      background: '#14C7FC'
    },
    '@media (max-width: 960px)': {
      fontSize: '0.7rem',
      marginTop: 20,
      position: 'relative',
      top: 'unset',
      right: 'unset'
    },
    '@media (max-width: 512px)': {
      minWidth: 250
    }
  },
  follow: {
    background: '#14C7FC',
    color: '#fff',
    marginRight: 10,
    opacity: 1,
    transition: 'all .4s',
    border: 'solid 1px #14C7FC',
    '&:hover': {
      opacity: .9,
      border: 'solid 1px #5B5A57',
      background: '#14C7FC'
    },
    '@media (max-width: 960px)': {
      fontSize: '0.7rem',
      marginTop: 20,
      position: 'relative',
      top: 'unset',
      right: 'unset'
    },
    '@media (max-width: 512px)': {
      minWidth: 250
    }
  },
  unfollow: {
    color: '#5B5A57',
    borderColor: '#5B5A57',
    marginRight: 10,
    opacity: 1,
    transition: 'all .4s',
    border: 'solid 1px',
    '&:hover': {
      opacity: .9,
      border: 'solid 1px #5B5A57',
    },
    '@media (max-width: 960px)': {
      fontSize: '0.7rem',
    },'@media (max-width: 960px)': {
      fontSize: '0.7rem',
      marginTop: 20,
      position: 'relative',
      top: 'unset',
      right: 'unset'
    },
  },
  moreChalls: {
    width: '100%',
    color: '#5B5A57',
    borderColor: '#5B5A57',
  },
  plusIcon: {
    maxWidth: 10,
    marginRight: 10
  },
  tabHeader: {
    boxShadow: 'none',
    background: 'transparent',
    '@media (max-width: 960px)': {
      display: 'none'
    }
  },
  tabPanel: {
    marginLeft: 56,
    marginTop: 56,
    '@media (max-width: 960px)': {
      display: 'none'
    }
  },
  accordian: {
    display: 'none',
    '& .MuiAccordionSummary-expanded': {
      background: 'purple'
    }
  },
  accordian2: {
    display: 'none',
    '@media (max-width: 960px)': {
      background: 'transparent',
      display: 'block',
      boxShadow: 'none',
      maxHeight: 200,
      overflow: 'auto'
    },
    '& .MuiAccordionSummary-expanded': {
      background: 'purple'
    }
  },
  AccordionDetails: {
    backgroundColor: 'transparent',
    wordBreak: 'break-all',
    whiteSpace: 'normal',
    // #B464FA
  },
  AccordionSummary: {
    backgroundColor: '#F3F3F3',
  },
  challs: {
    flexDirection: 'column',
    '& h2': {
      opacity: 0.6,
      '@media (max-width: 960px)': {
        fontSize: 16,
        textTransform: 'uppercase'
      },
    }
  },
  sectionTitle: {
    padding: '0 10px'
  },
  challsBox: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    maxHeight: '960px',
    overflow: 'auto',
    padding: 10,
    '&::-webkit-scrollbar': {
      width: '100%',
      height: 4
    },
    '@media (max-width: 960px)': {
      flexWrap: 'nowrap',
      overflow: 'auto',
      width: '100vw',
      padding: 'unset',
    },
  },
  searchInput: {
    background: '#E1E0DE',
    position: 'absolute',
    right: 20,
    height: 30,
    width: 400
  },
  profile: {
    padding: 50,
    transition: 'all 1s',
    '& hr': {
      display: 'none'
    },
    '@media (max-width: 960px)': {
      overflow: 'unset',
      padding: '10px 0',
      height: '40vh',
    },
  },
  hideProfile:{
    '@media (max-width: 960px)': {
      overflow: 'hidden',
      height: '0vh',
      padding: 0,
    },
  },
  heading: {
    minHeight: 'unset',
    '@media (max-width: 960px)': {
      margin: 0
    }
  },
  hiddenProfile: {
    '@media (max-width: 960px)': {
      overflow: 'hidden !important'
    },
  }
});

export default StaticProfileStyles;