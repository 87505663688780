// meed/src/components/layout/Navbar.js
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// MUI stuff
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import LinearProgress from '@mui/material/LinearProgress';

// Icons
import { withStyles } from '@mui/styles';
import logo from '../../images/logo4x.png';
import mascot from '../../images/no-img.png';
import search from '../../images/search2x.png';

import { logoutUser, AuthenticateWithTwitch, markNotificationsRead, getUserDataReturned } from '../../redux/actions/userActions';
import NavbarStyles from './styles/NavbarStyles';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

const auth = getAuth();
// Extend dayjs with the relativeTime and utc plugin
dayjs.extend(relativeTime);
dayjs.extend(utc);

let userInterval;

class Navbar extends Component {
  state = {
    socialName: '',
    socialImg: '',
    anchorEl: null,
    anchorEl2: null,
    online: true,
    notiIds: [],
    newNotifications: 0,
    authenticated: false,
    once: false,
    notifications: [],
    loading: true
  };

  componentDidMount = () => {
    onAuthStateChanged(auth, user => {
      if (user) {
        this.setState({ socialName: user.displayName });
        this.setState({ socialImg: user.photoURL });
      }
    })
    if (!userInterval && this.state.authenticated) {
      userInterval = setInterval(() => {
        this.props.getUserDataReturned()
          .then((data) => {
            if (data && data.message && data.message === 'No new notifications') {
              return;
            } else if (data && data.notifications && data.notifications[0] && data.notifications[0].notificationId !== this.state.notifications[0].notificationId) {
              this.setState({
                notifications: data.notifications,
                newNotifications: data.newNotifications
              })
            }
          })
          .catch((err) => console.log(err))
      }, 30000);
    }
  }

  handleLogout = () => {
    this.handleClose();
    this.props.logoutUser(this.props.history);
    clearInterval(userInterval);
  };

  connectTwitch = () => {
    // TODO: move these to react env vars
    this.props.AuthenticateWithTwitch({ clientId: process.env.TWITCH_CLIENT_ID, redirectUri: process.env.TWITCH_REDIRECT_URI });
  };

  searchMeed = (event) => {
    this.props.history.push('/search')
  }

  notiClick = (event) => {
    this.setState({
      anchorEl2: event.currentTarget,
      anchorEl: null,
    })
    if (this.state.newNotifications)
      this.props.markNotificationsRead(this.state.notiIds)
  }
  handleNotificationClick = (path) => {
    this.handleClose(); // Close the menu
    this.props.history.push(path); // Navigate
  };

  menuClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  handleClose2 = () => {
    this.setState({
      anchorEl2: null,
      anchorEl: null
    })
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      anchorEl2: null
    })
  };

  onlineToggle = () => {
    this.setState({
      online: !this.state.online
    })
  };

  htmlDecode = (input) => {
    let doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  };

  updatePageStatus = (authenticated, loading, once) => {
    this.setState({
      authenticated: authenticated,
      loading: loading,
      once: once
    })
  };

  render() {
    const {
      authenticated,
      loading,
      credentials: { handle, meedPointsBalance, twitch },
      notifications,
      newNotifications,
      classes,
      // UI: { showNav }
    } = this.props;
    if (notifications.length && !this.state.notiIds.length) {
      this.setState({
        notiIds: notifications.map((noti) => noti.notificationId),
        newNotifications: newNotifications,
        notifications: notifications
      })
    }
    if (!this.state.once) {
      this.updatePageStatus(authenticated, false, true);
    }
    let notificationsContent;

    if (this.state.notifications && this.state.notifications.length > 0) {
      notificationsContent = this.state.notifications.map((noti) => {
        const timeAgo = dayjs(noti.createdAt).fromNow();
        const isUnread = !noti.read;
        const notificationClasses = `${classes.notificationMenuItem} ${isUnread ? classes.notificationUnread : ''}`;
        let deadlineMessage = '';
        if (noti.type) { // Adjust the condition to check for specific notification types
          const localDeadline = dayjs(noti.completeByDateAsTimestamp).local().format('LLLL');
          deadlineMessage = `Please complete it by ${localDeadline}.`;
        }
        return (
          <MenuItem
            component={Link}
            to={(noti.influencerAtName !== '' && noti.challengeId !== '') ? `/users/${noti.influencerAtName}/challenge/${noti.challengeId}` : '#'}
            key={noti.notificationId}
            className={notificationClasses}
            onClick={() => this.handleNotificationClick(noti.notificationId)}
          >
            <div className={classes.notificationContent}>
              <p className={classes.notificationMessage}>
                {this.htmlDecode(noti.message)} {deadlineMessage}
              </p>
              <span className={classes.notificationTime}>{timeAgo}</span>
            </div>
          </MenuItem>
        );
      });
    } else {
      // Render a MenuItem with a "No notifications" message if there are no notifications
      notificationsContent = (
        <MenuItem className={classes.notificationEmpty}>
          <Typography variant="subtitle1" color="textSecondary">No notifications</Typography>
        </MenuItem>
      );
    }
    return (
      <AppBar className={classes.appBar}>
        <Toolbar className="nav-container">
          {loading && (!authenticated || !this.state.once) ?
            (
              <Fragment>
                <div className={classes.leftNav}>
                  <Link className={classes.meedLink} to="/home">
                    <img
                      src={logo}
                      alt="Meed"
                      className={classes.logo}
                    />
                  </Link>
                  <div
                    className={classes.searchInput}
                    onClick={this.searchMeed}
                  >
                    <img
                      src={search}
                      alt="Meed"
                      className={classes.logo}
                    />
                  </div>
                </div>
                <LinearProgress className={classes.halfLine} />
                <span className={classes.vertSep}>|</span>
                <LinearProgress className={classes.halfLine} />
                <span className={classes.vertSep}>|</span>

                <LinearProgress className={classes.halfLine} />
                <span className={classes.vertSep}>|</span>

                <LinearProgress className={classes.halfLine} />
                {twitch && twitch.profile_image_url ?
                  <img
                    src={twitch && twitch.profile_image_url}
                    alt="profile"
                    className={classes.profileImage}
                  />
                  :
                  <img
                    src={mascot}
                    alt="profile"
                    className={classes.profileImage}
                  />
                }
              </Fragment>
            ) :
            authenticated ? (
              <Fragment>
                <div className={classes.leftNav}>
                  <Link className={classes.meedLink} to="/home">
                    <img
                      src={logo}
                      alt="Meed"
                      className={classes.logo}
                    />
                  </Link>
                  <div
                    className={classes.searchInput}
                    onClick={this.searchMeed}
                  >
                    <img
                      src={search}
                      alt="Meed"
                      className={classes.logo}
                    />
                  </div>
                </div>
                {/*<Link className={classes.homeLink} to="/search/following">
                  Following
          </Link> <span className={classes.vertSep}>|</span>*/}
                <Link className={classes.homeLink} to={`/users/${handle}`}>
                  My Page
                </Link> <span className={classes.vertSep}>|</span>
                <Badge className={classes.margin} badgeContent={this.state.newNotifications} color="secondary">
                  <Link className={classes.homeLink} onClick={this.notiClick} to={`#`}>
                    Notifications
                  </Link>
                </Badge>
                <span className={classes.vertSep}>|</span>
                <Link className={`${classes.coins} ${classes.homeLink}`} to={`/buyMeedPoints`}>
                  ${meedPointsBalance}
                </Link>
                {twitch && twitch.profile_image_url ?
                  <img
                    src={twitch && twitch.profile_image_url}
                    alt="profile"
                    className={classes.profileImage}
                    onClick={this.menuClick}
                  />
                  :
                  <img
                    src={mascot}
                    alt="profile"
                    className={classes.profileImage}
                    onClick={this.menuClick}
                  />
                }
                <Menu
                  id="simple-menu2"
                  anchorEl={this.state.anchorEl2}
                  keepMounted
                  open={Boolean(this.state.anchorEl2)}
                  onClose={this.handleClose2}
                  MenuListProps={{ disablePadding: true }}
                  className={classes.menuWrap2}
                >
                  {notificationsContent}
                </Menu>
                <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                  MenuListProps={{ disablePadding: true }}
                  className={classes.menuWrap}
                >
                  <MenuItem component={Link} to={`/users/${handle}`} className={classes.menuItem2} onClick={this.handleClose}><Typography className={classes.whiteText}>My Page</Typography></MenuItem>
                  {/* <MenuItem component={Link} className={classes.menuItem} onClick={this.handleClose}><Link className={classes.whiteText} to={`/manage`}>Control Center</Link></MenuItem> */}
                  <MenuItem component={Link} to="/search/following" className={`${classes.menuItem2} ${classes.mobileLink}`} >
                    <Typography className={classes.mobileLink} >
                      Following
                    </Typography>
                  </MenuItem>
                  <MenuItem component={Link} to={`#`} className={`${classes.menuItem2} ${classes.mobileLink}`} >
                    <Badge className={classes.margin} badgeContent={newNotifications} color="secondary">
                      <Typography className={classes.mobileLink} onClick={this.notiClick}>
                        Notifications
                      </Typography>
                    </Badge>
                  </MenuItem>
                  <MenuItem component={Link} to={`/buyMeedPoints`} className={classes.menuItem2} onClick={this.handleClose}><Typography className={classes.whiteText}>Wallet</Typography></MenuItem>
                  <MenuItem component={Link} to={`/buyMeedPoints`} className={`${classes.menuItem2} ${classes.mobileLink}`} >
                    <Typography className={`${classes.mobileLink} ${classes.coins}`} >
                      ${meedPointsBalance}
                    </Typography>
                  </MenuItem>
                  <MenuItem component={Link} to={`/settings`} className={classes.menuItem2} onClick={this.handleClose}><Typography className={classes.whiteText}>Settings</Typography></MenuItem>
                  <MenuItem component={Link} to={`/faq`} className={classes.menuItem2} onClick={this.handleClose}><Typography className={classes.whiteText}>FAQ</Typography></MenuItem>
                  <MenuItem className={classes.menuItem2} component={Link} to={`/service`}>Terms of Service</MenuItem>
                  <MenuItem className={classes.menuItem2} component={Link} to={`/privacy`}>Privacy Policy</MenuItem>
                  <MenuItem className={classes.menuItem2} component={Link} to={`/about`}>About Us</MenuItem>
                  <MenuItem className={classes.menuItem2} component={Link} to={`/contact`}>Contact Us</MenuItem>
                  <MenuItem className={classes.menuItem2} onClick={this.handleLogout}>Log Out</MenuItem>
                </Menu>
              </Fragment>
            ) : (
              <Fragment>
                <Link className={classes.logo} to="/home">
                  <img
                    src={logo}
                    alt="Meed"
                    className={classes.logo}
                  />
                </Link>
                <div className={classes.buttonGroup}>
                  {/*
                  <Button color="inherit" component={Link} to="/about" className={classes.loginButton}>
                    About
                  </Button> */}
                  <Button color="inherit" component={Link} to="/integrations/discord" className={classes.loginButton}>
                    About
                  </Button>
                  <Button color="inherit" component={Link} to="/contact" className={classes.contactButton}>
                    Contact Us
                  </Button>
                  <Button color="inherit" component={Link} to="/login" className={classes.loginButton}>
                    Login
                  </Button>
                  <Button color="inherit" component={Link} to="/signup" className={classes.signUpButton}>
                    Signup
                  </Button>
                </div>
              </Fragment>
            )}
        </Toolbar>
      </AppBar>
    );
  }
}

Navbar.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  data: state.data,
  authenticated: state.user.authenticated,
  loading: state.user.loading,
  credentials: state.user.credentials,
  notifications: state.user.notifications,
  newNotifications: state.user.newNotifications,
  getUserDataReturned: state.user.getUserDataReturned,
  UI: state.UI
});

const mapActionsToProps = { logoutUser, AuthenticateWithTwitch, markNotificationsRead, getUserDataReturned };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(NavbarStyles)(withRouter(Navbar)));
