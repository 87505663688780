// meed/src/pages/styles/aboutStyles.js
import bgFace from '../../images/bgFace2.jpg';
import thumb from '../../images/topBanner.jpg';
import img1 from '../../images/img1.jpg';
import img2 from '../../images/img2.jpg';
const aboutStyles = (theme) => ({
  ...theme.custom,
  about: {
    background: '#9322D4',
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  reelWrap: {
    position: 'relative',
    maxHeight: '75vh',
    overflow: 'hidden',
    height: '100%',
    '@media (max-width: 960px)': {
        height: 'unset',
        marginTop: 64,
        maxHeight: 'unset',
        position: 'relative'
    },
    '@media (max-width: 600px)': {
      marginTop: 56
  },
  },
  moreWrap: {
    background: '#F3F3F3',
    paddingBottom: 0,
    fontFamily: '"Roboto", sans-serif',
    fontWeight: 500,
    color: '#5B5A57',
    position: 'relative',
    textAlign: 'center',
    zIndex: 1,
    '@media (max-width: 960px)': {
        padding: 20
    },
    '& h1': {
        fontSize: '2.5em',
        textTransform: 'lowercase',
        color: '#B600FF',
        textAlign: 'center',
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 500,
        '@media (max-width: 960px)': {
          fontSize: '1.5em'
        },
    },
    '& p': {
        fontSize: '2.5em',
        margin: 'auto',
        maxWidth: 1200,
        textAlign: 'center',
        fontFamily: '"Roboto", sans-serif',
        textTransform: 'initial',
        '@media (max-width: 960px)': {
            fontSize: '1.5em'
        },
    },
  },
  exampleWrap: {
    backgroundImage: `url(${bgFace})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    maxHeight: '70vh',
    '& h1': {
       fontSize: '2.5em',
       color: 'rgba(255,255,255, .67)',
       padding: '50px 100px',
       '@media (max-width: 1450px)': {
        fontSize: '2em',
      },
      '@media (max-width: 1230px)': {
        padding: 20
      },
      '@media (max-width: 960px)': {
        fontSize: '1.5em',
        padding: 20
      },
  },
    '@media (max-width: 960px)': {
        padding: 20,
        maxHeight: 'unset',
        height: 'unset'
    },
    fontFamily: '"Roboto", sans-serif',
    fontWeight: 500,
    color: '#5B5A57',
    position: 'relative',
  },
  cardsWrap: {
    background: '#F9F9F9',
    padding: '50px 100px',
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 600px)': {
      justifyContent: 'center'
    },
    '@media (max-width: 960px)': {
        padding: 20
    },
    fontFamily: '"Roboto", sans-serif',
    fontWeight: 500,
    color: '#9322D4',
    position: 'relative'
  },
  reel: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center',
    filter: 'blur(8px)',
    zIndex: 1,
    '@media (max-width: 1325px)': {
      width: 'unset',
      height: '100%',
    },
    '@media (max-width: 960px)': {
      width: '100%',
      height: 'unset',
    },
    '@media (max-width: 600px)': {
      width: 'unset',
      height: '100%',
    },
  },
  bgFilter: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'rgba(147, 34, 212, .5)',
    zIndex: 2
  },
  bgThumb: {
    backgroundImage: `url(${bgFace})`,
    position: 'absolute',
    width: '100%',
    height: '100%',
    filter: 'blur(8px)',
    zIndex: 0
  },
  mainGridModal: {
    marginTop: 75,
    padding: '20px 10px'
  },
  footerGridModal: {
    minHeight: 40
  },
  mainGrid: {
    height: '100%',
    position: 'relative',
    padding: '50px 100px',
    '& div': {
      boxShadow: 'none'
    },
    '@media (max-width: 960px)': {
        padding: 20
    },
    '@media (max-width: 600px)': {
      width: '100%',
      textAlign: 'center'
    },
  },
  cardCta: {

    padding: 20
  },
  hideMobile: {
    '@media (max-width: 600px)': {
      display: 'none'
    },
  },
  topJoint: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100%',
    backgroundImage: `url(${thumb})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: 3,
    '@media (max-width: 960px)': {
      padding: '80px 20px'
    },
    '& h1': {
        margin: 10,
        fontSize: '3em',
        '@media (max-width: 960px)': {
            fontSize: '1.5em'
        },
    }
  },
  cta: {
    minWidth: 500,
    color: '#FFF',
    position: 'relative',
    textAlign: 'center',
    marginBottom: 200,
    '& h1': {
        fontFamily: '"Roboto", sans-serif',
    },
    '& p': {
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 700
    },
    '& span': {
        fontSize: '2.0rem',
        '@media (max-width: 960px)': {
            fontSize: '1.5em'
        },
        '@media (max-width: 600px)': {
          fontSize: '1.2em'
        },
    },
    '@media (max-width: 960px)': {
        minWidth: 'unset',
        marginTop: 100,
        marginBottom: 0,
    },
  },
  dripDown: {
    maxHeight: 400,
    width: '100%',
    position: 'absolute',
    left: 0,
    top: -200,
    zIndex: 0,
    '@media (max-width: 690px)': {
      top: -200
    },
    '@media (max-width: 600px)': {
      top: -100
    },
  },
  dripUp: {
    maxHeight: 400,
    width: '100%',
    position: 'relative',
    left: 0,
    top: -1,
    zIndex: 0,
    transform: 'rotate(180deg)',
    background: '#F9F9F9'
  },
  planets: {
    textAlign: 'right',
    width: '100%',
    maxWidth: 500
  },
  planets2: {
    position: 'absolute',
    right: 20,
    top: 0,
    zIndex: 1,
    textAlign: 'right',
    width: '100%',
    maxWidth: 300,
    '@media (max-width: 600px)': {
      maxWidth: 170
    },
  },
  exampleCta: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media (max-width: 690px)': {
      marginTop: 100
    },
  },
  callCardWrap: {
      display: 'flex',
      justifyContent: 'center',
  },
  callCardWrap1: {
    marginTop: -50,
  },
  callCard: {
    display: 'flex',
    flexDirection: 'column',
    background: '#062A4E',
    color: '#fff',
    borderRadius: 8,
    padding: 20,
    margin: '0 10px',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 278,
    textAlign: 'center',
    minHeight: 370,
    '& p': {
        margin: 5
    },
    '@media (max-width: 700px)': {
      padding: 5,
    },
    '@media (max-width: 600px)': {
      margin: 10,
      padding: 20,
    },
  },
  cardIcon: {
    width: 170,
    marginBottom: 20
  },
  mascotGrid: {
    '@media (max-width: 600px)': {
    //   height: 'unset',
    //   position: 'absolute',
    //   top: 0
    }
  },
  mascot: {
    width: '100%',
    '@media (max-width: 600px)': {
      maxWidth: '100px',
      height: 'unset'
    },
  },
  buttonDefault: {
    width: '100%',
    marginTop: 30,
    borderRadius: '2px',
    maxWidth: '220px'
  },
  label: {
    color: '#fff',
    borderColor: '#fff',
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff',
    },
  },
  submitButtonChall: {
    background: '#7B5DF9',
    color: '#fff',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: 10,
    '@media (max-width: 600px)': {
      marginTop: 30,
      position: 'relative',
      left: 'unset',
      transform: 'unset',
      bottom: 'unset'
    },
  },
  visitButtonChall: {
    background: '#7B5DF9',
    color: '#fff',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: -50,
    borderRadius: 35,
    '@media (max-width: 600px)': {
      marginTop: 30,
      position: 'relative',
      left: 'unset',
      transform: 'unset',
      bottom: 'unset'
    },
  },
  submitButton: {
    minWidth: 200,
    background: '#7B5DF9',
    color: '#fff',
    opacity: 1,
    transition: 'all .4s',
    borderRadius: 80,
    fontSize: '.75rem',
    border: 'solid 1px #7B5DF9',
    '&:hover': {
      border: 'solid 1px #fff',
      background: '#7B5DF9',
    },
    '@media (max-width: 960px)': {
        marginTop: 10,
        minWidth: 300,
    },
  },
  flexCol: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  
  card: {
    boxShadow: 'none',
    textAlign: 'center',
    '& textarea': {
      textAlign: 'center',
    }
  },
  card2: {
    position: 'relative',
    overflow: 'visible',
    boxShadow: 'none',
    textAlign: 'center',
    '& textarea': {
      textAlign: 'center',
    }
  },
  challengeLink: {
    textAlign: 'center',
    color: '#454545',
    fontWeight: 500,
  },
  challengeLink2: {
    fontSize: '.75rem',
    border: 'solid 1px #fffff',
    color: '#757575',
    maxWidth: 380,
    borderRadius: 35,
    margin: '0 auto',
    padding: 5,
    cursor: 'pointer'
  },
  footer: {
    background: '#fff',
    position: 'relative',
    paddingBottom: 100,
    color: '#5B5A57',
    '& p': {
      color: '#5B5A57',
    },
    '& h1': {
      '@media (max-width: 960px)': {
        fontSize: '1.5em',
        textAlign: 'left'
      },
    },
  },
  footerGrid:{
    padding: '0 100px',
    '@media (max-width: 960px)': {
        padding: '0 20px',
    },
    '@media (max-width: 600px)': {
      textAlign: 'left',
      alignItems: 'center'
    },
  },
  footerHead: {
    height: 'unset',
    position: 'absolute',
    top: 150,
    textAlign: 'left',
    '& p': {
      color: 'rgba(255,255,255,.4)',
      cursor: 'default',
      '&:hover': {
        color: 'rgba(255,255,255,.4)'
      }
    },
    '@media (max-width: 600px)': {
      textAlign: 'center'
    },
  },
  footerActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  copyRight: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    padding: '0 100px',
    '& a': {
      cursor: 'pointer'
    },
    '@media (max-width: 960px)': {
        padding: '0 20px',
    },
    '@media (max-width: 600px)': {
      textAlign: 'center',
      width: '100%',
      padding: 0
    },
  },
  footerSocial: {
    width: 25,
    marginRight: 10
  },
  sitelinks: {
    '@media (max-width: 960px)': {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      textAlign: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap'
    },
  },
  sitelink: {
    color: 'rgba(255,255,255,.4)',
    cursor: 'pointer',
    '&:hover': {
      color: '#fff'
    },
    margin: 10,
  },
  ctaText: {
      textTransform: 'capitalize',
      fontWeight: 700
  },
  pink: {
      color: '#B600FF'
  },
  whiteBg: {
    background: '#fff'
  },
  mid: {
    '& p': {
      fontSize: '2em',
      '@media (max-width: 960px)': {
        fontSize: '1.05em',
      },
      '@media (max-width: 600px)': {
        fontSize: '1.5em',
      },
    },
  },
  smoll: {
    '& p': {
      fontSize: '1.5em',
      '@media (max-width: 960px)': {
        fontSize: '1em',
      },
    },
  },
  img1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: 50,
    aspectRatio: 0.5,
    position: 'relative',
    backgroundImage: `linear-gradient(rgba(182, 0, 255, .6), rgba(182, 0, 255, .6)), url(${img1})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    maxHeight: 600,
    margin: '0 auto',
      '@media (max-width: 960px)': {
        maxHeight: 300,
      },

  },
  img2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: 50,
    aspectRatio: 0.5,
    position: 'relative',
    backgroundImage: `linear-gradient(rgba(182, 0, 255, .6), rgba(182, 0, 255, .6)), url(${thumb})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    maxHeight: 600,
    margin: '0 auto',
      '@media (max-width: 960px)': {
        maxHeight: 300,
      },

  },
  img3: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: 50,
    aspectRatio: 0.5,
    position: 'relative',
    backgroundImage: `linear-gradient(rgba(182, 0, 255, .6), rgba(182, 0, 255, .6)), url(${img2})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    maxHeight: 600,
    margin: '0 auto',
      '@media (max-width: 960px)': {
        maxHeight: 300,
      },

  },
  ctaTextCard: {
    width: '100%',
    zIndex: 1,
    color: '#fff',
    fontSize: '.785em',
    textAlign: 'left',
    '& img': {
      width: 50
    },
    '& p': {
      textAlign: 'left',
    }
  },
  ctaImgs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '& img': {
      border: 'solid 1px #9322D4',
      width: 200,
      borderRadius: '50%',
      transition: 'all .4s',
      '&:hover': {
        transform: 'scale(1.1)',
        zIndex: 1,
        borderColor: '#fff'
      },
      '@media (max-width: 960px)': {
        width: 150,
      },
      '@media (max-width: 760px)': {
        width: 100,
      },
    },
    '@media (max-width: 600px)': {
      width: '100%',
      justifyContent: 'center'
    },
  },
  team2: {
    zIndex: 1,
  },
  team1: {
    zIndex: 2,
    marginLeft: -30
  },
  team3: {
    zIndex: 3,
    marginLeft: -30
  },
  team4: {
    zIndex: 4,
    marginLeft: -30
  },
  dialog: {
    borderRadius: 0,
    // '& div': {
    //   borderRadius: 0,
    //   maxWidth: 'unset',
    //   '@media (max-width: 960px)': {
    //     overflowY: 'auto'
    //   },
    // },
  },
  dialogPaper: {
    overflow: 'visible',
    position: 'relative',
    background: '#fff',
    maxWidth: 'unset',
    '@media (max-width: 960px)': {
      maxHeight: 'unset',
      width: '100%',
      height: '100%',
      margin: 0
    },
  },
  modalWrap: {
    overflowX: 'hidden',
    position: 'relative',
    width: '100%',
    minWidth: '730px',
    maxWidth: 730,
    '@media (max-width: 960px)': {
      minWidth: '460px',
      padding: 0,
      height: '100vh',
    },
    '@media (max-width: 600px)': {
      minWidth: 'unset',
      padding: 0,
      height: '100vh',
    },
  },
  closeButton: {
    width: 35,
    height: 35,
    padding: 5,
    background: '#fff',
    borderRadius: '50%',
    position: 'absolute',
    top: -25,
    right: -25,
    zIndex: 1,
    display: 'flex', 
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '@media (max-width: 960px)': {
      top: 0,
      right: 0,
      transform: 'scale(.7)'
    },
  },
  searchBar: {
    position: 'relative',
    background: "#7B5DF9",
    height: '227px',
    '& h1': {
      color: '#fff',
      textAlign: 'center',
      paddingTop: 50,
      margin: 0
    }
  },
  visitProfile: {
    border: 'solid 1px #fff',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    maxHeight: 185,
    overflow: 'hidden',
    position: 'absolute',
    left: '50%',
    bottom: -40,
    transform: 'translateX(-50%)',
    '& img': {
      maxWidth: 350,
      width: '100%'
    }
  },
  pageText:{
    fontWeight: 500,
    color: '#8C8C8C',
    margin: '8px 0px'
  },
  textField: {
    borderRadius: 35,
    '& fieldset': {
      borderRadius: 35
    },
  },
  ideas: {
    padding: '40px 30px',
    color: '#fff',
    background: 'linear-gradient(to bottom, #7B5DF9, #5B43C1)'
  },
  shareideas: {
    border: 'solid 1px #7B5DF9',
    padding: '40px 30px',
    color: '#7B5DF9',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    cursor: 'pointer',
    '& p': {
      display: 'flex'
    },
    '& svg': {
      marginRight: 10
    }
  },
  dice: {
    maxWidth: 100
  },
  outlined: {
    margin: 8,
    width: '100%',
    border: 'solid 1px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: '35px',
  },
  whiteTxt: {
    textAlign: 'center'
  },
  endDate: {
    textAlign: 'center'
  },
  planeIcon: {
    marginLeft: 5
  },
  signUp: {
    cursor: 'pointer'
  }
});

export default aboutStyles;