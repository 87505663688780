// meed/src/components/challenge/VoteButton.js
import React, { Component } from 'react';
import MyButton from '../../util/MyButton';
import PropTypes from 'prop-types';
// Icons
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
// Redux
import { connect } from 'react-redux';
import { castVote, removeVote } from '../../redux/actions/dataActions';

/**
 * VoteButton component for casting pass or fail votes on a challenge.
 * 
 * @component
 */
export class VoteButton extends Component {
  /**
   * Gets the user's vote data for the challenge.
   * 
   * @returns {Object|null} The user's vote data, or null if no vote.
   */
  userVote = () => {
    if (this.props.challenge.userVote) {
      return {
        vote: this.props.challenge.userVote,
        qualifiedVote: this.props.challenge.voteQualified,
      };
    } else {
      return null;
    }
  };

  /**
   * Handles casting a vote on the challenge.
   * 
   * @param {string} voteType - The type of vote ('pass' or 'fail').
   */
  handleVote = (voteType) => {
    this.props.castVote(this.props.challengeId, voteType);
  };

  /**
   * Handles removing the user's vote from the challenge.
   */
  removeVote = () => {
    this.props.removeVote(this.props.challengeId);
  };

  render() {
    const { user, challenge, className } = this.props;

    // Check if the required props are available
    if (!user || !challenge) {
      console.error("User or Challenge prop is not available");
      return null;
    }

    const { authenticated } = this.props.user;
    const userVoteData = this.userVote();
    const userVote = userVoteData ? userVoteData.vote : null;

    return (
      <div>
        <MyButton
          className={className}
          tip="Pass"
          onClick={authenticated ? (userVote === 'pass' ? this.removeVote : () => this.handleVote('pass')) : null}
        >
          <ThumbUpIcon
            style={{ color: authenticated ? (userVote === 'pass' ? '#0000FF' : 'gray') : 'gray' }}
          />
          {authenticated && (challenge.status === 'completed' || challenge.status === 'failed' || userVote) ? (
            <div>{challenge.passVotesOfficial} ({challenge.passVotesAll})</div>
          ) : (
            <div></div>
          )}
        </MyButton>
        <MyButton
          className={className}
          tip="Fail"
          onClick={authenticated ? (userVote === 'fail' ? this.removeVote : () => this.handleVote('fail')) : null}
        >
          <ThumbDownIcon
            style={{ color: authenticated ? (userVote === 'fail' ? '#F44336' : 'gray') : 'gray' }}
          />
          {authenticated && (challenge.status === 'completed' || challenge.status === 'failed' || userVote) ? (
            <div>{challenge.failVotesOfficial} ({challenge.failVotesAll})</div>
          ) : (
            <div></div>
          )}
        </MyButton>
      </div>
    );
  }
}

VoteButton.propTypes = {
  user: PropTypes.object.isRequired,
  challenge: PropTypes.object.isRequired,
  challengeId: PropTypes.string.isRequired,
  castVote: PropTypes.func.isRequired,
  removeVote: PropTypes.func.isRequired,
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionsToProps = {
  castVote,
  removeVote,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(VoteButton);
