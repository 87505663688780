// File: src/pages/styles/unsubscribeStyles.js
import berryBg from '../../images/berryBg-removebg.png';

const UnsubscribeStyles = (theme) => ({
  ...theme.custom,
  unsubscribeContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    top: 0,
    width: '100%',
    height: '100vh',
    textAlign: 'center',
    padding: '24px',
    backgroundImage: `url(${berryBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  textContainer: {
    backgroundColor: '#fff',
    padding: '32px',
    borderRadius: '16px',
    maxWidth: '75%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  pageTitle: {
    fontSize: '3rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: '32px',
  },
  explanationText: {
    fontSize: '1.5rem',
    color: theme.palette.text.primary,
    marginBottom: '32px',
  },
  emailText: {
    fontSize: '1.2rem',
    color: theme.palette.text.secondary,
    marginBottom: '32px',
  },
  progress: {
    color: theme.palette.primary.main,
    marginBottom: '24px',
  },
  processingText: {
    color: theme.palette.text.primary,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    marginTop: '20px',
  },
  button: {
    marginTop: 20,
    marginBottom: 20,
    position: 'relative',
    borderRadius: '20px',
    padding: '8px 16px',
    textTransform: 'none',
    color: '#fff',
    '&:hover': {
      opacity: 0.9,
    },
  },
  homeButton: {
    backgroundColor: '#5E2A84',
    '&:hover': {
      backgroundColor: '#4B0082',
    },
  },
  contactButton: {
    backgroundColor: '#7289DA',
    '&:hover': {
      backgroundColor: '#5E76BA',
    },
  },
});

export default UnsubscribeStyles;