// meed/src/pages/unsubscribe.js
/**
 * @file unsubscribe.js
 * 
 * React component that handles email unsubscribe requests. It processes tokens from URL parameters,
 * provides user feedback, and offers appropriate navigation options based on the result.
 * Uses an ErrorBoundary for resilient error handling and provides multiple user paths for
 * both successful and failed unsubscribe attempts.
 */
import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// MUI Components
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

// Redux and Routing
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { processUnsubscribe } from '../redux/actions/notificationActions';

// Styles and Components
import UnsubscribeStyles from './styles/unsubscribeStyle';
import ErrorBoundary from '../components/common/ErrorBoundary';

/**
 * Status-specific render functions mapping.
 * Extracted from the component to maintain clarity and separation of concerns.
 * @type {Object.<string, Function>}
 */
const statusContent = {
  initial: () => null,

  processing: (classes) => (
    <>
      <CircularProgress size={60} className={classes.progress} />
      <Typography variant="h5" className={classes.processingText}>
        Processing your request...
      </Typography>
    </>
  ),

  success: (classes, result) => (
    <>
      <Typography variant="h2" className={classes.pageTitle}>
        Successfully Unsubscribed
      </Typography>
      <Typography variant="h5" className={classes.explanationText}>
        You've been unsubscribed from {result?.data?.category} notifications.
      </Typography>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          component={Link}
          to="/"
          className={`${classes.button} ${classes.homeButton}`}
        >
          Return Home
        </Button>
        <Button
          variant="contained"
          component={Link}
          to="/contact"
          className={`${classes.button} ${classes.contactButton}`}
        >
          Give Feedback
        </Button>
      </div>
    </>
  ),

  error: (classes) => (
    <>
      <Typography variant="h2" className={classes.pageTitle}>
        Unable to Process Request
      </Typography>
      <Typography variant="h5" className={classes.explanationText}>
        We couldn't process your unsubscribe request. This might be because 
        the link has expired or is invalid.
      </Typography>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          component={Link}
          to="/"
          className={`${classes.button} ${classes.homeButton}`}
        >
          Return Home
        </Button>
        <Button
          variant="contained"
          component={Link}
          to="/search"
          className={`${classes.button} ${classes.searchButton}`}
        >
          Search for User
        </Button>
        <Button
          variant="contained"
          component={Link}
          to="/contact"
          className={`${classes.button} ${classes.reportButton}`}
        >
          Report Problem
        </Button>
      </div>
    </>
  )
};

/**
 * Unsubscribe component for handling email unsubscribe requests.
 * @extends {Component}
 */
class Unsubscribe extends Component {
  state = {
    status: 'initial',
    result: null,
    errors: {}
  };

  componentDidMount() {
    this.setState({ status: 'processing' }, () => {
      this.processUnsubscribeToken();
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.UI.errors !== prevProps.UI.errors) {
      this.setState({ errors: this.props.UI.errors, status: 'error' });
    }
    if (this.props.UI.message !== prevProps.UI.message && this.props.UI.message) {
      toast.success(this.props.UI.message);
    }
  }

  /**
   * Processes the unsubscribe token from URL parameters.
   * @returns {Promise<void>}
   */
  processUnsubscribeToken = async () => {
    try {
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');

      if (!token) {
        this.setState({ status: 'error' });
        return;
      }

      const result = await this.props.processUnsubscribe(token);
      this.setState({ 
        status: 'success',
        result
      });
    } catch (err) {
      this.setState({ status: 'error' });
      toast.error('Failed to process unsubscribe request');
    }
  };

  /**
   * Renders appropriate content based on current status.
   * @returns {JSX.Element} The status-specific content
   */
  renderContent = () => {
    const { classes } = this.props;
    const { status, result } = this.state;
    
    const renderFunction = statusContent[status] || statusContent.error;
    return renderFunction(classes, result);
  };

  render() {
    const { classes } = this.props;

    return (
      <ErrorBoundary>
        <div className={classes.unsubscribeContainer}>
          <ToastContainer 
            position="top-center" 
            autoClose={5000} 
            hideProgressBar={false} 
          />
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.textContainer}>
                {this.renderContent()}
              </div>
            </Grid>
          </Grid>
        </div>
      </ErrorBoundary>
    );
  }
}

Unsubscribe.propTypes = {
  classes: PropTypes.object.isRequired,
  processUnsubscribe: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  UI: state.UI
});

const mapActionsToProps = {
  processUnsubscribe
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(UnsubscribeStyles)(Unsubscribe));