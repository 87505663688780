// meed/src/pages/integrationsTwitch.js
/**
 * @file integrationsTwitch.js
 * @summary This file manages the integration of MeedBot commands with Twitch. It provides a user interface for displaying various
 * MeedBot commands and their descriptions in a tabbed layout, allowing users to interact with and understand the MeedBot functionality on their Twitch streams.
 */
import React, { Component, Fragment } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
// MUI Stuff
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../components/layout/TabPanel';
// Redux stuff
import { connect } from 'react-redux';
import swords from '../images/swords.png';
import integrationsTwitchStyles from './styles/integrationsTwitchStyles';

/**
 * Command data array containing the list of MeedBot commands, their descriptions, and which tabs they appear in.
 */
const commands = [
  {
    id: 1,
    tabs: [0, 1], // 'All' and 'Challenge' tabs
    title: '!meed-challenge',
    description:
      'Embark on a quest of valor with "!meed-challenge". Command a fellow adventurer to prove their mettle by typing their username and the epic task at hand. For example, “!meed-challenge @RingBearer Retrieve the ancient artifact in record time” would call upon @RingBearer to undertake a legendary journey. Forge bonds of fellowship by linking your Twitch account with Meed and join forces in the spirit of friendly competition.',
    example: '!meed-challenge [@user] [challenge description]',
    icon: swords,
  },
  {
    id: 2,
    tabs: [0, 2], // 'All' and 'Donate' tabs
    title: '!meed-donate',
    description:
      'Support your allies in their quests with "!meed-donate". Summon a link to bestow treasures upon your chosen champion by typing their call sign. For instance, “!meed-donate @HobbitHero” will conjure up a pathway to @HobbitHero’s hoard, where you can pledge your gold. Be it a token of gratitude or a bounty for a daring deed, your contribution fuels the adventures ahead.',
    example: '!meed-donate [@user]',
    icon: swords,
  },
  {
    id: 3,
    tabs: [0, 3], // 'All' and 'Help' tabs
    title: '!meed-help',
    description:
      'Should you find yourself at a crossroads, uncertain of the path ahead, invoke "!meed-help". Like a guiding light in the darkest of mines or wretched of spider lairs, this command will reveal knowledge about Meed and provide a scroll to this very compendium. Type "!meed-help" and receive the wisdom you seek to navigate your fellowship through the challenges that await.',
    example: '!meed-help',
    icon: swords,
  },
  {
    id: 4,
    tabs: [0, 4], // 'All' and 'User' tabs
    title: '!meed-user',
    description:
      'To discover the legends behind the names in your fellowship, use "!meed-user". By invoking this rune with a user’s moniker, you can unearth their saga on Meed. For example, typing "!meed-user @ElvenArcher" would unveil the profile of @ElvenArcher, should they be registered in the annals of our chronicles. A link shall be forged, leading you to their tale of quests and conquests.',
    example: '!meed-user [@user]',
    icon: swords,
  },
];

/**
 * Tab labels corresponding to their indices.
 * These labels define the categories of commands that will be displayed in each tab.
 */
const tabLabels = ['All', 'Challenge', 'Donate', 'Help', 'User'];

/**
 * CommandComponent
 * @description Renders an individual command card with title, description, and example.
 * @param {Object} props - Contains the command object and the classes for styling.
 * @returns JSX element for rendering a single command's card.
 */
const CommandComponent = ({ command, classes }) => (
  <Grid container className={`${classes.commandContainerFormat}`}>
    <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
      <img className={`${classes.jobs}`} alt="Icon" src={command.icon} />
    </Grid>
    <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
      <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>
        <div className={`${classes.thick}`}>{command.title}</div>
        <div className={`${classes.commandDescription}`}>{command.description}</div>
      </Grid>
      <Grid className={`${classes.mid}`} item sm={4} xs={12}>
        <Button className={`${classes.commandExample}`}>{command.example}</Button>
      </Grid>
    </Grid>
  </Grid>
);

/**
 * IntegrationsTwitch Component
 * @description Main component for managing and rendering the integrations page for Twitch.
 * Displays available commands in a tabbed layout, allowing users to navigate and explore different categories of commands.
 */
class integrationsTwitch extends Component {
  /**
   * @constructor
   * Initializes the state for the component, including tab selection and UI state management.
   */
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {},
      submitted: false,
      open1: false,
      matches: window.matchMedia('(min-width: 768px)').matches,
      Twitch: window.Twitch,
      searchChall: true,
      socialName: '',
      payAmount: 0,
      open: false,
      body: '',
      endDate: 'xxlarge',
      completeByDate: 'xxlarge',
      influencer: '',
      timeout: null,
      loading: false,
      userHandleUrl: null,
      challengeCost: 0,
      mpChecked: true,
      tChecked: false,
      disabled: false,
      goal: 'No',
      tabValue: 0,
      once: false,
    };
  }

  /**
   * @function componentWillReceiveProps
   * @description Lifecycle method that is invoked when the component receives new props. It updates the state with errors if present in nextProps.
   * @param {Object} nextProps - The new props passed to the component.
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }

  /**
   * Handles the opening of a dialog or modal when a specific challenge is clicked.
   * @param {Object} e - The event triggered by clicking.
   * @param {Object} challenge - The challenge object associated with the click.
   */
  handleClickOpen = async (e, challenge) => {
    e.stopPropagation();
    e.preventDefault();
    await this.setState({
      open1: true,
    });
  };

  /**
   * @function a11yProps
   * @description Provides accessibility props for the tabs.
   * @param {number} index - The index of the current tab.
   * @returns {Object} The accessibility properties for the tab.
   */
  a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  /**
   * @function handleChangeTab
   * @description Updates the state to reflect the currently selected tab.
   * @param {Object} event - The event object triggered by tab selection.
   * @param {number} newValue - The index of the selected tab.
   */
  handleChangeTab = (event, newValue) => {
    this.setState({
      tabValue: newValue,
    });
  };

  /**
   * Scrolls the page to the top of the command list when called.
   */
  scrollToTopOfList = () => {
    const headerElement = document.getElementById('topOfList');
    if (headerElement) {
      headerElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  /**
   * @function componentDidMount
   * @description Lifecycle method that runs after the component is mounted. Ensures the page scrolls to the top when loaded.
   */
  async componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.state.once) {
      if (this.props.home) {
        // Additional logic if needed
      }
    }
  }

  /**
   * @function render
   * @description Renders the component's UI, including the tab navigation and the command cards.
   * @returns JSX element for rendering the entire integrationsTwitch component.
   */
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.team}>
        <Grid container className={classes.reelWrap}>
          {/* <div className={classes.bgThumb}></div> */}
          <Grid
            className={`${classes.gridSpacingNone} ${classes.topJoint} ${classes.mainGrid}`}
            xs={12}
          >
            <div className={classes.cta}>
              <div className={classes.ctaText}>
                <h1>Available Commands</h1>
              </div>
              <div className={classes.ctaBtn}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  onClick={this.scrollToTopOfList}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34.875"
                    height="34.875"
                    viewBox="0 0 34.875 34.875"
                  >
                    <path
                      id="Icon_awesome-chevron-circle-down"
                      data-name="Icon awesome-chevron-circle-down"
                      d="M35.438,18A17.438,17.438,0,1,1,18,.563,17.434,17.434,0,0,1,35.438,18ZM19.2,26.009l9.527-9.527a1.681,1.681,0,0,0,0-2.384l-1.2-1.2a1.681,1.681,0,0,0-2.384,0L18,20.046,10.856,12.9a1.681,1.681,0,0,0-2.384,0l-1.2,1.2a1.681,1.681,0,0,0,0,2.384L16.8,26.009A1.689,1.689,0,0,0,19.2,26.009Z"
                      transform="translate(-0.563 -0.563)"
                      fill="#fff"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.moreWrap}>
          <Grid
            className={`${classes.gridSpacingNone} ${classes.mainGrid} ${classes.needHelpDescFormat}`}
            item
            sm={6}
            xs={12}
          >
            <div className={`${classes.pTextSize}`}>
              <span className={classes.pTextSizeBold}>
                Welcome to the MeedBot Command Hub for Twitch.{' '}
              </span>
              Ready to enhance your stream with interactive challenges? Connect your Twitch to Meed,
              and let your viewers join the fun with the commands outlined below. Let the games
              begin!
            </div>
          </Grid>
          <Grid
            className={`${classes.gridSpacingNone} ${classes.needHelpIntegFormat}`}
            item
            sm={6}
            xs={12}
          >
            <Grid container className={classes.integrationSection}>
              <Grid className={classes.integrationSectionText1} item md={6} xs={12}>
                {' '}
                Ready to add Meed to your stream?{' '}
              </Grid>
              <Grid className={classes.integrationSectionText2} item md={6} xs={12}>
                <Button
                  className={classes.startIntegrationButton}
                  href="https://meed.app/settings"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Start Integration
                </Button>
              </Grid>

              {/*<img className={classes.needHelpImg} src={thumb}></img>*/}
            </Grid>
          </Grid>
        </Grid>
        <Fragment>
          <div id="topOfList" className={`${classes.commandContainerFormat1}`}>
            <AppBar
              className={`${classes.tabHeader} ${classes.commandListFormat}`}
              position="static"
              color="default"
            >
              <Tabs
                value={this.state.tabValue}
                onChange={this.handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className={classes.tabHeader}
                aria-label="scrollable auto tabs example"
              >
                {tabLabels.map((label, index) => (
                  <Tab
                    key={index}
                    className={`${classes.commandHeaderButton} ${
                      this.state.tabValue === index ? classes.commandActiveButton : ''
                    } `}
                    label={label}
                    {...this.a11yProps(index)}
                  />
                ))}
              </Tabs>
            </AppBar>
            {tabLabels.map((label, index) => (
              <TabPanel
                value={this.state.tabValue}
                index={index}
                key={index}
                className={classes.tabPanel}
              >
                {commands
                  .filter((command) => index === 0 || command.tabs.includes(index))
                  .map((command) => (
                    <CommandComponent key={command.id} command={command} classes={classes} />
                  ))}
              </TabPanel>
            ))}
          </div>
        </Fragment>
      </div>
    );
  }
}

/**
 * PropTypes validation for integrationsTwitch component.
 */
integrationsTwitch.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

/**
 * mapStateToProps
 * @description Maps Redux state to component props.
 * @param {Object} state - The Redux state object.
 * @returns {Object} Mapped state properties.
 */
const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {};

// Export the connected component with styles applied
export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(integrationsTwitchStyles)(integrationsTwitch));
