// meed/src/util/MyButton.tsx
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

interface MyButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  tip: string;
  btnClassName?: string;
  tipClassName?: string;
  disabled?: boolean;
}

/**
 * MyButton component for displaying a button with a tooltip.
 * 
 * @component
 * @param props - The properties for the component.
 * @param props.children - The child elements to be displayed inside the button.
 * @param props.onClick - The function to be called when the button is clicked.
 * @param props.tip - The tooltip text to be displayed.
 * @param [props.btnClassName] - The CSS class for the button.
 * @param [props.tipClassName] - The CSS class for the tooltip.
 * @param [props.disabled] - Whether the button is disabled.
 * @returns The rendered button component.
 */
const MyButton: React.FC<MyButtonProps> = ({ 
  children, 
  onClick, 
  tip, 
  btnClassName, 
  tipClassName, 
  disabled 
}) => (
  <Tooltip title={tip} className={tipClassName} placement="top">
    <IconButton
      disabled={disabled}
      onClick={onClick}
      className={btnClassName}
      size="large">
      {children}
    </IconButton>
  </Tooltip>
);

export default MyButton;