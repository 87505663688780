// meed/src/util/errorHandler.ts

import { Dispatch, AnyAction } from 'redux';
import { SET_ERRORS } from '../redux/types';
import { ERROR_MAPPING } from '../redux/errorMapping';

interface ErrorResponse {
  data: {
    error?: string;
    [key: string]: any;
  };
}

export interface ExtendedError extends Error {
  response?: ErrorResponse;
}

/**
 * Handles errors by logging them, dispatching appropriate error actions based on the error type,
 * and optionally rethrowing the error for further handling.
 *
 * @param {ExtendedError} error - The error object caught in the catch block.
 * @param {Dispatch<AnyAction>} dispatch - The Redux dispatch function to send actions.
 * @param {string} context - A string representing the context or action type where the error occurred.
 * @param {boolean} [shouldThrowError=true] - Whether to rethrow the error after processing.
 * @throws {ExtendedError} The original error object after processing, if shouldThrowError is true.
 */
const handleError = (
  error: ExtendedError, 
  dispatch: Dispatch<AnyAction>, 
  context: string, 
  shouldThrowError: boolean = true
): void => {
  console.error(`Error occurred in ${context}:`, error.response ? error.response.data : error);

  if (error.response && error.response.data.error) {
    const errorType = ERROR_MAPPING[error.response.data.error];
    if (errorType) {
      dispatch({ type: errorType, error: { message: error.response.data.error, context } });
    } else {
      dispatch({ type: SET_ERRORS, payload: { ...error.response.data, context } });
    }
  } else {
    dispatch({ type: SET_ERRORS, payload: { ...error, context } });
  }

  if (shouldThrowError) {
    throw error;
  }
};

export default handleError;