// meed/src/util/modLevelValues.ts

/**
 * Enum representing different moderator levels and their corresponding numeric values.
 */
export enum ModLevel {
    Basic = 1,
    Intermediate = 2,
    Full = 3,
  }
  
  /**
   * Object mapping human-readable moderator levels to numeric values.
   * Each level grants different permissions for moderators within the system.
   * All are subject to change and part of an ongoing discussion.
   * - Tier 1 (Basic): Currently can flag/hide challenges and ban users.
   * - Tier 2 (Intermediate): Inherits permissions from Tier 1 and is also reserved for future use.
   * - Tier 3 (Full): Allows moderators to accept, decline, delete, complete, and fail challenges. Full moderators can also flag/hide challenges and ban users.
   *
   * Note: The permissions for each tier are subject to change as the platform evolves and further decisions are made.
   */
  export const modLevelValues: Record<string, ModLevel> = {
    basic: ModLevel.Basic,
    intermediate: ModLevel.Intermediate,
    full: ModLevel.Full,
  };
  
  /**
   * Interface representing the structure of a moderator's information for an influencer.
   */
  interface ModeratorInfo {
    influencerId: string;
    level: string;
  }
  
  /**
   * Interface representing the structure of a user object with moderator information.
   */
  interface User {
    moderatedInfluencers: ModeratorInfo[];
  }
  
  /**
   * Selector to get the moderator level for a specific user and influencer.
   * This function retrieves the moderator level information from the user's moderatedInfluencers array.
   *
   * @param user - The user object containing moderator information.
   * @param influencerId - The ID of the influencer for whom the moderator level is to be retrieved.
   * @returns The moderator level of the user for the specified influencer.
   *          Returns 'none' if the moderator level is not found.
   */
  export const getModeratorLevel = (user: User, influencerId: string): string => {
    const influencer = user.moderatedInfluencers.find(inf => inf.influencerId === influencerId);
    return influencer ? influencer.level : 'none';
  };