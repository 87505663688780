// meed/src/util/config.ts

/**
 * The endpoint URL for the Twitch API.
 */
export const TWITCH_API_URL = 'https://api.twitch.tv/helix'; // Replace with actual URL if needed

/**
 * The Twitch Client ID for OAuth authentication.
 */
export const TWITCH_CLIENT_ID = 'ldsz98zpapc5tayyp34ypb0rgxds2j';

/**
 * The redirect URI for Twitch OAuth authentication.
 */
export const TWITCH_REDIRECT_URI = 'https://meed.app/';

/**
 * The Discord Client ID for OAuth authentication.
 */
export const DISCORD_CLIENT_ID = '1044064242598412359';

/**
 * The redirect URI for Discord OAuth authentication.
 */
export const DISCORD_REDIRECT_URI = 'https://meed.app/auth/discord';

/**
 * The invite URL for Meed Discord Bot. Clicking should open the Discord application.
 */
export const DISCORD_INVITE_URL = 'https://discord.com/oauth2/authorize?client_id=1039218567297646654';

export const DISCORD_MEED_SERVER = 'https://discord.gg/6aPytwJH83';

/**
 * The Streamlabs Client ID for OAuth authentication.
 */
export const STREAMLABS_CLIENT_ID = '9be6b7d0-e8b2-4e76-bf1d-e8cf72a92167';

/**
 * The redirect URI for Streamlabs OAuth authentication.
 */
export const STREAMLABS_REDIRECT_URI = 'https://meed.app/oauth/streamlabs';

/**
 * The Algolia Application ID for search functionality.
 */
export const ALGOLIA_APP_ID = 'NGAO27P2B5';

/**
 * The Algolia API key for search functionality.
 */
export const ALGOLIA_API_KEY = 'cb398cda25d3e47c5d89065f53de82cd';

/**
 * The API_BASE_URL. Currently: <v1>.
 */
export const API_BASE_URL= '/v1';

