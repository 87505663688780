// meed/src/components/challenge/AcceptChallenge.js
import React, { Component, Fragment } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import MyButton from '../../util/MyButton';
import { toast } from 'react-toastify';

// MUI Stuff
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import { connect } from 'react-redux';
import { acceptChallenge } from '../../redux/actions/dataActions';
import acceptChallengeStyles from './styles/acceptChallengeStyles';

class AcceptChallenge extends Component {
  state = {
    open: false,
    disabled: false
  };
  handleOpen = (event) => {
    this.setState({ disabled: true });
    event.preventDefault();
    event.stopPropagation();
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  acceptChallenge = async () => {
    try {
      const res = await this.props.acceptChallenge(this.props.challengeId);
      if (res && res.success) {
        toast.success('SUCCESS: Challenge Accepted!');
        this.props.updateChallenge(this.props.challengeId);
      } else {
        // Handle the case where the challenge is not accepted
        toast.error('Failure: Challenge not Accepted!');
      }
    } catch (err) {
      // Handle any additional errors that might occur during the await operation
      toast.error('Failure: Challenge not Accepted!');
    } finally {
      // Update the state regardless of the operation's outcome
      this.setState({ disabled: false, open: false });
    }
  };  
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <MyButton
          tip="Accept Challenge"
          onClick={this.handleOpen}
          btnClassName={classes.acceptButton}
          disabled={this.state.disabled}
        >
          Accept
        </MyButton>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            Are you sure you want to accept this challenge ?
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleClose}>
              Cancel
            </Button>
            <Button onClick={this.acceptChallenge} >
              Accept
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

AcceptChallenge.propTypes = {
  acceptChallenge: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  challengeId: PropTypes.string.isRequired
};

export default connect(
  null,
  { acceptChallenge }
)(withStyles(acceptChallengeStyles)(AcceptChallenge));
