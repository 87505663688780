// meed/src/components/challenge/CompleteChallenge.js
import React, { Component, Fragment } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import MyButton from '../../util/MyButton';
import { toast } from 'react-toastify';

// MUI Stuff
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import { connect } from 'react-redux';
import { completeChallenge } from '../../redux/actions/dataActions';

const styles = {
  completeButton: {
    background: '#14C7FC',
    color: '#fff',
    position: 'relative',
    border: 'solid 1px #14C7FC',
    borderRadius: 8,
    padding: '6px 16px',
    fontSize: 16,
    fontWeight: 700,
    opacity: 0.9,
    transition: 'all .4s',
    '&:hover': {
      opacity: 1,
      border: 'solid 1px #5B5A57',
      background: '#14C7FC'
    },
  }
};

/**
 * React Component to complete a challenge
 * @extends Component
 */
class CompleteChallenge extends Component {
  state = {
    open: false,
    disabled: false
  };
  handleOpen = (event) => {
    this.setState({ disabled: true });
    event.preventDefault();
    event.stopPropagation();
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  completeChallenge = async () => {
    this.setState({ disabled: true });
    try {
      const res = await this.props.completeChallenge(this.props.challengeId);
      // Check the correct message for a successful operation
      if (res && res.success) {
        toast.success('SUCCESS: Challenge completed, now for voting!');
        this.props.updateChallenge(this.props.challengeId);
      } else {
        toast.error('Failure: Challenge not Completed!');
      }
    } catch (err) {
      // This will catch any additional errors that might occur during the await operation
      toast.error('Failure: Challenge not Completed!');
    } finally {
      // Update the state regardless of the operation's outcome
      this.setState({ disabled: false, open: false });
    }
  };  
  render() {
    const { classes } = this.props;

    // Changes tooltip based on this.props.challenge.votingType
    // TODO: Move this out of render?
    const votingTypeTips = {
      all: "Move Challenge to 24hr voting stage and cast a pass vote. Challenge passes with more pass votes than fail votes.",
      both: "Move Challenge to 24hr voting stage and cast a pass vote. Challenge fails if creator doesn't make a pass vote.",
      boost: "Move Challenge to 24hr voting stage and cast a pass vote. Challenge passes with more qualified pass votes than qualified fail votes.",
      creator: "Move Challenge to 24hr voting stage and cast a pass vote. Challenge fails if creator doesn't make a pass vote.",
      custom: "Move Challenge to 24hr voting stage and cast a pass vote. Challenge passes with more qualified pass votes than qualified fail votes.",
      default: "Move Challenge to 24hr voting stage and cast a pass vote. Challenge passes unless you remove or change the vote to fail.",
    };


    // Get the tip based on the voting type from the dictionary
    const tip = votingTypeTips[this.props.votingType];

    return (
      <Fragment>
        <MyButton
          tip={tip}
          onClick={this.handleOpen}
          btnClassName={classes.completeButton}
          disabled={this.state.disabled}
        >
          Complete
        </MyButton>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            Are you sure you want to complete this challenge ?
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleClose}>
              Cancel
            </Button>
            <Button onClick={this.completeChallenge}>
              Complete
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

CompleteChallenge.propTypes = {
  completeChallenge: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  challengeId: PropTypes.string.isRequired,
  votingType: PropTypes.string.isRequired
};

export default connect(
  null,
  { completeChallenge }
)(withStyles(styles)(CompleteChallenge));
