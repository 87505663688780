// meed/src/util/ChallengeSkeleton.js
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// MUI
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

import { withStyles } from '@mui/styles';
import mascot from '../images/no-img.png';
const styles = (theme) => ({
  ...theme.custom,
  card: {
    display: 'flex',
    backgroundColor: '#7A5DF9',
    position: 'relative',
    cursor: 'pointer',
    flexDirection: 'row',
    marginBottom: 20,
    marginRight: 20,
    borderRadius: '0px',
    maxWidth: '350px',
    minWidth: '260px',
    width: '100%',
    color: '#fff',
  },
  noMargin: {
    marginBottom: 2
  },
  cardContent: {
    background: 'transparent',
    width: '100%',
    flexDirection: 'column',
    padding: 25
  },
  cover: {
    minWidth: 200,
    objectFit: 'cover'
  },
  handle: {
    width: 60,
    height: 18,
    backgroundColor: theme.palette.primary.main,
    marginBottom: 7
  },
  date: {
    height: 14,
    width: 100,
    backgroundColor: 'rgba(0,0,0, 0.3)',
    marginBottom: 10
  },
  fullLine: {
    height: 15,
    width: '70%',
    backgroundColor: 'rgba(0,0,0, 0.6)',
    marginBottom: 10
  },
  halfLine: {
    height: 15,
    width: '50%',
    backgroundColor: 'rgba(0,0,0, 0.6)',
    marginBottom: 10
  },
  yellowCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#5B5A57', 
    borderRadius: '50%',
    position: 'absolute',
    top: '10px',
    right: '10px',
    width: '50px',
    height: '50px'
  },
  profileImage: {
    width: '100%'
  }
});

const ChallengeSkeleton = (props) => {
  const { classes } = props;

  const content = Array.from({ length: 5 }).map((item, index) => (
    <Card className={`${classes.card} ${props.noMargin ? classes.noMargin : ''}`} key={index}>
      <CardContent className={classes.cardContent}>
        <div className={classes.yellowCircle}><img
                  src={mascot}
                  alt="profile"
                  className={classes.profileImage}
                /></div>
        <div className={classes.fullLine} /> 
        <div className={classes.fullLine} /> 
        <div className={classes.fullLine} />
      </CardContent>
    </Card>
  ));

  return <Fragment>{content}</Fragment>;
};

ChallengeSkeleton.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChallengeSkeleton);
