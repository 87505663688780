// meed/src/pages/styles/forogtPasswordStyles.js
import berryBg from '../../images/berryBg-removebg.png';

const forgotPasswordStyles = (theme) => ({
    ...theme.custom,
    form: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        left: 0,
        top: 64,
        backgroundImage: `url(${berryBg})`,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        padding: 50,
    },
    buttonDefault: {
        width: '100%',
        marginTop: 30,
        borderRadius: '2px',
        maxWidth: '220px'
    },
    label: {
        color: '#fff !important',
        borderColor: '#fff',
        '& .MuiInput-underline:after': {
            borderBottomColor: '#fff',
        },
        '& input, textarea': {
            color: '#fff'
        }
    },
    pageTitle: {
        color: '#fff'
    },
    gang: {
        color: 'fff',
        '& label.Mui-focused': {
            color: '#fff',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#fff',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#fff',
            },
            '&:hover fieldset': {
                borderColor: '#fff',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#fff',
            },
        },
    },
});

export default forgotPasswordStyles;