// meed/src/services/AuthService.js
import { toast } from "react-toastify";
import handleError from "../util/errorHandler";

/**
 * Service for handling OAuth authentication with various platforms.
 */
class AuthService {
    /**
     * Authenticates with a specified platform using the provided configuration.
     *
     * @param {Object} authConfig - The authentication configuration.
     * @param {string} authConfig.platform - The name of the platform (e.g., 'Twitch', 'Streamlabs', 'Discord').
     * @param {string} authConfig.baseUri - The base URI for the OAuth authorization endpoint.
     * @param {string} authConfig.clientId - The client ID for the OAuth application.
     * @param {string} authConfig.redirectUri - The redirect URI for the OAuth application.
     * @param {string} authConfig.scope - The scope of the OAuth request.
     * @param {Function} dispatch - The Redux dispatch function.
     * @param {Function} getTokenAction - The action to dispatch for retrieving the authentication token.
     * @returns {Promise<Object>} - A promise that resolves with the token response.
     */
    static async authenticate(authConfig, dispatch, getTokenAction) {
      try {
        const codeUri = this.constructCodeUri(authConfig);
        const code = await this.getCode(codeUri);
        if (!code) {
          toast.error(`Failed to authenticate with ${authConfig.platform}.`);
          return { success: false, message: `Failed to authenticate with ${authConfig.platform}.` };
        }
  
        const tokenResponse = await dispatch(getTokenAction(code));
        if (tokenResponse && tokenResponse.success) {
          toast.success(`${authConfig.platform} Account Linked!`);
        } else {
          toast.error(`${authConfig.platform} Account not Linked. ${tokenResponse.error ? tokenResponse.error : ''}`);
        }
  
        return tokenResponse;
      } catch (error) {
        console.error(`Error occurred while authenticating with ${authConfig.platform}:`, error.response ? error.response.data : error);
        toast.error(`Failed to authenticate with ${authConfig.platform}.`);
  
        handleError(error, dispatch, `authenticate ${authConfig.platform}`);
      }
    }
  
    /**
     * Constructs the OAuth authorization URL based on the provided configuration.
     *
     * @param {Object} authConfig - The authentication configuration.
     * @param {string} authConfig.baseUri - The base URI for the OAuth authorization endpoint.
     * @param {string} authConfig.clientId - The client ID for the OAuth application.
     * @param {string} authConfig.redirectUri - The redirect URI for the OAuth application.
     * @param {string} authConfig.responseType - The response type for the OAuth request (default is 'code').
     * @param {string} authConfig.scope - The scope of the OAuth request.
     * @returns {string} - The constructed authorization URL.
     */
    static constructCodeUri({ baseUri, clientId, redirectUri, responseType = 'code', scope }) {
      const uri = new URL(baseUri);
      uri.searchParams.append('client_id', clientId);
      uri.searchParams.append('redirect_uri', redirectUri);
      uri.searchParams.append('response_type', responseType);
      uri.searchParams.append('scope', scope);
      return uri.toString();
    }
  
    /**
     * Opens a new window with the given URI to retrieve an authorization code.
     * Continuously polls the new window's URL every 500ms to capture the code appended as a query parameter.
     *
     * @param {string} uri - The authentication URI which, when accessed, will provide an auth code in the URL.
     * @returns {Promise<string>} - A promise that resolves with the authorization code if successful.
     */
    static getCode(uri) {
      return new Promise((resolve, reject) => {
        const authWindow = window.open(
          uri,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes,width=700,height=700"
        );
  
        let url;
  
        const intervalId = setInterval(() => {
          try {
            url = authWindow && authWindow.location && authWindow.location.search;
          } catch (e) { }
          if (url) {
            const parsedCode = url.substring(1);
            const urlParams = new URLSearchParams(parsedCode);
            const code = urlParams.get('code');
            if (authWindow && code) {
              clearInterval(intervalId);
              authWindow.close();
              resolve(code);
            }
          }
        }, 500);
  
        authWindow.onbeforeunload = () => {
          clearInterval(intervalId);
          reject(new Error('User closed the authentication window.'));
        };
      });
    }
  }
  
  export default AuthService;
  