// meed/src/components/challenge/styles/reportChallengeStyles.js
const reportChallengeStyles = {
    reportButton: {
        background: '#FFDF57',
        cursor: 'pointer',
        color: '#5B5A57',
        position: 'relative',
        border: 'solid 1px #FFDF57',
        borderRadius: 8,
        padding: '6px 16px',
        fontWeight: 700,
        transition: 'all .4s',
        '&:hover': {
            background: '#FFDF57',
            opacity: 1,
            border: 'solid 1px #5B5A57',
        }
    },
    reportChallenge: {
        fontSize: '.875rem',
        color: '#5B5A57',
        marginBottom: 5,
        cursor: 'pointer',
        '&:hover': {
            background: 'transparent',
            opacity: .9,
        }
    },
    menuFormat: {
        width: '45%',
         //fontSize: 20,
         /*'& ::after': {
           content: '',
           borderRadius: '25px',
         },*/
         
        /* '& fieldset': {
           borderRadius: '25px',
           //marginRight: '35px',
           paddingRight: '50px'
         },*/
         '& .MuiInputBase-input':{
           borderRadius: '40px',
           width: '95%',
         },
       },
    fieldFormat: {
  
        //border: 'solid 1px',
        //borderColor: 'rgba(0, 0, 0, 0.23)',
        //paddingRight: "20px",
      
        '& fieldset': {
          borderRadius: 25,
          //borderRadius: '35px',
          //marginRight: '20px'
                
        },
        '@media (max-width: 960px)': {
          //marginLeft: "auto",
          display:'contents',
          width: 'max-content',
          marginRight:'0px',

        },
    },
    
};

export default reportChallengeStyles;