// meed/src/redux/reducers/uiReducer.ts
import {
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  STOP_LOADING_UI,
  HIDE_NAV,
  SHOW_NAV,
  SHOW_TOAST,
  CLEAR_TOAST_MESSAGE
} from '../types';

// Define the shape of the UI state
interface UIState {
  loading: boolean;
  errors: any | null;
  showNav: boolean;
  toastMessage: string;
}

// Define the initial state
const initialState: UIState = {
  loading: false,
  errors: null,
  showNav: true,
  toastMessage: '',
};

// Define types for all possible actions
type UIAction = 
  | { type: typeof SET_ERRORS; payload: any }
  | { type: typeof CLEAR_ERRORS }
  | { type: typeof LOADING_UI }
  | { type: typeof STOP_LOADING_UI }
  | { type: typeof HIDE_NAV }
  | { type: typeof SHOW_NAV }
  | { type: typeof SHOW_TOAST; payload: string }
  | { type: typeof CLEAR_TOAST_MESSAGE };

function uiReducer(state = initialState, action: UIAction): UIState {
  switch (action.type) {
    case SET_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        errors: null
      };
    case LOADING_UI:
      return {
        ...state,
        loading: true
      };
    case STOP_LOADING_UI:
      return {
        ...state,
        loading: false
      };
    case HIDE_NAV:
      return {
        ...state,
        showNav: false
      };
    case SHOW_NAV:
      return {
        ...state,
        showNav: true
      };
    case SHOW_TOAST:
      return {
        ...state,
        toastMessage: action.payload
      };
    case CLEAR_TOAST_MESSAGE:
      return {
        ...state,
        toastMessage: ''
      };
    default:
      return state;
  }
}

export default uiReducer;