// meed/src/components/challenge/styles/failChallengeStyles.js
const failChallengeStyles = {
    failButton: {
        background: '#E1E0DE',
        color: '#5B5A57',
        position: 'relative',
        border: 'solid 1px #E1E0DE',
        borderRadius: 8,
        padding: '6px 16px',
        fontSize: 16,
        fontWeight: 700,
        marginRight: 5,
        opacity: 0.9,
        transition: 'all .4s',
        '&:hover': {
            opacity: 1,
            border: 'solid 1px #5B5A57',
            background: '#E1E0DE'
        },
    }
};

export default failChallengeStyles;