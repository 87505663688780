// meed/src/util/variables.ts
/**
 * The URL for authorizing and integrating the application with a Discord account.
 * This link directs users to Discord's OAuth2 authorization flow, allowing them to authorize the application
 * to access their Discord account with specific permissions.
 * 
 * @type {string}
 */
export const discordLink = "https://discord.com/oauth2/authorize?client_id=1039218567297646654&scope=identify%20bot%20applications.commands&permissions=2146958591";

/**
 * V0.0.3
 * 
 * The URL for integrating with the Twitch extension.
 * This link directs users to the Twitch dashboard for a specific version of the Twitch extension.
 * It is used for managing Twitch extension settings and integration.
 * 
 * @type {string}
 */
export const twitchExtensionIntegrationLink = "https://meed.app/waitlist";
// This is the actual link but until we're ready to add it, we'll just redirect to the Settings section/waitlist section
// export const twitchExtensionIntegrationLink = "https://dashboard.twitch.tv/extensions/vxeoxt5q9ku5fex4upoinrp1l98kxz-0.0.3";
