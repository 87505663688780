// meed/src/pages/styles/contactStyles.js
import gotSkittles from '../../images/gotSkittles.png';
const contactStyles = (theme) => ({
    ...theme.custom,
    form: {
      /* //position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      left: 0,
      top: 64,
      //backgroundImage: `url(${berryBg})`,
      width: '100%',
      height: '100%',
      textAlign: 'left',
      padding: 50 */
      marginLeft:'10px',
    },
    buttonDefault: {
      
      marginTop: 30,
      borderRadius: '35px',
      maxWidth: '220px',
      //top: '934px',
      //left: '136px',
      width: '165px',
      height: '45px',
      backgroundColor: "#7B5DF9",
      color:'#FFFFFF'
      
    },
    contactImg: {
      width: '100%',
      height: '100%',
      //marginTop: 30,
      marginLeft:'20px',
      borderRadius: '2px',
      backgroundImage: `linear-gradient(rgba(123,93,249, .76), rgba(123,93,249, .76)), url(${gotSkittles})`,
      backgroundPosition: 'left',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      
      //maxWidth: '220px'
    },
    label: {
      color: '#fff',
      borderColor: '#fff',
      '& .MuiInput-underline:after': {
        borderBottomColor: '#fff',
      },
      '& .MuiSelect-root' :{
         width:'95%',
      },
    },
    gang: {
      '& label.Mui-focused': {
        color: '#fff',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#fff',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#fff',
          borderRadius: 25,
        },
        '&:hover fieldset': {
          borderColor: '#fff',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#fff',
          borderRadius: 25,
        },
        
      },
      '& .MuiOutlinedInput-input':{
        borderRadius: 25,
      }
  
    },
    menuFormat: {
     width: '45%',
      //fontSize: 20,
      /*'& ::after': {
        content: '',
        borderRadius: '25px',
      },*/
      
     /* '& fieldset': {
        borderRadius: '25px',
        //marginRight: '35px',
        paddingRight: '50px'
      },*/
      '& .MuiInputBase-input':{
        borderRadius: '40px',
        width: '95%',
      },
    },
    pageTitle: {
      fontWeight: 600,
      fontSize: '2em',
      color: '#707070',
      marginTop:'80px'
    },
    subHeader: {
      fontWeight: 400,
      fontSize: '1.25em',
      color: '#7B5DF9',
      margin: 0
    },
    formEntryHeader: {
      fontWeight: 400,
      fontSize: '1.25em',
      color: '#707070',
      margin: 0
    },
    roundedRadius: {
      width: '45%',
      marginRight: '20px',
      //paddingRight: '36px',
      '& fieldset': {
        borderRadius: 25,
        '& .MuiOutlinedInput-root':{
        borderRadius: 25,
       
        },
        '& .MuiSelect-Root':{
          borderRadius: 25,
          width: '95%',
        }
      }
    
    /*  '& .MuiInputBase-input:-webkit-autofill': {
        borderRadius: 25,
        backgroundColor: 'red',
      },
      '& .MuiSelect-selectMenu':{
        borderRadius: 25,
        backgroundColor: 'red',
      }*/
    },
    fieldFormat: {
  
      //border: 'solid 1px',
      //borderColor: 'rgba(0, 0, 0, 0.23)',
      //paddingRight: "20px",
    
      '& fieldset': {
        borderRadius: 25,
        //borderRadius: '35px',
        //marginRight: '20px'
        
      },
      '@media (max-width: 960px)': {
        //marginLeft: "auto",
        display:'contents',
        width: 'max-content',
        marginRight:'0px',

      },
    },
    fieldFormatRight: {
  
      
      '& fieldset': {
      //  borderRadius: 25,
        //borderRadius: '35px',
       marginRight: '0px',
       
       // width : "100%",
      },
      '@media (max-width: 960px)': {
        //marginLeft: "auto",
        marginRight:'0px',    
      },
    },
    fieldFormatLeft: {
  
     // width : "50%",
    },
    messageFormat: {
  
      //border: 'solid 1px',
      //borderColor: 'rgba(0, 0, 0, 0.23)',
      '& fieldset': {
        borderRadius: 25,
        //borderRadius: '35px',
        marginRight: '20px',
        
      },
      width: '98%',
      //padding: '18.5px 14px',
      
      //height: '17px',
      //margin:'0px',
    },
  });

export default contactStyles;