// meed/src/util/theme.js
const theme = {
  palette: {
    primary: {
      light: '#f1b6d4',
      main: '#333333',
      dark: '#008394',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ff6333',
      main: '#6E55D9',
      dark: '#b22a00',
      contrastText: '#fff'
    },
    thirdary: {
      light: '#f1b6d4',
      main: '#6E55D9',
      dark: '#008394',
      contrastText: '#fff'
    },
    upvote: {
      main: '#BB6BD9'
    }
  },
  custom: {
    '& .MuiExpansionPanelSummary-expanded': {
      background: 'purple'
    },
    typography: {
      useNextVariants: true
    },
    form: {
      textAlign: 'center',
    },
    image: {
      margin: '20px auto 20px auto'
    },
    pageTitle: {
      margin: '10px auto 10px auto'
    },
    textField: {
      margin: '10px auto 10px auto'
    },
    button: {
      marginTop: 20,
      position: 'relative'
    },
    customError: {
      color: '#FF0000',
      fontSize: '0.8rem',
      marginTop: 10
    },
    progress: {
      position: 'absolute'
    },
    invisibleSeparator: {
      border: 'none',
      margin: 4
    },
    visibleSeparator: {
      width: '100%',
      borderBottom: '1px solid rgba(0,0,0,0.1)',
      marginBottom: 20
    },
    paper: {
      background: 'transparent',
      boxShadow: 'none',
      padding: 20
    },
    roundedInput:{
      marginBottom:'15px',
      width:"calc(100% - 8px)",
      border: "1px solid #E0E0E0",
      color: "#BDBDBD",
      borderRadius:'20px',
      padding:"2.5px",
      paddingLeft:"15px",
      paddingRight:"0px",
      marginTop:'10px'
    },
    profile: {
      padding: 50,
      '@media (max-width: 600px)': {
        padding: 10,
        paddingBottom: 0
      },
      '& .image-wrapper': {
        background: 'transparent',
        textAlign: 'center',
        position: 'relative',
        '& button': {
          position: 'absolute',
          top: '80%',
          left: '70%'
        }
      },
      '& .profile-image': {
        width: 125,
        height: 125,
        objectFit: 'cover',
        maxWidth: '100%',
        borderRadius: '50%',
        '@media (max-width: 600px)': {
          width: 70,
          height: 70,
        }
      },
      '& .profile-details': {
        background: 'transparent',
        position: 'relative',
        '& span, svg': {
          verticalAlign: 'middle'
        },
        
      },
      '& hr': {
        border: 'none',
        margin: '0 0 10px 0',
        '@media (max-width: 600px)': {
          margin: 0
        },
      },
      '& svg.button': {
        '&:hover': {
          cursor: 'pointer'
        }
      }
    },
    buttons: {
      textAlign: 'center',
      '& a': {
        margin: '20px 10px'
      }
    },
    overrides: {
      MuiModal:{
          root: {
              zIndex: 2300,
          }
      }
    }
  }
};

export default theme;