// meed/src/config/environments.ts
/**
 * @file environments.ts
 * 
 * Configuration file that defines environment-specific settings for the Meed frontend application.
 * This includes API base URLs and Firebase configuration details for local, development, and production environments.
 *
 * @module environmentsConfig
 */

/**
 * Interface representing the structure of the environment configuration.
 * @interface EnvironmentConfig
 */
export interface EnvironmentConfig {
    api: {
        /**
         * The base URL for the API used in the environment.
         * @type {string}
         */
        baseUrl: string;

        /**
         * Flag indicating whether to use Firebase Functions as the API endpoint.
         * Optional, defaults to false if not specified.
         * @type {boolean | undefined}
         */
        useFunctions?: boolean;
    };
    firebase: {
        /**
         * The Firebase API key for the environment.
         * @type {string}
         */
        apiKey: string;

        /**
         * The Firebase authentication domain for the environment.
         * @type {string}
         */
        authDomain: string;

        /**
         * The Firebase database URL for the environment.
         * @type {string}
         */
        databaseURL: string;

        /**
         * The Firebase project ID for the environment.
         * @type {string}
         */
        projectId: string;

        /**
         * The Firebase storage bucket for the environment.
         * @type {string}
         */
        storageBucket: string;

        /**
         * The Firebase messaging sender ID for the environment.
         * @type {string}
         */
        messagingSenderId: string;

        /**
         * The Firebase app ID for the environment.
         * @type {string}
         */
        appId: string;

        /**
         * The Firebase measurement ID for analytics, if applicable.
         * @type {string}
         */
        measurementId: string;
    };
}

/**
 * Record of different environment configurations for the Meed application.
 * Supports `local`, `development`, and `production` environments.
 * @type {Record<string, EnvironmentConfig>}
 */
export const environments: Record<string, EnvironmentConfig> = {
    local: {
        api: {
            baseUrl: 'http://localhost:5001/meed-dev/us-east1/api',
            useFunctions: true
        },
        firebase: {
            apiKey: "AIzaSyAxxyWKeDwT5gO5Klx9C0S_esl-DzvzsHU",
            authDomain: "meed-dev.firebaseapp.com",
            databaseURL: "https://meed-dev.firebaseio.com",
            projectId: "meed-dev",
            storageBucket: "meed-dev.appspot.com",
            messagingSenderId: "563518078673",
            appId: "1:563518078673:web:349c19cba1b15ff2700ff0",
            measurementId: "G-2NF4YFLLC5"
        }
    },
    development: {
        api: {
            baseUrl: 'https://meed-dev.web.app/api',
            useFunctions: false
        },
        firebase: {
            apiKey: "AIzaSyAxxyWKeDwT5gO5Klx9C0S_esl-DzvzsHU",
            authDomain: "meed-dev.firebaseapp.com",
            databaseURL: "https://meed-dev.firebaseio.com",
            projectId: "meed-dev",
            storageBucket: "meed-dev.appspot.com",
            messagingSenderId: "563518078673",
            appId: "1:563518078673:web:349c19cba1b15ff2700ff0",
            measurementId: "G-2NF4YFLLC5"
        }
    },
    production: {
        api: {
            baseUrl: 'https://meed.app/api',
            useFunctions: false
        },
        firebase: {
            apiKey: "AIzaSyAhE9bQpvSnYaTzp7graC2BrgtR9U4Kw9k",
            authDomain: "covenant-28c21.firebaseapp.com",
            databaseURL: "https://covenant-28c21.firebaseio.com",
            projectId: "covenant-28c21",
            storageBucket: "covenant-28c21.appspot.com",
            messagingSenderId: "1053987706594",
            appId: "1:1053987706594:web:85eb2ad3ff473d687b30bb",
            measurementId: "G-Z14W8XXQ95"
        }
    }
};
