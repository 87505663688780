// meed/src/components/challenge/Challenge.js
import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { getChallenge, likeChallenge, unlikeChallenge, removeVote } from '../../redux/actions/dataActions';
import CompleteChallenge from './CompleteChallenge';
import DeclineChallenge from './DeclineChallenge';
import AcceptChallenge from './AcceptChallenge';
import FailChallenge from './FailChallenge';
import ReportChallenge from './ReportChallenge';
import OthersDecideChallenge from './OthersDecideChallenge';
import VoteButton from './VoteButton';
import { getAnalytics, logEvent } from "firebase/analytics";
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
// MUI Stuff
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
// Icons
// Redux
import { connect } from 'react-redux';
import {
  getTotalFundraised,
  acceptChallenge,
  declineChallenge,
  completeChallenge,
  failChallenge,
  cancelChallenge,
  boostChallenge,
  submitClip,
} from '../../redux/actions/dataActions'
import { mod, ban, unban, unmod, reportUser } from '../../redux/actions/userActions';
import { Button } from '@mui/material';
import heart2 from '../../images/heart2x.png';
import money from '../../images/money2x.png';
import time from '../../images/time2x.png';
import { toast } from 'react-toastify';
import ProfileSkeleton from '../../util/ProfileSkeleton';
import close from '../../images/close.png';
import mascot from '../../images/no-img.png';
import swords from '../../images/swords.png';
import grayMoney from '../../images/grayMoney.png';
import grayTime from '../../images/grayTime.png';
import grayHeart from '../../images/grayHeart.png';
import grayVid from '../../images/grayVid.png';
import grayLink from '../../images/grayLink.png';
import grayBang from '../../images/grayBang.png';
import grayDots from '../../images/grayDots.png';
import berry from '../../images/singleBerryPurple.png';
import newTab from '../../images/newTab.png';
import { Fragment } from 'react';
import { challengeStyles } from './styles/challengeStyles.js';
import { getModeratorLevel, modLevelValues } from '../../util/modLevelValues';

let gettingChall = false;
const analytics = getAnalytics();

const htmlDecode = (input) => {
  let doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

/**
 * Returns a human-readable string representing a time duration based on a given key.
 * This function maps predefined keys to their corresponding time sizes (e.g., '1 Hour', '30 Seconds').
 *
 * @param {string} nam - The key used to determine the time size. Expected values include 'xxxxsmall', 'xxxsmall', etc.
 * @returns {string} The human-readable time size corresponding to the provided key, or 'Forever' if the key is not recognized.
 */
const getTimeSize = (nam) => {
  const timeMappings = {
    'xxxxsmall': '30 Seconds',
    'xxxsmall': '1 Minute',
    'xxsmall': '5 Minutes',
    'xsmall': '10 Minutes',
    'small': '30 Minutes',
    'medium': '1 Hour',
    'large': '6 Hours',
    'xlarge': '12 Hours',
    'xxlarge': '1 Day',
    'xxxlarge': '3 Days',
    'xxxxlarge': '6 Days',
    'xxxxxlarge': '14 Days',
    'xxxxxxlarge': '4 Weeks',
    'default': 'Forever',
  };

  // Return the corresponding time size or 'Forever' if not found
  return timeMappings[nam] || timeMappings['default'];
};


class Challenge extends Component {
  state = {
    Twitch: window.Twitch,
    clicked: false,
    thisChallenge: {
      challengeId: '',
      challengeName: '',
      createdAt: '',
      completeByDate: '',
      completeByDateAsTimestamp: "",
      description: '',
      influencerAtName: '',
      initialCreatorPaymentAmount: '',
      likeCount: 0,
      status: '',
      userHandle: '',
      userImage: '',
      clickedChallenge: '',
      payAmount: 0,
      timerInterval: true,
      invalidLink: '',
      clip: ''
    },
    showAction: true,
    showBoost: false,
    showClips: false,
    showLink: false,
    showMod: false,
    modalChallenge: {},
    open: false,
    doIt: true,
    countDownTime: '',
    countDownTime2: '',
    timeLeft: '',
    disabled1: false
  };

  async componentDidMount() {
    if (this.props.challenge) {
      logEvent(analytics, 'view_challenge', {
        influencerAtName: this.props.challenge.influencerAtName,
        challengeId: this.props.challenge.challengeId,
        challengeCreatorHandle: this.props.challenge.userHandle,
        currentUserHandle: this.props.user.authenticated ? this.props.user.credentials.handle : 'anon'
      });
    }

    await this.setState({
      clickedChallenge: this.props.clickedChallenge,
      thisChallenge: (this.props.home === 'true' || this.props.spacing === 'none' || this.props.profile === 'true') ? this.props : this.props.data
    }, () => {
      // Now that state is guaranteed to be updated, you can destructure thisChallenge
      const { thisChallenge } = this.state;

      if (this.props.action && this.props.action === 'boost') {
        // Logic for 'boost' action
      } else {
        thisChallenge.challenge && thisChallenge.challenge.influencerId === this.props.user.credentials.userId ?
          this.showAction() : this.showBoost();
      }

      if (thisChallenge.challenge && thisChallenge.challenge.status === 'accepted') {
        this.countDown(new Date(thisChallenge.challenge.acceptedAt), thisChallenge.challenge.completeByDate, 2);
      } else if (thisChallenge.challenge) {
        this.countDown(new Date(thisChallenge.challenge.createdAt), thisChallenge.challenge.challengeEndDate, 1);
      }
    });
  }

  /**
 * Responds to updates in component props and state, performing actions based on the new prop values.
 * This lifecycle method is particularly focused on handling changes related to clicked challenges and their properties.
 */
  async componentDidUpdate(prevProps) {
    // Check if a new challenge has been clicked and if the component should update accordingly
    if (this.state.doIt && this.props.clickedChallenge && this.props.clickedChallenge.challengeId && this.props.clickedChallenge.challengeId !== prevProps.clickedChallId) {
      // Update component state with the new challenge details
      await this.setState({
        clickedChallenge: this.props.clickedChallenge, // Update the clicked challenge
        clickedChallId: this.props.challenge.challengeId, // Store the ID of the clicked challenge for reference
        thisChallenge: this.props, // Update the current challenge details based on props
        doIt: false // Reset the 'doIt' flag to prevent repeated updates
      }, () => {
        // Destructure thisChallenge from the updated state for ease of access
        const { thisChallenge } = this.state;

        // If the action is 'boost', show the boost UI component
        if (this.props.action === 'boost') {
          this.showBoost();
        } else {
          // Clear any existing timers to reset the countdown
          this.clearTimer();

          // Start a new countdown based on the challenge status and dates
          if (thisChallenge.challenge && thisChallenge.challenge.status === 'accepted') {
            // If the challenge is accepted, use the accepted date for the countdown
            this.countDown(new Date(thisChallenge.challenge.acceptedAt), thisChallenge.challenge.completeByDate, 2);
          } else {
            // For other statuses, use the challenge creation date for the countdown
            this.countDown(new Date(thisChallenge.challenge.createdAt), thisChallenge.challenge.challengeEndDate, 1);
          }
        }
      });
    }

    // Check for updates in challenge properties that might require a UI update
    if (this.props.clickedChallenge && prevProps.challenge && this.props.challenge && (this.props.challenge.challengeId !== prevProps.challenge.challengeId || this.props.challenge.status !== prevProps.challenge.status || this.props.challenge.initialCreatorPaymentAmount !== prevProps.challenge.initialCreatorPaymentAmount)) {
      await this.setState({
        clickedChallenge: this.props.clickedChallenge,
        clickedChallId: this.props.challenge.challengeId,
        thisChallenge: this.props
      }, () => {
        // Destructure thisChallenge for ease of access
        const { thisChallenge } = this.state;

        // Clear any existing countdown timers
        this.clearTimer();

        // Start a new countdown based on the updated challenge details
        if (thisChallenge.challenge && thisChallenge.challenge.status === 'accepted') {
          this.countDown(new Date(thisChallenge.challenge.acceptedAt), thisChallenge.challenge.completeByDate, 2);
        } else {
          this.countDown(new Date(thisChallenge.challenge.createdAt), thisChallenge.challenge.challengeEndDate, 1);
        }
      });
    }

    // Check for minor updates in challenge properties like payment amount and like count
    if (prevProps.challenge && this.props.challenge && (this.props.challenge.challengeId === prevProps.challenge.challengeId && (this.props.challenge.initialCreatorPaymentAmount !== prevProps.challenge.initialCreatorPaymentAmount || this.props.challenge.likeCount !== prevProps.challenge.likeCount))) {
      await this.setState({
        clickedChallenge: this.props.clickedChallenge,
        clickedChallId: this.props.challenge.challengeId,
        thisChallenge: this.props
      });
      // No further action is taken here, but the state is updated to ensure the UI reflects the latest challenge details
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { thisChallenge, payAmount, clip } = this.state; // Destructuring for easier access

    if (payAmount) {
      this.setState({ disabled1: true });
      setTimeout(() => {
        this.setState({ disabled1: false });
      }, 5000);

      try {
        const res = await this.props.boostChallenge(
          thisChallenge.challenge.challengeId, // Access using destructured variable
          parseInt(payAmount) // Access using destructured variable
        );
        if (res.message === 'Challenge boosted') {
          toast.success('SUCCESS: Challenge boosted!');
          this.setState(prevState => ({
            thisChallenge: {
              ...prevState.thisChallenge,
              challenge: {
                ...prevState.thisChallenge.challenge,
                initialCreatorPaymentAmount: prevState.thisChallenge.challenge.initialCreatorPaymentAmount + parseInt(payAmount)
              }
            }
          }));
        } else {
          toast.error('Failure: Challenge not boosted!');
          if (res && res.error) {
            toast.error(res.error);
          }
        }
      } catch (err) {
        toast.error('Failure: Challenge not boosted!');
      }

      logEvent(analytics, 'boost_challenge', {
        currentUserHandle: this.props.user.authenticated ? this.props.user.credentials.handle : 'anon'
      });
    }

    if (clip) {
      try {
        const res = await this.props.submitClip(
          thisChallenge.challenge.challengeId, // Access using destructured variable
          clip // Access using destructured variable
        );
        if (res.message === 'Clip submitted') {
          toast.success('SUCCESS: Clip submitted!');
        } else {
          toast.error('Failure: Clip not submitted!');
        }
      } catch (err) {
        toast.error('Failure: Clip not submitted!');
      }

      logEvent(analytics, 'clip_submitted', {
        currentUserHandle: this.props.user.authenticated ? this.props.user.credentials.handle : 'anon'
      });
    }
  };

  doNothing = () => {

  }

  modUser = async (event) => {
    event.preventDefault();
    const { thisChallenge } = this.state;

    try {
      await this.props.mod({ handle: thisChallenge.challenge.userHandle });
      toast.success('SUCCESS: Mod submitted!');
    } catch (err) {
      toast.error('Failed: Mod not submitted.');
    }

    logEvent(analytics, 'post_challenge', {
      currentUserHandle: this.props.user.authenticated ? this.props.user.credentials.handle : 'anon'
    });
  };

  banUser = async (event) => {
    const { thisChallenge } = this.state;
    event.preventDefault();
    try {
      // TODO: Add banLevel here (just level).
      await this.props.ban({ handle: thisChallenge.challenge.userHandle, influencerHandle: this.props.userHandle });
      toast.success('SUCCESS: Ban submitted!');
    } catch (err) {
      toast.error('Failed: Ban not submitted.');
    }
    logEvent(analytics, 'post_challenge', {
      currentUserHandle: this.props.user.authenticated ? this.props.user.credentials.handle : 'anon'
    });
  };

  likeChallenge = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ disabled: true });
    const { thisChallenge } = this.state;

    try {
      const res = await this.props.likeChallenge(thisChallenge.challenge.challengeId);

      if (res.message === 'Challenge liked') {
        // toast.success('SUCCESS: Challenge upvoted!');
        this.setState(prev => ({
          thisChallenge: {
            ...prev.thisChallenge,
            challenge: {
              ...prev.thisChallenge.challenge,
              liked: true,
              likeCount: prev.thisChallenge.challenge.likeCount + 1
            }
          }
        }));
      }
      // else {
      //   toast.error('Failure: Challenge not upvoted!');
      // }
    } catch (err) {
      // toast.error('Failure: Challenge not upvoted!');
    } finally {
      this.setState({ disabled: false });
    }

    this.setState({ open: false });
  };

  unlikeChallenge = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ disabled: true });
    const { thisChallenge } = this.state;

    try {
      const res = await this.props.unlikeChallenge(thisChallenge.challenge.challengeId);
      if (res.message === 'Challenge unliked') {
        //toast.success('SUCCESS: Challenge upvote removed!');
        this.setState(prev => ({
          thisChallenge: {
            ...prev.thisChallenge,
            challenge: {
              ...prev.thisChallenge.challenge,
              liked: false,
              likeCount: prev.thisChallenge.challenge.likeCount - 1
            }
          }
        }));
      }
      // else {
      //   toast.error('Failure: Challenge upvote not removed!');
      // }
    } catch (err) {
      // toast.error('Failure: Challenge upvote not removed!');
    } finally {
      this.setState({ disabled: false });
    }

    this.setState({ open: false });
  };

  reportUser = async (event) => {
    const { thisChallenge } = this.state;
    try {
      const res = await this.props.reportUser({ handle: thisChallenge.challenge.userHandle });
      if (res.message === 'User Reported') {
        toast.success('SUCCESS: User Reported!');
        await this.props.updateChallenge(thisChallenge.challenge.challengeId);
      } else {
        toast.error('Failure: User not Reported!');
      }
    } catch (err) {
      toast.error('Failure: User not Reported!');
    }
    this.setState({ open: false });
  };

  clickCardSearch = async (chall) => {
    if (gettingChall) return;
    gettingChall = true;
    this.setState({ clicked: !this.state.clicked });

    if (!chall.challengeId) {
      chall.challengeId = chall.objectID;
    }

    try {
      await this.props.viewChall(chall);
    } catch (err) {
      console.log("err clicking challenge");
    } finally {
      setTimeout(() => {
        gettingChall = false;
      }, 400);
    }
  };

  showBoost = () => {
    this.setState({
      showBoost: true,
      showClips: false,
      showLink: false,
      showAction: false,
      showMod: false
    })
  }
  showClips = () => {
    this.setState({
      showClips: true,
      showBoost: false,
      showLink: false,
      showAction: false,
      showMod: false
    })
  }
  showLink = () => {
    this.setState({
      showLink: true,
      showBoost: false,
      showClips: false,
      showAction: false,
      showMod: false
    })
  }
  showAction = () => {
    this.setState({
      showAction: true,
      showBoost: false,
      showClips: false,
      showLink: false,
      showMod: false

    })
  }
  showMod = () => {
    this.setState({
      showMod: true,
      showBoost: false,
      showClips: false,
      showLink: false,
      showAction: false,
    })
  }

  clickCard = async (e, id) => {
    let action;

    if (e && e.target.name === 'boost') {
      action = 'boost';
    }

    if (gettingChall) {
      return;
    }

    gettingChall = true;
    this.setState({ clicked: !this.state.clicked });

    try {
      await this.props.activeChall(id, action);
    } catch (err) {
      console.log("err clicking challenge");
    } finally {
      setTimeout(() => {
        gettingChall = false;
      }, 400);
    }
  };

  handleChange = (event) => {
    const { thisChallenge } = this.state;
    if (event.target.name === 'payAmount' && event.target.value < 0) {
      event.target.value = 0
    }
    if (event.target.value && event.target.name === 'clip') {
      event.target.value = event.target.value.trim();
      if (thisChallenge.challenge.influencerAtName !== this.props.user.credentials.handle && (!event.target.value.includes('https://clips.twitch.tv') && !event.target.value.includes('https://www.twitch.tv/videos/'))) {
        this.setState({ invalidLink: 'Must be a twitch clip or video' });
      } else {
        this.setState({ invalidLink: '' });
      }
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  clearTimer = async () => {
    await this.setState({ timerInterval: false });
  }

  /**
   * Calculates the time left from the given date to a future date based on specified increments.
   * The future date is determined by adding a specified time increment (like minutes, hours, or days) to the provided date.
   *
   * @param {Date} countDownDate - The start date from which to calculate the time left.
   * @param {string} nam - A key representing the time increment to be added to the start date.
   * @returns {string} A string representing the time left in a human-readable format (e.g., '2 h ', '15 m ', 'EXPIRED').
   */
  timeLeft = (countDownDate, nam) => {
    let adjustedDate;
    let now = new Date().getTime();
    let distance;
    const { thisChallenge } = this.state;

    // Check if 'custom' case
    if (nam && nam.toLowerCase() === 'custom') {
      // Use the completeByDateAsTimestamp from the state
      const timestamp = thisChallenge.challenge.completeByDateAsTimestamp;
      adjustedDate = new Date(timestamp._seconds * 1000);
      distance = adjustedDate.getTime() - now;
    } else {
      // Standard case: use predefined time increments
      const timeIncrements = {
        'xxxxsmall': { unit: 'seconds', value: 30 },
        'xxxsmall': { unit: 'minutes', value: 1 },
        'xxsmall': { unit: 'minutes', value: 5 },
        'xsmall': { unit: 'minutes', value: 10 },
        'small': { unit: 'minutes', value: 30 },
        'medium': { unit: 'hours', value: 1 },
        'large': { unit: 'hours', value: 6 },
        'xlarge': { unit: 'hours', value: 12 },
        'xxlarge': { unit: 'days', value: 1 },
        'xxxlarge': { unit: 'days', value: 3 },
        'xxxxlarge': { unit: 'days', value: 6 },
        'xxxxxlarge': { unit: 'days', value: 14 },
        'xxxxxxlarge': { unit: 'days', value: 30 },
        'default': { unit: 'days', value: 31 },
      };

      const increment = timeIncrements[nam] || timeIncrements['default'];
      adjustedDate = new Date(countDownDate);
      // Apply the increment to the countDownDate
      switch (increment.unit) {
        case 'seconds':
          adjustedDate.setSeconds(adjustedDate.getSeconds() + increment.value);
          break;
        case 'minutes':
          adjustedDate.setMinutes(adjustedDate.getMinutes() + increment.value);
          break;
        case 'hours':
          adjustedDate.setHours(adjustedDate.getHours() + increment.value);
          break;
        case 'days':
          adjustedDate.setDate(adjustedDate.getDate() + increment.value);
          break;
        default:
          break;
      }
      distance = adjustedDate.getTime() - now;
    }
    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    let finalTime = '';
    if (days > 0) {
      finalTime = `${days} d `;
    } else if (hours > 0) {
      finalTime = `${hours} h `;
    } else if (minutes > 0) {
      finalTime = `${minutes} m `;
    } else if (seconds > 0) {
      finalTime = `${seconds} s `;
    } else if (distance < 0) {
      finalTime = 'EXPIRED';
    } else {
      finalTime = '---';
    }

    // Update state (consider switching this to allow for better state updating?)
    if (!this.state.timeLeft)
      this.setState({
        timeLeft: finalTime
      });

    return finalTime;
  }

  /**
   * Sets up a countdown timer that updates the component's state at regular intervals.
   * The countdown is calculated from the given start date (`countDownDate`) by adding 
   * a specified amount of time based on the `nam` parameter. The interval updates
   * the state with the time left until the countdown expires.
   *
   * @param {Date} countDownDate - The start date for the countdown.
   * @param {string} nam - A key representing the amount of time to add to `countDownDate` 
   *                       for the countdown (e.g., 'xxxxsmall', 'medium').
   * @param {number} type - A numeric type used to determine the format or method 
   *                        of the countdown calculation.
   */
  countDown = (countDownDate, nam, type) => {
    let adjustedDate;
    const { thisChallenge } = this.state;

    // Check if 'custom' case
    if (nam && nam.toLowerCase() === 'custom') {
      // Use the completeByDateAsTimestamp from the state
      const timestamp = thisChallenge.challenge.completeByDateAsTimestamp;
      adjustedDate = new Date(timestamp._seconds * 1000);
    } else {
      // Standard case: use predefined time increments
      const timeIncrements = {
        'xxxxsmall': { unit: 'seconds', value: 30 },
        'xxxsmall': { unit: 'minutes', value: 1 },
        'xxsmall': { unit: 'minutes', value: 5 },
        'xsmall': { unit: 'minutes', value: 10 },
        'small': { unit: 'minutes', value: 30 },
        'medium': { unit: 'hours', value: 1 },
        'large': { unit: 'hours', value: 6 },
        'xlarge': { unit: 'hours', value: 12 },
        'xxlarge': { unit: 'days', value: 1 },
        'xxxlarge': { unit: 'days', value: 3 },
        'xxxxlarge': { unit: 'days', value: 6 },
        'xxxxxlarge': { unit: 'days', value: 14 },
        'xxxxxxlarge': { unit: 'days', value: 30 },
        'default': { unit: 'days', value: 31 },
      };

      const increment = timeIncrements[nam] || timeIncrements['default'];
      adjustedDate = new Date(countDownDate);
      // Apply the increment to the countDownDate
      switch (increment.unit) {
        case 'seconds':
          adjustedDate.setSeconds(adjustedDate.getSeconds() + increment.value);
          break;
        case 'minutes':
          adjustedDate.setMinutes(adjustedDate.getMinutes() + increment.value);
          break;
        case 'hours':
          adjustedDate.setHours(adjustedDate.getHours() + increment.value);
          break;
        case 'days':
          adjustedDate.setDate(adjustedDate.getDate() + increment.value);
          break;
        default:
          console.error("Unrecognized unit for time increment:", increment.unit);
          break;
      }
    }

    // Update countdown at regular intervals
    const updateCountDown = () => {
      var now = new Date().getTime();
      var distance = adjustedDate.getTime() - now;

      // Calculate days, hours, minutes, seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      let countdownKey = type === 2 ? 'countDownTime2' : 'countDownTime';
      let newTime = 'EXPIRED';
      if (days > 0) newTime = days + "d ";
      else if (hours > 0) newTime = hours + "h ";
      else if (minutes > 0) newTime = minutes + "m ";
      else if (seconds > 0) newTime = seconds + "s ";

      this.setState({ [countdownKey]: newTime, timerInterval: distance >= 0 });

      // Clear interval if the countdown has expired
      if (distance < 0) {
        clearInterval(this.countdownTimer);
      }
    }

    // Setting the interval to update the countdown every second
    this.countdownTimer = setInterval(updateCountDown, 1000);
  }

  handleClickOpen = async (e, challenge) => {
    e.stopPropagation();
    e.preventDefault();
    await this.setState({
      modalChallenge: challenge,
      open: true
    });
    challenge && challenge.status === 'accepted' ?
      challenge && challenge.completeByDate &&
      this.countDown(new Date(challenge && challenge.acceptedAt), challenge && challenge.completeByDate, 2)
      :
      challenge &&
      this.countDown(new Date(challenge && challenge.createdAt), challenge && challenge.challengeEndDate, 2)
    if (challenge.clips && challenge.clips[0] && challenge.clips[0].link) {
      let clipUrl = challenge.clips[0].link;
      if (clipUrl && clipUrl.includes('clip')) {
        let index = clipUrl.lastIndexOf('.tv/') + 4;
        let url = clipUrl.slice(0, index) + 'embed?clip=' + clipUrl.substring(clipUrl.lastIndexOf('/') + 1) + '&parent=meed.app';
        this.setState({
          clipVid:
            <div className={this.props.classes.videoWrapModal}>
              <iframe src={url}
                width="100%"
                height="100%"
                muted={true}
              >
              </iframe>
            </div>
        })
      } else
        new this.state.Twitch.Embed('modal' + challenge.challengeId, {
          width: '100%',
          height: '100%',
          video: challenge.clips[0].link.substring(challenge.clips[0].link.lastIndexOf('/') + 1),
          layout: 'video',
          muted: true,
          // only needed if your site is also embedded on embed.example.com and othersite.example.com 
          parent: ["localhost", "meed.app", "covenant-28c21.web.app", "covenant-28c21.firebaseapp.com"]
        });
    }
  };

  handleClose = (e) => {
    e.stopPropagation()
    this.setState({ open: false });
  };

  /**
   * Determines if the currently authenticated user is a moderator with at least
   * the required moderator level for a given influencer. This method is intended
   * to be used within a React component and relies on the component's props.
   * The method is case-insensitive for moderator levels.
   *
   * @param {string} requiredLevel - The required moderator level (e.g., 'full', 'Intermediate').
   * @returns {boolean} True if the user is a moderator with at least the specified level
   *                    for the influencer associated with the current challenge. False otherwise.
   */
  isUserModWithLevel(requiredLevel) {
    const { user } = this.props;
    const influencerId = this.state.thisChallenge.challenge?.influencerId;

    if (!influencerId) {
      return false;
    }

    const userModLevel = getModeratorLevel(user, influencerId).toLowerCase();
    const requiredModLevel = requiredLevel.toLowerCase();
    return modLevelValues[userModLevel] >= modLevelValues[requiredModLevel];
  }

  render() {
    dayjs.extend(relativeTime);
    const {
      classes,
      user: {
        authenticated,
      }
    } = this.props;
    const { thisChallenge, modalChallenge, clickedChallenge, showLink, showBoost, showClips, showMod, showAction, invalidLink, payAmount } = this.state;

    const reportChallenge =
      this.props.clickedChallenge ?
        <ReportChallenge updateChallenge={this.props.updateChallenge} className={classes.follow} challengeId={this.props.challengeId} /> :
        null;
    // The first part of this code determines when the button is visible, so it'll show if the condition evaluates to true -- otherwise it's set to null.
    const acceptButton =
      authenticated && clickedChallenge && (thisChallenge.challenge?.influencerId === this.props.user.credentials.userId ||
        this.isUserModWithLevel('full')) && thisChallenge.challenge.status === 'created' ? (
        <AcceptChallenge updateChallenge={this.props.updateChallenge} className={classes.follow} challengeId={thisChallenge.challenge?.challengeId} />
      ) : null;

    const declineButton =
      authenticated && clickedChallenge && (thisChallenge.challenge?.influencerId === this.props.user.credentials.userId ||
        this.isUserModWithLevel('full')) && thisChallenge.challenge?.status === 'created' ? (
        <DeclineChallenge updateChallenge={this.props.updateChallenge} className={classes.follow} challengeId={thisChallenge.challenge?.challengeId} />
      ) : null;

    const completeButton =
      authenticated && clickedChallenge && (thisChallenge.challenge?.influencerId === this.props.user.credentials.userId ||
        this.isUserModWithLevel('full')) && thisChallenge.challenge?.status === 'accepted' ? (
        <CompleteChallenge updateChallenge={this.props.updateChallenge} className={classes.follow} challengeId={thisChallenge.challenge?.challengeId} votingType={thisChallenge.challenge.votingType} />
      ) : null;

    const othersDecideButton =
      authenticated && clickedChallenge && (thisChallenge.challenge?.influencerId === this.props.user.credentials.userId ||
        this.isUserModWithLevel('full')) && thisChallenge.challenge?.status === 'accepted' ? (
        <OthersDecideChallenge updateChallenge={this.props.updateChallenge} className={classes.follow} challengeId={thisChallenge.challenge?.challengeId} votingType={thisChallenge.challenge.votingType} />
      ) : null;

    const failButton =
      authenticated && clickedChallenge && (thisChallenge.challenge?.influencerId === this.props.user.credentials.userId ||
        this.isUserModWithLevel('full')) && thisChallenge.challenge?.status === 'accepted' ? (
        <FailChallenge updateChallenge={this.props.updateChallenge} className={classes.follow} challengeId={thisChallenge.challenge?.challengeId} votingType={thisChallenge.challenge.votingType} />
      ) : null;

    const voteButton =
      authenticated && clickedChallenge && thisChallenge.challenge?.status && ['voting', 'completed', 'failed'].includes(thisChallenge.challenge.status) ? (
        <VoteButton
          className={classes.voteButton}
          challengeId={thisChallenge.challenge.challengeId}
          user={this.props.user}
          castVote={this.props.castVote}
          removeVote={this.props.removeVote}
          votingType={thisChallenge.challenge.votingType}
        />
      ) : null;

    const reportChallengeModal =
      modalChallenge ?
        <ReportChallenge className={classes.follow} challengeId={this.props.challengeId} /> :
        null;
    const acceptButtonModal =
      authenticated && (thisChallenge.challenge?.influencerId === this.props.user.credentials.userId ||
        this.isUserModWithLevel('full')) && modalChallenge.status === 'created' ? (
        <AcceptChallenge className={classes.follow} challengeId={modalChallenge && modalChallenge.challengeId} />
      ) : null;

    const declineButtonModal =
      authenticated && (thisChallenge.challenge?.influencerId === this.props.user.credentials.userId ||
        this.isUserModWithLevel('full')) && modalChallenge?.status === 'created' ? (
        <DeclineChallenge className={classes.follow} challengeId={modalChallenge?.challengeId} />
      ) : null;

    const completeButtonModal =
      authenticated && (thisChallenge.challenge?.influencerId === this.props.user.credentials.userId ||
        this.isUserModWithLevel('full')) && modalChallenge?.status === 'accepted' ? (
        <CompleteChallenge className={classes.follow} challengeId={modalChallenge?.challengeId} votingType={thisChallenge.challenge?.votingType} />
      ) : null;

    const othersDecideButtonModal =
      authenticated && (thisChallenge.challenge?.influencerId === this.props.user.credentials.userId ||
        this.isUserModWithLevel('full')) && modalChallenge?.status === 'accepted' ? (
        <OthersDecideChallenge className={classes.follow} challengeId={modalChallenge?.challengeId} votingType={thisChallenge.challenge?.votingType} />
      ) : null;

    const failButtonModal =
      authenticated && (thisChallenge.challenge?.influencerId === this.props.user.credentials.userId ||
        this.isUserModWithLevel('full')) && modalChallenge?.status === 'accepted' ? (
        <FailChallenge className={classes.follow} challengeId={modalChallenge?.challengeId} votingType={thisChallenge.challenge?.votingType} />
      ) : null;

    // Still need to adjust class styling
    const voteButtonModal =
      authenticated && thisChallenge.challenge && ['voting', 'completed', 'failed'].includes(thisChallenge.challenge.status) ? (
        <VoteButton
          className={classes.voteButton}
          challenge={thisChallenge.challenge}
          challengeId={thisChallenge.challenge.challengeId}
          user={this.props.user}
          castVote={this.props.castVote}
          removeVote={this.props.removeVote}
          votingType={thisChallenge.challenge.votingType}
        />
      ) : null;

    const noActionModal = (acceptButtonModal || declineButtonModal || completeButtonModal || failButtonModal || reportChallengeModal) ?
      null :
      <Typography variant="body1" className={classes.pageText}>
        There are currently no actions to take on this challenge.
      </Typography>;
    const displayPledgeAmount = thisChallenge.challenge && thisChallenge.challenge.initialCreatorPaymentAmount;
    {/****************************** Challenge Queue Card Begins ******************************/ }
    return (
      <Fragment> {
        this.props.spacing === 'none' ? (
          <Card className={`${classes[thisChallenge.challenge?.status]} ${this.props.donate ? classes.donate : ''}  ${classes.noSpacingCard} ${this.props.oneMargin ? classes.oneMargin : ''} ${this.props.clickedChallenge ? classes.clickedChallenge : ''}  ${this.props.clickedChallId && thisChallenge.challenge && thisChallenge.challenge.challengeId && this.props.clickedChallId === thisChallenge.challenge.challengeId ? classes.clicked : ''} ${this.props.searching ? classes.transparent : ''}`} onClick={this.props.searching ? () => this.clickCardSearch(thisChallenge.challenge) : (this.props.home || this.props.profile) ? (e) => this.handleClickOpen(e, thisChallenge.challenge) : (e) => this.clickCard(e, thisChallenge.challenge.challengeId)} >
            {this.props.home ?
              <div className={classes.purpleCircle}>
                <img
                  src={mascot}
                  alt="profile"
                  className={classes.profileImage}
                  onClick={this.menuClick}
                />
              </div> :
              <div></div>
            }
            <CardContent className={classes.content}>
              <Typography className={`${thisChallenge.challenge?.status === 'completed' ? classes.completedStateTitle : classes.hideOverflow}`} variant="body1">{thisChallenge.challenge && htmlDecode(thisChallenge.challenge.challengeName).length < 30 ? htmlDecode(thisChallenge.challenge.challengeName) : thisChallenge.challenge && '' + (htmlDecode(thisChallenge.challenge.challengeName)).substring(0, 30).trim() + '...'}</Typography>
              <Typography
                className={`${thisChallenge.challenge?.status === 'completed' ? classes.completedState : classes.verifiedUser}`}
                variant="body2"
              >
                {thisChallenge.challenge && thisChallenge.challenge.userHandle}
                {/* <img className={classes.verified} src={logo}/> */}
              </Typography>
              <Typography
                className={`${thisChallenge.challenge?.status === 'completed' ? classes.completedState : classes.verifiedUser} ${classes.challengeEngagement}`}
                variant="body2"
              >
                <Tooltip title='Boost Challenge' placement="top">
                  <span>
                    <img
                      name='boost'
                      className={classes.verified}
                      src={`${this.props.clickedChallId &&
                        thisChallenge.challenge &&
                        thisChallenge.challenge.challengeId &&
                        this.props.clickedChallId === thisChallenge.challenge.challengeId ?
                        grayMoney :
                        money
                        }`}
                      alt="Pledge amount indicator for Boost Challenge" // Alt text added here
                    />
                    {` ${displayPledgeAmount} ${thisChallenge.challenge &&
                      thisChallenge.challenge.goal === 'Yes' ?
                      `/ ${thisChallenge.challenge.goalAmount}` :
                      ``
                      }`}
                  </span>
                </Tooltip>
                <Tooltip title={thisChallenge.challenge?.liked ? 'Unlike' : 'Like'} placement="top"><span className={classes.heart} onClick={thisChallenge.challenge?.liked ? this.unlikeChallenge : this.likeChallenge}><img className={`${classes.verified} ${thisChallenge.challenge?.liked ? '' : classes.heartLight}`} src={thisChallenge.challenge?.challengeId && this.props.clickedChallId === thisChallenge.challenge.challengeId ? grayHeart : heart2} /> {thisChallenge.challenge?.likeCount ? thisChallenge.challenge.likeCount : 0}</span></Tooltip>
                <Tooltip title={thisChallenge.challenge?.status === 'accepted' ? 'Time left to complete the challenge' : thisChallenge.challenge?.status === 'created' ? 'Time left to accept the challenge' : thisChallenge.challenge?.status} placement="top"><span><img className={classes.verified} src={thisChallenge.challenge && thisChallenge.challenge.challengeId && this.props.clickedChallId === thisChallenge.challenge.challengeId ? grayTime : time} /> {
                  thisChallenge.challenge?.completeByDate &&
                    (thisChallenge.challenge.status === 'accepted' || thisChallenge.challenge.status === "created") ?
                    this.timeLeft(new Date(thisChallenge.challenge.createdAt), thisChallenge.challenge.completeByDate)
                    :
                    thisChallenge.challenge?.status
                }
                </span></Tooltip>
                {/* <img className={classes.verified} src={logo}/> */}
              </Typography>
              {/* { deleteButton } */}
              {/* <LikeButton className={classes.likeButton} challengeId={challengeId} />
          <span className={classes.likeCount}>{likeCount}</span> */}
            </CardContent>
            <div className={classes.actionButtons2}>
              {reportChallenge}
              <div>
                {declineButton}
                {acceptButton}
                {failButton}
                {completeButton}
                {othersDecideButton}
                {voteButton}
              </div>
            </div>
          </Card>
        ) : (
          // later refactor use variable for each card and have a variable for the dialog
          <Card
            className={`
          ${this.props.home || this.props.profile || this.props.donate ? classes.minWide : ''} 
          ${this.props.home ? classes.home : ''} 
          ${this.props.donate ? classes.minWide : ''} 
          ${classes[thisChallenge.challenge?.status]} 
          ${classes.card} 
          ${this.props.clickedChallenge ? classes.clickedChallenge : ''}
        `}
            component={Link}
            to={this.props.home || this.props.profile ? '#' : !this.props.clickedChallenge
              ? `users/${thisChallenge.challenge
              && thisChallenge.challenge.influencerAtName}/${thisChallenge.challenge
              && thisChallenge.challenge.challengeId}`
              : '#'
            }
            onClick={this.props.home || this.props.profile ? (e) => this.handleClickOpen(e, thisChallenge.challenge) : this.doNothing}>


            {gettingChall ?
              <CircularProgress size={30} className={classes.progress} /> :
              this.props.clickedChallenge ?
                <div className={`${classes.expandedView} ${showClips ? classes.modalRight2 : ''}`}>
                  {thisChallenge.challenge?.clips && showClips ?
                    <div className={classes.clipList}>
                      <Typography className={`${classes.primPadding} ${classes.clipHead}`} variant="body1" onClick={this.showBoost}>Return to Menu</Typography>
                      {
                        thisChallenge.challenge?.clips.map((clip) =>
                          <div className={classes.challengeClip} key={clip.createdAt}>
                            <img className={classes.berryThumb} src={berry}></img>
                            <div>
                              <Typography
                                className={`${classes.primPadding} ${classes.clipTitle}`}
                                variant="body2"
                              >
                                <a href={`/users/${clip.submitter}`}>Video submitted by {`${clip.submitter}`}</a>
                              </Typography>
                              <Typography
                                className={`${classes.primPadding} ${classes.clipDesc}`}
                                variant="body2"
                              >
                                <a target='_blank' rel='noopener noreferrer' href={clip.link}>{clip.link}</a>
                              </Typography>
                              <img src={newTab} className={classes.newTab}></img>
                            </div>
                          </div>
                        )
                      }
                    </div>
                    :
                    <CardContent className={`${classes.content} ${classes.modalContent} ${classes.conty} ${classes.noPadding} ${classes.noMargin}`}>
                      {/* this is what you see on the expanded card view - host page */}
                      <Typography className={`${classes.primPadding} ${classes.tit}`} variant="body1">{thisChallenge.challenge && htmlDecode(thisChallenge.challenge.challengeName)}</Typography>
                      <Typography className={`${classes.primPadding} `} component={Link} to={`/users/${thisChallenge.challenge && thisChallenge.challenge.userId ? thisChallenge.challenge.userHandle : thisChallenge.challenge && thisChallenge.challenge.influencerAtName}`}>
                        {thisChallenge.challenge && thisChallenge.challenge.userHandle}
                      </Typography>
                      <Typography className={`${classes.primPadding} ${classes.desc}`} variant="body1">{thisChallenge.challenge && htmlDecode(thisChallenge.challenge.description)}</Typography>
                      <br></br>
                      <Typography
                        className={`${classes.verifiedUser} ${classes.primPadding} ${classes.gap}`}
                        variant="body2"
                      >
                        <span>Reward</span> <span className={classes.modalMeta}><img className={classes.verified} src={money} /> {`${displayPledgeAmount}`}</span>
                        {/* <img className={classes.verified} src={logo}/> */}
                      </Typography>
                      {thisChallenge.challenge && thisChallenge.challenge.goal === 'Yes' ?
                        <Typography
                          className={`${classes.verifiedUser} ${classes.primPadding} ${classes.gap}`}
                          variant="body2"
                        >
                          <span>Goal</span> <span className={classes.modalMeta}><img className={classes.verified} src={money} /> {`${thisChallenge.challenge && thisChallenge.challenge.goalAmount}`}</span>
                          {/* <img className={classes.verified} src={logo}/> */}
                        </Typography>
                        : <div></div>
                      }
                      <Typography component={Link} to={`/users/${thisChallenge.challenge?.influencerAtName}`}
                        className={`${classes.verifiedUser} ${classes.primPadding} ${classes.gap}`}
                        variant="body2"
                      >
                        <span>Upvotes</span> <span className={classes.modalMeta}><img className={classes.verified} src={heart2} /> {thisChallenge.challenge?.likeCount ? thisChallenge.challenge.likeCount : 0}</span>
                        {/* <img className={classes.verified} src={logo}/> */}
                      </Typography>
                      <Typography component={Link} to={`/users/${thisChallenge.challenge?.influencerAtName}`}
                        className={`${classes.verifiedUser} ${classes.primPadding} ${classes.gap}`}
                        variant="body2"
                      >
                        <Tooltip title={
                          thisChallenge.challenge?.status === 'accepted' ?
                            'Time left to complete the challenge' :
                            `This challenge was ${thisChallenge.challenge?.status}`
                        }
                          placement="top">
                          <span>{(thisChallenge.challenge?.status === 'accepted') ?
                            'Time Left to Complete' :
                            thisChallenge.challenge?.status === 'created' ?
                              'Time to Complete' :
                              `Challenge Status`}</span>
                        </Tooltip>
                        <Tooltip title={
                          thisChallenge.challenge?.status === 'accepted' ?
                            this.state.countDownTime2 :
                            thisChallenge.challenge?.status === 'created' ?
                              thisChallenge.challenge && getTimeSize(thisChallenge.challenge.completeByDate) :
                              `This challenge was  ${thisChallenge.challenge?.status}`
                        }
                          placement="top">
                          <span className={classes.modalMeta}>
                            <img className={classes.verified} src={time} />
                            {
                              thisChallenge.challenge?.status === 'accepted' ?
                                this.state.countDownTime2 :
                                thisChallenge.challenge?.status === 'created' ?
                                  getTimeSize(thisChallenge.challenge.completeByDate) :
                                  thisChallenge.challenge?.status
                            }
                          </span>
                        </Tooltip>
                        {/* <img className={classes.verified} src={logo}/> */}
                      </Typography>
                      <div className={classes.challengeTools}>
                        <img className={`${classes.verified} ${showBoost ? classes.challengeToolsActive : ''}`} src={grayMoney} onClick={this.showBoost} />
                        <img className={`${classes.verified} ${showClips ? classes.challengeToolsActive : ''}`} src={grayVid} onClick={this.showClips} />
                        <img className={`${classes.verified} ${showLink ? classes.challengeToolsActive : ''}`} src={grayLink} onClick={this.showLink} />
                        <img className={`${classes.verified} ${showAction ? classes.challengeToolsActive : ''}`} src={grayBang} onClick={this.showAction} />
                        <img className={`${classes.verified} ${showMod ? classes.challengeToolsActive : ''}`} src={grayDots} onClick={this.showMod} />
                      </div>
                      {
                        thisChallenge.challenge && (thisChallenge.challenge.influencerAtName !== this.props.user.credentials.handle
                          && thisChallenge.challenge.userHandle !== this.props.user.credentials.handle)
                          && (thisChallenge.challenge.status !== 'created' && thisChallenge.challenge.status !== 'accepted')
                          ?
                          (thisChallenge.challenge && showLink
                            ?
                            <div className={classes.linkChall}>
                              <div className={classes.navShare} onClick={() => {
                                window.navigator.share({
                                  title: 'Meed',
                                  text: 'Check out this challenge!',
                                  url: `https://meed.app/users/${thisChallenge.challenge.influencerAtName}/challenge/${thisChallenge.challenge.challengeId}`
                                })
                              }}  // className={`${classes.gap}`}>
                              >
                                <Typography variant="body1" className={classes.pageText}>
                                  Share this challenge:
                                </Typography>
                                <Typography variant="body1" className={classes.link}>
                                  {`https://meed.app/users/${thisChallenge.challenge.influencerAtName}/challenge/${thisChallenge.challenge.challengeId}`}
                                </Typography>
                              </div>
                              <div className={`${classes.gap}`}>
                                <Typography variant="body1" className={classes.pageText}>
                                  Add Video Clip Hyperlink<br></br> (All links subject to approval)
                                </Typography>
                                <TextField
                                  variant="standard"
                                  className={`${classes.endDate} ${classes.whiteBg}`}
                                  placeholder="https://clips.twitch.tv/ModernAuspiciousAlbatrossBigBrother-ylivODItb1Wxhhja"
                                  name='clip'
                                  InputProps={{ disableUnderline: true }}
                                  helperText={invalidLink}
                                  error={invalidLink ? true : false}
                                  onChange={this.handleChange}
                                  type="text" />
                              </div>
                              <Button
                                type="submit"
                                variant="contained"
                                className={classes.submitButton}
                                disabled={this.state.disabled1 || ((!payAmount || payAmount <= 0) && (!this.state.clip || invalidLink))}
                                onClick={this.handleSubmit}
                              >
                                {
                                  `Submit `
                                }
                                {this.state.disabled1 ? <CircularProgress size={30} className={classes.progress} /> : ''}
                              </Button>
                            </div>
                            : <div></div>)

                          :
                          (thisChallenge.challenge && (showLink || showBoost || showClips)) ?
                            <form onSubmit={this.handleSubmit}>
                              {thisChallenge.challenge && showBoost && (thisChallenge.challenge.status === 'created'
                                || thisChallenge.challenge.status === 'accepted' || modalChallenge.status === 'voting') ?
                                <div className={`${classes.gap}`}>
                                  <Typography variant="body1" className={classes.pageText}>
                                    Add to Reward
                                  </Typography>
                                  <TextField
                                    variant="standard"
                                    className={`${classes.endDate} ${classes.whiteBg}`}
                                    InputProps={{ disableUnderline: true }}
                                    placeholder="$100"
                                    name='payAmount'
                                    onChange={this.handleChange}
                                    onKeyDown={(event) => {
                                      // Prevent 'E' from being entered
                                      if (event.keyCode === 69) {
                                        event.preventDefault();
                                      }
                                    }}
                                    type="number" />
                                </div>
                                :
                                showBoost && !(thisChallenge.challenge.status === 'created'
                                  || thisChallenge.challenge.status === 'accepted' || thisChallenge.challenge.status === 'voting') ?
                                  <div className={`${classes.gap}`}>
                                    <Typography variant="body1" className={classes.pageText}>
                                      You can only Boost an Open or Active Challenge
                                    </Typography>
                                  </div>
                                  : <div></div>
                              }
                              {thisChallenge.challenge && showLink
                                ?
                                <div className={classes.linkChall}>
                                  <div className={`${classes.gap} ${classes.navShare}`} onClick={() => {
                                    window.navigator.share({
                                      title: 'Meed',
                                      text: 'Check out this challenge!',
                                      url: `https://meed.app/users/${thisChallenge.challenge.influencerAtName}/challenge/${thisChallenge.challenge.challengeId}`
                                    })
                                  }}>
                                    <Typography variant="body1" className={classes.pageText}>
                                      Share this challenge:
                                    </Typography>
                                    <Typography variant="body1" className={classes.link}>
                                      {`https://meed.app/users/${thisChallenge.challenge.influencerAtName}/challenge/${thisChallenge.challenge.challengeId}`}
                                    </Typography>
                                  </div>
                                  <div className={`${classes.gap}`}>
                                    <Typography variant="body1" className={classes.pageText}>
                                      Add Video Clip Hyperlink<br></br> (All links subject to approval)
                                    </Typography>
                                    <TextField
                                      variant="standard"
                                      className={`${classes.endDate} ${classes.whiteBg}`}
                                      placeholder="https://clips.twitch.tv/ModernAuspiciousAlbatrossBigBrother-ylivODItb1Wxhhja"
                                      name='clip'
                                      InputProps={{ disableUnderline: true }}
                                      helperText={invalidLink}
                                      error={invalidLink ? true : false}
                                      onChange={this.handleChange}
                                      type="text" />
                                  </div>
                                </div>
                                : <div></div>
                              }
                              {thisChallenge.challenge && !thisChallenge.challenge.clips && showClips
                                ?
                                <div className={`${classes.gap}`}>
                                  <Typography variant="body1" className={`${classes.pageText} ${classes.pointer}`} onClick={this.showLink}>
                                    There are currently no clips, click here to add one!
                                  </Typography>
                                </div>
                                : <div></div>
                              }
                              <br></br>
                              {!authenticated ?
                                <Button
                                  variant="contained"
                                  className={classes.submitButton}
                                  component={Link} to={`/signup`}
                                >
                                  Sign in to Participate
                                </Button>
                                :
                                (showLink || (showBoost && (thisChallenge.challenge.status === 'created'
                                  || thisChallenge.challenge.status === 'accepted' || thisChallenge.challenge.status === 'voting'))) ?
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    className={classes.submitButton}
                                    disabled={
                                      this.state.disabled1 ||
                                      (
                                        (!payAmount || payAmount <= 0) &&
                                        (!this.state.clip || invalidLink)
                                      )
                                    }
                                    onClick={this.handleSubmit}
                                  >
                                    {
                                      `Submit `
                                    }
                                    {this.state.disabled1 ? <CircularProgress size={30} className={classes.progress} /> : ''}
                                  </Button>
                                  : <div></div>
                              }
                            </form>
                            : <div></div>
                      }
                      {/* { deleteButton } */}
                      {/* <LikeButton className={classes.likeButton} challengeId={challengeId} />
                <span className={classes.likeCount}>{likeCount}</span> */}
                    </CardContent>
                  }
                  <div className={classes.actionButtons3}>
                    {thisChallenge.challenge && showMod ?
                      <div>
                        <Typography component={Link} to={`/users/${thisChallenge.challenge && thisChallenge.challenge.userHandle ? thisChallenge.challenge.userHandle : thisChallenge.challenge && thisChallenge.challenge.influencerAtName}`}>
                          Go to {thisChallenge.challenge.userHandle}'s Profile Page
                        </Typography>
                        {
                          thisChallenge.challenge?.influencerId === this.props.user.credentials.userId
                            ?
                            <div>
                              <Typography onClick={this.modUser}>
                                Make Moderator
                              </Typography>
                              <Typography onClick={this.banUser}>
                                Ban
                              </Typography>
                            </div>
                            :
                            <div></div>
                        }
                        <Typography onClick={this.reportUser}>
                          Report User
                        </Typography>
                      </div>
                      : <div></div>
                    }
                    {
                      thisChallenge.challenge && !showAction ?
                        <div></div> :
                        <div className={classes.buttonActions}>
                          {declineButton}
                          {acceptButton}
                          {failButton}
                          {completeButton}
                          {othersDecideButton}
                          <br></br>
                          {reportChallenge}
                        </div>
                    }
                  </div>
                </div>
                :
                <div className={classes.cardWrap}>

                  <CardContent className={classes.content}>
                    <div className={classes.userMetaCard}>
                      {
                        thisChallenge.challenge?.userImage ?
                          <img
                            src={thisChallenge.challenge && this.props.home && thisChallenge.challenge.creatorImage ? thisChallenge.challenge.creatorImage : mascot}
                            alt="profile"
                            className={classes.profileImage}
                          />
                          :
                          (this.props.home && this.props.spacing !== 'none' ?
                            <div className={classes.purpleCircleHome}>
                              <img
                                src={mascot}
                                alt="profile"
                                className={classes.profileImage}
                                onClick={this.menuClick}
                              />
                            </div> :
                            <div></div>
                          )
                      }
                      <div>
                        <Typography className={`${thisChallenge.challenge?.status === 'completed' ? classes.completedStateTitle : classes.hideOverflow}`} variant="body1">{thisChallenge.challenge && htmlDecode(thisChallenge.challenge.challengeName).length < 30 ? htmlDecode(thisChallenge.challenge.challengeName) : thisChallenge.challenge && '' + (htmlDecode(thisChallenge.challenge.challengeName)).substring(0, 30).trim() + '...'}</Typography>
                        <Typography
                          className={`${thisChallenge.challenge?.status === 'completed' ? classes.completedState : classes.verifiedUser}`}
                          variant="body2"
                          component={Link}
                          to={`/users/${thisChallenge.challenge && this.props.home ? thisChallenge.challenge?.userId && thisChallenge.challenge?.userHandle : thisChallenge.challenge?.influencerAtName}`}
                        >
                          {
                            (
                              thisChallenge.challenge &&
                              (
                                (this.props.profile && this.props.requested) || this.props.home
                              )
                            )
                              ? (thisChallenge.challenge && thisChallenge.challenge.userHandle)
                              : (thisChallenge.challenge && thisChallenge.challenge.influencerAtName)
                          }
                          {/* <img className={classes.verified} src={logo}/> */}
                        </Typography>
                      </div>
                    </div>
                    {/* This is what you should see when there is no a basic card on homepage and elsewhere */}
                    <Typography
                      className={`${thisChallenge.challenge?.status === 'completed' ? classes.completedState : classes.verifiedUser} ${classes.challengeEngagement}`}
                      variant="body2"
                    >
                      <Tooltip title='Reward for challenge completion' placement="top"><span><img className={classes.verified} src={`${this.props.clickedChallId && thisChallenge.challenge && thisChallenge.challenge.challengeId && this.props.clickedChallId === thisChallenge.challenge.challengeId ? grayMoney : money}`} /> {` ${displayPledgeAmount} ${thisChallenge.challenge && thisChallenge.challenge.goal === 'Yes' ? `/ ${thisChallenge.challenge.goalAmount}` : ``}`}</span></Tooltip>
                      <Tooltip title={thisChallenge.challenge?.liked ? 'Unlike' : 'Like'} placement="top"><span className={classes.heart} onClick={thisChallenge.challenge?.liked ? this.unlikeChallenge : this.likeChallenge}><img className={`${classes.verified} ${thisChallenge.challenge?.liked ? '' : classes.heartLight}`} src={thisChallenge.challenge?.challengeId && this.props.clickedChallId === thisChallenge.challenge.challengeId ? grayHeart : heart2} /> {thisChallenge.challenge?.likeCount ? thisChallenge.challenge.likeCount : 0}</span></Tooltip>
                      <Tooltip title={
                        thisChallenge.challenge?.status === 'accepted' ?
                          'Time left to complete the challenge' :
                          thisChallenge.challenge?.status === 'created' ?
                            'Time left to accept the challenge' :
                            `This challenge was  ${thisChallenge.challenge?.status}`
                      }
                        placement="top">
                        <span className={classes.status}><img className={classes.verified} src={thisChallenge.challenge?.challengeId && this.props.clickedChallId === thisChallenge.challenge.challengeId ? grayTime : time} /> {
                          thisChallenge.challenge?.completeByDate &&
                            (thisChallenge.challenge?.status === 'accepted' || thisChallenge.challenge?.status === "created") ?
                            this.timeLeft(new Date(thisChallenge.challenge.createdAt), thisChallenge.challenge?.completeByDate)
                            :
                            thisChallenge.challenge?.status

                        }
                        </span>
                      </Tooltip>
                      {/* <img className={classes.verified} src={logo}/> */}
                    </Typography>
                    {/* { deleteButton } */}
                    {/* <LikeButton className={classes.likeButton} challengeId={challengeId} />
            <span className={classes.likeCount}>{likeCount}</span> */}
                  </CardContent>
                </div>
            }
          </Card>
        )
      }
        {/****************************** Challenge Modal Begins ******************************/}
        <Dialog
          PaperProps={{ classes: { root: classes.dialogPaper } }}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.dialog}
        >
          <div className={classes.modalWrap}>
            <Button className={classes.logo} onClick={this.handleClose} autoFocus>
              <img
                src={close}
                alt="Close"
              />
            </Button>
            <div className={classes.modalJoint}>
              <div className={classes.modalLeft}>
                {modalChallenge.clips ?
                  this.state.clipVid ?
                    this.state.clipVid :
                    <div id={'modal' + modalChallenge.challengeId} className={classes.twitchVideo}></div> :
                  <ProfileSkeleton home={true} />
                }
                <div className={classes.modalCard}>
                  <div className={classes.modaltoUser}>
                    {
                      modalChallenge?.influencerImage ?
                        <img
                          src={modalChallenge?.influencerImage}
                          alt="profile"
                          className={classes.profileImage}
                        />
                        :
                        ((this.props.home || this.props.profile) && this.props.spacing !== 'none' ?
                          <img
                            src={mascot}
                            alt="profile"
                            className={classes.profileImage}
                            onClick={this.menuClick}
                          /> :
                          <div></div>
                        )
                    }
                    <Typography component={Link} to={`/users/${modalChallenge?.influencerAtName}`}
                      className={`${classes.linkHover} ${modalChallenge?.status === 'completed' ? classes.completedState : classes.verifiedUser}`}
                      variant="body2"
                    >
                      {modalChallenge?.influencerAtName}
                      {/* <img className={classes.verified} src={logo}/> */}
                    </Typography>
                  </div>
                  <CardContent className={`${classes.content} ${classes.modalChall}`}>
                    <div>
                      {
                        modalChallenge?.userImage ?
                          <img
                            src={modalChallenge?.userImage}
                            alt="profile"
                            className={classes.profileImage}
                          />
                          :
                          (this.props.home || this.props.profile && this.props.spacing !== 'none' ?

                            <img
                              src={mascot}
                              alt="profile"
                              className={classes.profileImage}
                              onClick={this.menuClick}
                            /> :
                            <div></div>
                          )
                      }
                    </div>
                    <div>
                      {/* This is what you should see when there is no a basic card on homepage and elsewhere */}
                      <Typography className={`${modalChallenge?.status === 'completed' ? classes.completedStateTitle : classes.hideOverflow}`} variant="body1">{modalChallenge && htmlDecode(modalChallenge.challengeName)}</Typography>
                      <Typography component={Link} to={`/users/${modalChallenge?.userId ? modalChallenge.userHandle : modalChallenge?.influencerAtName}`}
                        className={`${classes.linkHover} ${modalChallenge?.status === 'completed' ? classes.completedState : classes.verifiedUser}`}
                        variant="body2"
                      >
                        {modalChallenge?.userHandle}
                        {/* <img className={classes.verified} src={logo}/> */}
                      </Typography>
                      <Typography className={`${classes.verifiedUser} ${classes.primPadding} ${classes.desc} ${classes.modalDesc}`} variant="body1">{modalChallenge && htmlDecode(modalChallenge.description)}</Typography>
                      {/* { deleteButton } */}
                      {/* <LikeButton className={classes.likeButton} challengeId={challengeId} />
                <span className={classes.likeCount}>{likeCount}</span> */}
                    </div>
                  </CardContent>
                </div>
              </div>
              <div className={classes.rightSide}>
                <div className={`${classes.modalRight} ${showClips ? classes.modalRight2 : ''}`}>
                  {modalChallenge?.clips && showClips ?
                    <div className={classes.clipList}>
                      <Typography className={`${classes.verifiedUser} ${classes.primPadding} ${classes.clipHead}`} variant="body1" onClick={this.showBoost}>Return to Menu</Typography>
                      {
                        modalChallenge && modalChallenge.clips.map((clip) =>
                          <div className={classes.challengeClip} key={clip.createdAt}>
                            <img className={classes.berryThumb} src={berry}></img>
                            <div>
                              <Typography
                                className={`${classes.verifiedUser} ${classes.primPadding} ${classes.clipTitle}`}
                                variant="body2"
                              >
                                <a href={`/users/${clip.submitter}`}>Video submitted by {`${clip.submitter}`}</a>
                              </Typography>
                              <Typography className={`${classes.verifiedUser} ${classes.primPadding} ${classes.clipDesc}`} variant="body2">
                                <a target='_blank' rel='noopener noreferrer' href={clip.link}>{clip.link}</a>
                              </Typography>
                              <img src={newTab} className={classes.newTab}></img>
                            </div>
                          </div>
                        )
                      }
                    </div>
                    :
                    <CardContent className={`${classes.content} ${classes.modalContent} ${classes.conty} ${classes.noPadding} ${classes.noMargin}`}>
                      {/* this is what you see on the expanded card view - host page */}
                      <hr className={classes.goldLine}></hr>
                      <div className={classes.versus}>
                        <span className={classes.versus1}>{modalChallenge?.userHandle}</span>
                        <span><img className={classes.verified} src={swords} /></span>
                        <span className={classes.versus2}>{modalChallenge?.influencerAtName}</span>
                      </div>
                      <hr className={classes.goldLine}></hr>
                      <Typography
                        className={`${classes.verifiedUser} ${classes.primPadding} ${classes.gap}`}
                        variant="body2"
                      >
                        <span>Reward</span> <span className={classes.modalMeta}><img className={classes.verified} src={money} /> {`${displayPledgeAmount}`}</span>
                        {/* <img className={classes.verified} src={logo}/> */}
                      </Typography>
                      {modalChallenge?.goal === 'Yes' ?
                        <Typography
                          className={`${classes.verifiedUser} ${classes.primPadding} ${classes.gap}`}
                          variant="body2"
                        >
                          <span>Goal</span> <span className={classes.modalMeta}><img className={classes.verified} src={money} /> {`${modalChallenge?.goalAmount}`}</span>
                          {/* <img className={classes.verified} src={logo}/> */}
                        </Typography>
                        : <div></div>
                      }
                      <Typography component={Link} to={`/users/${modalChallenge?.influencerAtName}`}
                        className={`${classes.verifiedUser} ${classes.primPadding} ${classes.gap}`}
                        variant="body2"
                      >
                        <span>Upvotes</span> <span className={classes.modalMeta}><img className={classes.verified} src={heart2} /> {modalChallenge?.likeCount ? modalChallenge.likeCount : 0}</span>
                        {/* <img className={classes.verified} src={logo}/> */}
                      </Typography>
                      <Typography component={Link} to={`/users/${modalChallenge?.influencerAtName}`}
                        className={`${classes.verifiedUser} ${classes.primPadding} ${classes.gap}`}
                        variant="body2"
                      >
                        <Tooltip title={
                          modalChallenge?.status === 'accepted' ?
                            'Time left to complete the challenge' :
                            'Time left to complete the challenge'
                        }
                          placement="top">
                          <span>{(modalChallenge?.status === 'accepted') ?
                            'Time Left to Complete' :
                            modalChallenge?.status === 'created' ?
                              'Time to Complete' :
                              `Challenge Status`}</span>
                        </Tooltip>
                        <Tooltip title={
                          modalChallenge?.status === 'accepted' ?
                            this.state.countDownTime2 :
                            modalChallenge?.status === 'created' ?
                              modalChallenge && getTimeSize(modalChallenge.completeByDate) :
                              `This challenge was  ${modalChallenge?.status}`
                        }
                          placement="top">
                          <span className={classes.modalMeta}>
                            <img className={classes.verified} src={time} />
                            {
                              modalChallenge?.status === 'accepted' ?
                                this.state.countDownTime2 :
                                modalChallenge?.status === 'created' ?
                                  modalChallenge && getTimeSize(modalChallenge.completeByDate) :
                                  modalChallenge?.status
                            }
                          </span>
                        </Tooltip>
                        {/* <img className={classes.verified} src={logo}/> */}
                      </Typography>
                      <div className={classes.challengeTools}>
                        <img className={`${classes.verified} ${showBoost ? classes.challengeToolsActive : ''}`} src={grayMoney} onClick={this.showBoost} />
                        <img className={`${classes.verified} ${showClips ? classes.challengeToolsActive : ''}`} src={grayVid} onClick={this.showClips} />
                        <img className={`${classes.verified} ${showLink ? classes.challengeToolsActive : ''}`} src={grayLink} onClick={this.showLink} />
                        <img className={`${classes.verified} ${showAction ? classes.challengeToolsActive : ''}`} src={grayBang} onClick={this.showAction} />
                        <img className={`${classes.verified} ${showMod ? classes.challengeToolsActive : ''}`} src={grayDots} onClick={this.showMod} />
                      </div>
                      {
                        (modalChallenge?.influencerAtName !== this.props.user.credentials?.handle
                          && modalChallenge?.userHandle !== this.props.user.credentials?.handle)
                          && (modalChallenge?.status !== 'created' && modalChallenge?.status !== 'accepted')
                          ?
                          (
                            modalChallenge && showLink
                              ?
                              <div className={classes.linkChall}>
                                <div className={`${classes.gap}`}>
                                  <Typography variant="body1" className={classes.pageText}>
                                    Share this challenge:
                                  </Typography>
                                  <Typography variant="body1" className={classes.link}>
                                    {`https://meed.app/users/${modalChallenge.influencerAtName}/challenge/${modalChallenge.challengeId}`}
                                  </Typography>
                                </div>
                                <div className={`${classes.gap}`}>
                                  <Typography variant="body1" className={classes.pageText}>
                                    Add Video Clip Hyperlink<br></br> (All links subject to approval)
                                  </Typography>
                                  <TextField
                                    variant="standard"
                                    className={`${classes.endDate} ${classes.whiteBg}`}
                                    placeholder="https://clips.twitch.tv/ModernAuspiciousAlbatrossBigBrother-ylivODItb1Wxhhja"
                                    name='clip'
                                    InputProps={{ disableUnderline: true }}
                                    helperText={invalidLink}
                                    error={invalidLink ? true : false}
                                    onChange={this.handleChange}
                                    type="text" />
                                </div>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  className={classes.submitButton}
                                  disabled={this.state.disabled1 || (!payAmount || payAmount <= 0) && (!this.state.clip || invalidLink)}
                                  onClick={this.handleSubmit}
                                >
                                  {
                                    `Submit `
                                  }
                                  {this.state.disabled1 ? <CircularProgress size={30} className={classes.progress} /> : ''}
                                </Button>
                              </div>
                              : <div></div>
                          )
                          :
                          (modalChallenge && (showLink || showBoost || showClips)) ?
                            <form onSubmit={this.handleSubmit}>
                              {modalChallenge && showBoost && (modalChallenge.status === 'created'
                                || modalChallenge.status === 'accepted' || thisChallenge.challenge.status === 'voting') ?
                                <div className={`${classes.gap}`}>
                                  <Typography variant="body1" className={classes.pageText}>
                                    Add to Reward
                                  </Typography>
                                  <TextField
                                    variant="standard"
                                    className={`${classes.endDate} ${classes.whiteBg}`}
                                    InputProps={{ disableUnderline: true }}
                                    placeholder="$100"
                                    name="payAmount"
                                    onChange={this.handleChange}
                                    onKeyDown={(event) => {
                                      // Prevent 'E' from being entered
                                      if (event.keyCode === 69) {
                                        event.preventDefault();
                                      }
                                    }}
                                    type="number"
                                  />
                                </div>
                                :
                                showBoost && !(modalChallenge.status === 'created'
                                  || modalChallenge.status === 'accepted' || modalChallenge.status === 'voting') ?
                                  <div className={`${classes.gap}`}>
                                    <Typography variant="body1" className={classes.pageText}>
                                      You can only Boost an Open or Active Challenge
                                    </Typography>
                                  </div>
                                  : <div></div>
                              }
                              {modalChallenge && showLink
                                ?
                                <div className={classes.linkChall}>
                                  <div className={`${classes.gap}`}>
                                    <Typography variant="body1" className={classes.pageText}>
                                      Share this challenge:
                                    </Typography>
                                    <Typography variant="body1" className={classes.link}>
                                      {`https://meed.app/users/${modalChallenge.influencerAtName}/challenge/${modalChallenge.challengeId}`}
                                    </Typography>
                                  </div>
                                  <div className={`${classes.gap}`}>
                                    <Typography variant="body1" className={classes.pageText}>
                                      Add Video Clip Hyperlink<br></br> (All links subject to approval)
                                    </Typography>
                                    <TextField
                                      variant="standard"
                                      className={`${classes.endDate} ${classes.whiteBg}`}
                                      placeholder="https://clips.twitch.tv/ModernAuspiciousAlbatrossBigBrother-ylivODItb1Wxhhja"
                                      name='clip'
                                      InputProps={{ disableUnderline: true }}
                                      helperText={invalidLink}
                                      error={invalidLink ? true : false}
                                      onChange={this.handleChange}
                                      type="text" />
                                  </div>
                                </div>
                                : <div></div>
                              }
                              {!modalChallenge?.clips && showClips
                                ?
                                <div className={`${classes.gap}`}>
                                  <Typography variant="body1" className={`${classes.pageText} ${classes.pointer}`} onClick={this.showLink}>
                                    There are currently no clips, click here to add one!
                                  </Typography>
                                </div>
                                : <div></div>
                              }
                              <hr className={classes.challLineBreak}></hr>
                              {!authenticated ?
                                <Button
                                  variant="contained"
                                  className={classes.submitButton}
                                  component={Link} to={`/signup`}
                                >
                                  Sign in to Participate
                                </Button>
                                :
                                (showLink || (showBoost && (modalChallenge.status === 'created'
                                  || modalChallenge.status === 'accepted' || thisChallenge.challenge.status === 'voting'))) ?
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    className={classes.submitButton}
                                    disabled={this.state.disabled1 || (!payAmount || payAmount <= 0) && (!this.state.clip || invalidLink)}
                                    onClick={this.handleSubmit}
                                  >
                                    {
                                      `Submit `
                                    }
                                    {this.state.disabled1 ? <CircularProgress size={30} className={classes.progress} /> : ''}
                                  </Button>
                                  : <div></div>
                              }
                            </form>
                            : <div></div>
                      }
                      {/* { deleteButton } */}
                      {/* <LikeButton className={classes.likeButton} challengeId={challengeId} />
                <span className={classes.likeCount}>{likeCount}</span> */}
                    </CardContent>
                  }
                  <div className={classes.actionButtons3}>
                    {modalChallenge && showMod ?
                      <div>
                        <Typography component={Link} to={`/users/${modalChallenge && modalChallenge.userId ? modalChallenge.userHandle : modalChallenge && modalChallenge.influencerAtName}`}>
                          Go to {modalChallenge.userHandle}'s Profile Page
                        </Typography>
                        {
                          modalChallenge?.influencerId === this.props.user.credentials?.userId
                            ?
                            <div>
                              <Typography onClick={this.modUser}>
                                Make Moderator
                              </Typography>
                              <Typography onClick={this.banUser}>
                                Ban
                              </Typography>
                            </div>
                            :
                            <div></div>
                        }
                        <Typography onClick={this.reportUser}>
                          Report User
                        </Typography>
                      </div>
                      : <div></div>
                    }
                    {
                      modalChallenge && !showAction ?
                        <div></div> :
                        <div>
                          {declineButtonModal}
                          {acceptButtonModal}
                          {failButtonModal}
                          {completeButtonModal}
                          {othersDecideButtonModal}
                          {voteButtonModal}
                          {noActionModal}
                        </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        {/****************************** Challenge Modal Ends ******************************/}

      </Fragment>
    );
  }
}

Challenge.propTypes = {
  user: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  challenge: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  openDialog: PropTypes.bool,
  getTotalFundraised: PropTypes.func.isRequired,
  acceptChallenge: PropTypes.func.isRequired,
  declineChallenge: PropTypes.func.isRequired,
  completeChallenge: PropTypes.func.isRequired,
  getChallenge: PropTypes.func.isRequired,
  failChallenge: PropTypes.func.isRequired,
  cancelChallenge: PropTypes.func.isRequired,
  boostChallenge: PropTypes.func.isRequired,
  submitClip: PropTypes.func.isRequired,
  likeChallenge: PropTypes.func.isRequired,
  unlikeChallenge: PropTypes.func.isRequired,
  handleVote: PropTypes.func.isRequired,
  removeVote: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
});

const mapActionsToProps = {
  getChallenge,
  likeChallenge,
  unlikeChallenge,
  getTotalFundraised,
  acceptChallenge,
  declineChallenge,
  completeChallenge,
  failChallenge,
  cancelChallenge,
  boostChallenge,
  submitClip,
  mod,
  unmod,
  ban,
  unban,
  reportUser,
  removeVote,
};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(challengeStyles)(Challenge));