// meed/src/components/challenge/ReportChallenge.js
import React, { Component, Fragment } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import MyButton from '../../util/MyButton';
import { toast } from 'react-toastify';

// MUI Stuff
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import { connect } from 'react-redux';
import { reportChallenge } from '../../redux/actions/dataActions';
import reportChallengeStyles from './styles/reportChallengeStyles';
import { DialogContent } from '@mui/material';

// page 6 - 7 https://docs.google.com/document/d/1-hxknVHV4a38-sV3liT32i2VU2qRUewDqXNuhdAKsJk/edit CMS & CRM Needs Doc
class ReportChallenge extends Component {
  state = {
    open: false,
    reason: 'default',
  };
  
  handleOpen = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  reportChallenge = async (event) => {
   if (this.state.reason === 'default'|| !this.state.reason){
    toast.error('Please select the reason.');
    return;
   }
    try {
      //console.log(this.state.reason);
      const res = await this.props.reportChallenge(this.props.challengeId, this.state.reason);
      if (res && res.success) {
        toast.success('SUCCESS: Challenge Reported!');
        this.props.updateChallenge(this.props.challengeId);
      } else {
        toast.error('Failure: Challenge not Reported!');
      }
    } catch (err) {
      // This will catch any errors that might occur during the await operation
      toast.error('Failure: Challenge not Reported!');
    } finally {
      // Update the state regardless of the operation's outcome
      this.setState({ open: false });
    }
  };  
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <MyButton
          tip="Report this Challenge"
          onClick={this.handleOpen}
          btnClassName={classes.reportChallenge}
          disabled={this.state.disabled}
        >Report this Challenge</MyButton>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            Why are you reporting this challenge?
          </DialogTitle>
          <DialogContent>
          <TextField
                    select
                    //width='95%'
                    disableUnderline={true}
                    className={`${classes.menuFormat} ${classes.fieldFormat}`}
                    id="reason"
                    //style={`${classes.roundedRadius}`}
                    name='reason'
                    value={this.state.reason}
                    variant='outlined'
                    margin="normal"
                    onChange={this.handleChange}
                   
                >
                    <MenuItem value= {'default'}> Select Reason </MenuItem> 
                    <MenuItem value={'inappropriate'}>Inappropriate</MenuItem>
                    <MenuItem value={'spam'}>Spam</MenuItem>
                    <MenuItem value={'harassment'}>Harassment</MenuItem>
                    <MenuItem value={'hate_speech'}>Hate Speech</MenuItem>
                    <MenuItem value={'violence'}>Violence</MenuItem>
                    <MenuItem value={'other'}>Other</MenuItem>
                </TextField>
          </DialogContent>
          <DialogActions>
          
            <Button onClick={this.handleClose}>
              Cancel
            </Button>
            <Button onClick={this.reportChallenge}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

ReportChallenge.propTypes = {
  reportChallenge: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  challengeId: PropTypes.string.isRequired
};

export default connect(
  null,
  { reportChallenge }
)(withStyles(reportChallengeStyles)(ReportChallenge));
