// meed/src/components/challenge/FailChallenge.js
import React, { Component, Fragment } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import MyButton from '../../util/MyButton';
import { toast } from 'react-toastify';

// MUI Stuff
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import { connect } from 'react-redux';
import { failChallenge } from '../../redux/actions/dataActions';
import failChallengeStyles from './styles/failChallengeStyles';

class FailChallenge extends Component {
  state = {
    open: false,
    disabled: false
  };
  handleOpen = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  failChallenge = async () => {
    this.setState({ disabled: true });
    try {
      const res = await this.props.failChallenge(this.props.challengeId);
      if (res && res.success) {
        toast.success('SUCCESS: Challenge Failed!');
        this.props.updateChallenge(this.props.challengeId);
      } else {
        toast.error('Failure: Challenge not Failed!');
      }
    } catch (err) {
      // This will catch any errors that might occur during the await operation
      toast.error('Failure: Challenge not Failed!');
    } finally {
      // Update the state regardless of the operation's outcome
      this.setState({ disabled: false, open: false });
    }
  };  
  render() {
    const { classes } = this.props;

    const votingTypeTips = {
      all: "Move Challenge to 24hr voting stage and cast a fail vote. Challenge fails with more fail votes than pass votes.",
      both: "Move Challenge to 24hr voting stage and cast a fail vote. Challenge will fail unless you and creator make a pass vote.",
      boost: "Move Challenge to 24hr voting stage and cast a fail vote. Challenge fails with more qualified fail votes than qualified pass votes.",
      creator: "Move Challenge to 24hr voting stage and cast a fail vote. Challenge fails if creator doesn't make a pass vote.",
      custom: "Move Challenge to 24hr voting stage and cast a fail vote. Challenge fails with more qualified fail votes than qualified pass votes.",
      default: "Move Challenge to 24hr voting stage and cast a fail vote. Challenge fails unless you remove or change the vote to pass.",
    };

    // Get the tip based on the voting type from the dictionary
    const tip = votingTypeTips[this.props.votingType];

    return (
      <Fragment>
        <MyButton
          tip={tip}
          onClick={this.handleOpen}
          btnClassName={classes.failButton}
          disabled={this.state.disabled}
        >
          Fail
        </MyButton>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            Are you sure you want to fail this challenge ?
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleClose}>
              Cancel
            </Button>
            <Button onClick={this.failChallenge}>
              Fail
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

FailChallenge.propTypes = {
  failChallenge: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  challengeId: PropTypes.string.isRequired,
  votingType: PropTypes.string.isRequired
};

export default connect(
  null,
  { failChallenge }
)(withStyles(failChallengeStyles)(FailChallenge));
