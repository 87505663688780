// meed/src/pages/team.js
import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
// MUI Stuff
import Grid from '@mui/material/Grid';
// Redux stuff
// import Filter from 'bad-words';
// Redux stuff
import { connect } from 'react-redux';
import team1 from '../images/team1.jpg';
import team2 from '../images/team2.png';
import team3 from '../images/team3.jpg';
import team4 from '../images/team4.png';
import mascot from '../images/mascot.png';
import swords from '../images/swords.png';
import teamStyles from './styles/teamStyles';

// money only counts for ppl with accounts on both sides
// only email, no from field
class team extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {},
      submitted: false,
      open1: false,
      matches: window.matchMedia("(min-width: 768px)").matches,
      Twitch: window.Twitch,
      searchChall: true,
      socialName: '',
      payAmount: 0,
      open: false,
      body: '',
      endDate: 'xxlarge',
      completeByDate: 'xxlarge',
      influencer: '',
      timeout: null,
      loading: false,
      userHandleUrl: null,
      challengeCost: 0,
      mpChecked: true,
      tChecked: false,
      disabled: false,
      goal: 'No'
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }
  handleClickOpen = async (e, challenge) => {
    e.stopPropagation();
    e.preventDefault();
    await this.setState({
      open1: true
    });
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.state.once) {
      if (this.props.home) {

      }
    }
  }
  render() {
    // const { errors } = this.state;
    const {
      classes,
    } = this.props;

    return (
      <div className={classes.team}>
        <Grid container className={classes.reelWrap}>
          {/* <div className={classes.bgThumb}></div> */}
          <Grid className={`${classes.gridSpacingNone} ${classes.topJoint} ${classes.mainGrid}`} xs={12}>
            <div className={classes.cta}>
              <div className={classes.ctaText}>
                <h1>Meet the Team!</h1>
              </div>
              <div className={classes.ctaBtn}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  onClick={this.handleClickOpen}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="34.875" height="34.875" viewBox="0 0 34.875 34.875">
                    <path id="Icon_awesome-chevron-circle-down" data-name="Icon awesome-chevron-circle-down" d="M35.438,18A17.438,17.438,0,1,1,18,.563,17.434,17.434,0,0,1,35.438,18ZM19.2,26.009l9.527-9.527a1.681,1.681,0,0,0,0-2.384l-1.2-1.2a1.681,1.681,0,0,0-2.384,0L18,20.046,10.856,12.9a1.681,1.681,0,0,0-2.384,0l-1.2,1.2a1.681,1.681,0,0,0,0,2.384L16.8,26.009A1.689,1.689,0,0,0,19.2,26.009Z" transform="translate(-0.563 -0.563)" fill="#fff" />
                  </svg>

                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.moreWrap}>
          <Grid className={`${classes.gridSpacingNone} ${classes.mainGrid}`} item sm={12} md={12}>
            <div className={classes.ctaText}>
              <h1>Led by a Veteran Team</h1>
              <br></br>
            </div>
          </Grid>
        </Grid>

        <Grid container className={`${classes.moreWrap}`}>
          <Grid className={`${classes.gridSpacingNone} ${classes.mainGrid} ${classes.cardCta}`} item sm={2} xs={12}>
            <div className={`${classes.img1} ${classes.mid}`}>
              <div className={`${classes.ctaTextCard} ${classes.mid}`}>
                <p className={`${classes.thick}`}>
                  Armante Washington
                </p>
                <p>
                  "Shwoobz"
                </p>
                <p className={classes.role}>Engineering Product Design</p>
              </div>
            </div>
            <div className={`${classes.ctaTextInfo} ${classes.smoll}`}>
              <img className={classes.jobs} src={swords} alt="Crossed swords icon"></img>
              <img className={classes.jobs} src={mascot} alt="Purple fruit mascot with a green leafy top icon"></img>
              <img className={classes.jobs} src={swords} alt="Crossed swords icon"></img>
              <img className={classes.jobs} src={mascot} alt="Purple fruit mascot with a green leafy top icon"></img>
            </div>
          </Grid>
          <Grid className={`${classes.gridSpacingNone} ${classes.mainGrid} ${classes.cardCta}`} item sm={2} xs={12}>
            <div className={`${classes.img2} ${classes.mid}`}>
              <div className={`${classes.ctaTextCard} ${classes.mid}`}>
                <p className={`${classes.thick}`}>
                  Joshua Singh
                </p>
                <p>
                  "Hayvan"
                </p>
                <p className={classes.role}>Engineering Product Design</p>
              </div>
            </div>
            <div className={`${classes.ctaTextInfo} ${classes.smoll}`}>
              <img className={classes.jobs} src={swords} alt="Crossed swords icon"></img>
              <img className={classes.jobs} src={mascot} alt="Purple fruit mascot with a green leafy top icon"></img>
              <img className={classes.jobs} src={swords} alt="Crossed swords icon"></img>
              <img className={classes.jobs} src={mascot} alt="Purple fruit mascot with a green leafy top icon"></img>
            </div>
          </Grid>
          <Grid className={`${classes.gridSpacingNone} ${classes.mainGrid} ${classes.cardCta}`} item sm={2} xs={12}>
            <div className={`${classes.img3} ${classes.mid}`}>
              <div className={`${classes.ctaTextCard} ${classes.mid}`}>
                <p className={`${classes.thick}`}>
                  Michael Campbell
                </p>
                <p>
                  "Retrograde Arc"
                </p>
                <p className={classes.role}>Engineering Product Design</p>
              </div>
            </div>
            <div className={`${classes.ctaTextInfo} ${classes.smoll}`}>
              <img className={classes.jobs} src={swords} alt="Crossed swords icon"></img>
              <img className={classes.jobs} src={mascot} alt="Purple fruit mascot with a green leafy top icon"></img>
              <img className={classes.jobs} src={swords} alt="Crossed swords icon"></img>
              <img className={classes.jobs} src={mascot} alt="Purple fruit mascot with a green leafy top icon"></img>
            </div>
          </Grid>
          <Grid className={`${classes.gridSpacingNone} ${classes.mainGrid} ${classes.cardCta}`} item sm={2} xs={12}>
            <div className={`${classes.img3} ${classes.mid}`}>
              <div className={`${classes.ctaTextCard} ${classes.mid}`}>
                <p className={`${classes.thick}`}>
                  Ella Pravets
                </p>
                <p>
                  "ellabella"
                </p>
                <p className={classes.role}>Engineering Product Design</p>
              </div>
            </div>
            <div className={`${classes.ctaTextInfo} ${classes.smoll}`}>
              <img className={classes.jobs} src={swords} alt="Crossed swords icon"></img>
              <img className={classes.jobs} src={mascot} alt="Purple fruit mascot with a green leafy top icon"></img>
              <img className={classes.jobs} src={swords} alt="Crossed swords icon"></img>
              <img className={classes.jobs} src={mascot} alt="Purple fruit mascot with a green leafy top icon"></img>
            </div>
          </Grid>
          <Grid className={`${classes.gridSpacingNone} ${classes.mainGrid} ${classes.cardCta}`} item sm={2} xs={12}>
            <div className={`${classes.img3} ${classes.mid}`}>
              <div className={`${classes.ctaTextCard} ${classes.mid}`}>
                <p className={`${classes.thick}`}>
                  Ike Nnoli
                </p>
                <p>
                  "Ikizzle"
                </p>
                <p className={classes.role}>Engineering Product Design</p>
              </div>
            </div>
            <div className={`${classes.ctaTextInfo} ${classes.smoll}`}>
              <img className={classes.jobs} src={swords} alt="Crossed swords icon"></img>
              <img className={classes.jobs} src={mascot} alt="Purple fruit mascot with a green leafy top icon"></img>
              <img className={classes.jobs} src={swords} alt="Crossed swords icon"></img>
              <img className={classes.jobs} src={mascot} alt="Purple fruit mascot with a green leafy top icon"></img>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.footer}>
          <Grid className={`${classes.gridSpacingNone} ${classes.mainGrid}`} item sm={4} xs={12}>
            <div className={`${classes.ctaText} ${classes.pink} ${classes.mid}`}>
              <p>
                {`
                      Meet the Meed Team.
                      We made it happen.
                      You can thank us later.
                    `}
              </p>
              <br></br>
              <br></br>
              <div className={classes.ctaBtn}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  component={Link}
                  to={`/home`}
                >
                  Make a Challenge
                </Button>
              </div>
            </div>
          </Grid>
          <Grid className={`${classes.gridSpacingNone} ${classes.mainGrid}`} item sm={8} xs={12}>
            <div className={`${classes.ctaImgs} ${classes.mid}`}>
              <img className={classes.team2} src={team2} alt="Michael Campbell, co-founder, in a construction setting"></img>
              <img className={classes.team1} src={team1} alt="Armante Washington, co-founder, in a car"></img>
              <img className={classes.team3} src={team3} alt="Joshua Singh, co-founder, dressed in a suit"></img>
              <img className={classes.team4} src={team4} alt="Ella Pravetz, marketing team member, headshot"></img>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

team.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(teamStyles)(team));
