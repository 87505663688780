// meed/src/components/challenge/styles/challengeStyles.js
// import berryBg from '../../images/berryBg-removebg.png';
export const challengeStyles = {
  card: {
    backgroundColor: '#7A5DF9',
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    marginRight: 20,
    borderRadius: '0px',
    maxWidth: '350px',
    width: '100%',
    color: '#fff',
    userSelect: 'text',
    '@media (max-width: 960px)': {
      minWidth: '350px',
    },
  },
  rightAlign: {
    textAlign: 'right'
  },
  cardWrap: {
    width: '100%',
    maxHeight: '130px',
    whiteSpace: 'nowrap',
    paddingBottom: 5,
    '@media (max-width: 960px)': {
      padding: 10,
    },
  },
  minWide: {
    minWidth: '350px',
    '&:hover': {
      zIndex: 1,
      opacity: 0.9,
      transform: 'scale(1.01)',
      transition: 'all .3s',
    },
    '@media (max-width: 960px)': {
      opacity: 1,
    },
  },
  donate: {
    minWidth: '350px',
    maxWidth: '350px',
    marginRight: 20,
    '&:hover': {
      zIndex: 1,
      opacity: 0.9,
      transform: 'scale(1.01)',
      transition: 'all .3s',
    },
    '@media (max-width: 960px)': {
      opacity: 1,
    },
  },
  home: {
    margin: 0,
    '&:hover': {
      zIndex: 1,
      opacity: 0.9,
      transform: 'unset',
      transition: 'all .3s',
    },
    '@media (max-width: 1400px)': {
      minWidth: 'unset',
    },
    '@media (max-width: 1279px)': {
      minWidth: '350px',
    },
    '@media (max-width: 1102px)': {
      minWidth: 'unset',
    },
    '@media (max-width: 959px)': {
      minWidth: '350px',
    },
    '@media (max-width: 843px)': {
      minWidth: 'unset',
    },
    '@media (max-width: 727px)': {
      maxWidth: 'unset',
    },
  },
  noSpacingCard: {
    backgroundColor: '#7A5DF9',
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '0px',
    maxHeight: '130px',
    width: '100%',
    color: '#fff',
    paddingBottom: 5,
    borderBottom: 'solid 1px #fff',
    opacity: 1,
    transition: '.4s',
    '&:hover': {
      '& p': {
        opacity: .9,
        transform: 'translateY(-2px)',
        transition: '1s',
      }
    },
    '@media (max-width: 960px)': {
      opacity: 1,
      maxWidth: 'unset',
      margin: '2px 3px',
      padding: 10,
      minWidth: '350px',
    },
  },
  image: {
    minWidth: 150
  },
  content: {
    overflow: 'hidden',
    objectFit: 'cover',
    padding: 0,
    width: '100%',
    paddingBottom: '6px !important',
    margin: 12,
    marginBottom: 0,
    color: '#fff',
    textOverflow: 'ellipsis',
    userSelect: 'text',
    '& form': {
      textAlign: 'center'
    },
    '@media (max-width: 960px)': {
      margin: 0,
    }
  },
  userMetaCard: {
    display: 'flex',
    flexDirection: 'row'
  },
  purpleCircleHome: {
  },
  conty: {
    overflowY: 'auto',
    overflowX: 'hidden',
    objectFit: 'cover',
    padding: 0,
    width: '100%',
    paddingBottom: '6px !important',
    margin: 12,
    marginBottom: 0,
    color: '#5B5A57',
    textOverflow: 'ellipsis',
    userSelect: 'text',
    '& form': {
      textAlign: 'center',
      marginTop: 20
    },
    '@media (max-width: 960px)': {
      margin: 0,
    },
    '&::-webkit-scrollbar': {
      width: '100%',
      height: 4
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      outline: '1px solid slategrey'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#7A5DF9',
      outline: '1px solid slategrey'
    }
  },
  modalChall: {
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 960px)': {
      padding: 10
    },
  },
  modalMeta: {
    width: '30%',
    display: 'flex',
    textOverflow: 'ellipses',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '& img': {
      width: 20,
      height: 20,
      marginRight: 5
    }
  },
  hideOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#fff',
    fontWeight: '700',
    userSelect: 'text',
  },
  completedStateTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#fff',
    paddingRight: '55px',
    fontWeight: '700'
  },
  verifiedUser: {
    display: 'flex',
    marginBottom: '10px',
    color: '#fff',
    textTransform: 'capitalize',
    userSelect: 'text',
  },
  challengeEngagement: {
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 20,
    margin: '0 2px',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      '& img': {
        marginRight: 5,
        marginLeft: 0
      }
    }
  },
  heart: {
    '&:hover': {
      transform: 'scale(1.09)',
      transition: 'all .3s',
    },
  },
  heartLight: {
    opacity: .4
  },
  completedState: {
    display: 'flex',
    marginBottom: 5,
    color: '#fff',
    textTransform: 'uppercase',
  },
  description: {
    display: 'flex',
    color: '#fff',
    fontSize: '11px',
    overflowX: 'hidden',
    maxWidth: '85%'
  },
  modalDesc: {
    padding: '0 !important',
    maxWidth: '500px !important'
  },
  tit: {
    textTransform: 'unset',
    fontWeight: 500,
    fontSize: '1.2rem'
  },
  modaltoUser: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 12,
    background: '#3F2D74'
  },
  desc: {
    maxHeight: 200,
    overflow: 'auto',
    maxWidth: 'unset',
    textTransform: 'unset',
    '&::-webkit-scrollbar': {
      width: '100%',
      height: 4
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      outline: '1px solid slategrey'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#7A5DF9',
      outline: '1px solid slategrey'
    }
  },
  completedStateDescription: {
    display: 'flex',
    color: '#5B5A57',
    fontSize: '11px',
    overflowX: 'hidden',
    maxWidth: '85%',
  },
  verified: {
    marginLeft: '5px',
    width: '20px'
  },
  likeButton: {
    zIndex: 1
  },
  likeCount: {
    color: '#BB6BD9'
  },
  challengeFunding: {
    fontSize: 20,
    fontWeight: 400,
    display: 'inline-block',
    position: 'absolute',
    right: '20px',
    color: '#fff'
  },
  purpleCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#54148B',
    color: '#5B5A57',
    borderRadius: '50% !important',
    position: 'absolute',
    top: '10px',
    right: '10px',
    width: '70px',
    height: '70px'
  },
  submitButton: {
    backgroundColor: '#14C7FC',
    color: '#fff',
    opacity: 1,
    transition: 'all .4s',
    '&:hover': {
      opacity: 0.9,
      border: 'solid 1px #5B5A57',
      background: '#14C7FC'
    },
  },
  actionButtons3: {
    color: '#fff',
    position: 'relative',
    bottom: 5,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15,
    '& p': {
      textAlign: 'center',
      cursor: 'pointer',
      margin: 5,
      '&:hover': {
        opacity: .9
      },
    },
    '& a': {
      textAlign: 'center',
      cursor: 'pointer',
      margin: 5,
      '&:hover': {
        opacity: .9
      },
    }
  },
  actionButtons2: {
    color: '#fff',
    position: 'relative',
    bottom: 5,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
  },
  actionButtons: {
    color: '#fff',
    position: 'relative',
    bottom: 5,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    marginBottom: 10
  },
  buttonActions: {
    textAlign: 'center'
  },
  follow: {
    background: '#14C7FC',
    color: '#fff',
    position: 'absolute',
    top: 10,
    right: 10,
    opacity: 0.9,
    transition: 'all .4s',
    '&:hover': {
      opacity: 1,
      border: 'solid 1px #5B5A57',
      background: '#14C7FC'
    },
  },
  clickedChallenge: {
    height: '100%',
    position: 'absolute',
    overflow: 'auto',
    background: '#fff !important',
    flexDirection: 'column',
    paddingBottom: 30,
    cursor: 'auto',
    justifyContent: 'space-between',
    margin: 0,
    '&::-webkit-scrollbar': {
      width: '100%',
      height: 4
    },
    '@media (max-width: 960px)': {
      maxWidth: 'unset',
      position: 'relative'
    }
  },
  expandedView: {
    height: '100%',
    overflow: 'auto !important',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 960px)': {
      height: 'unset',
      '& div': {
        maxWidth: 'unset'
      },
    },
  },
  clicked: {
    background: '#fff !important',
    color: '#5B5A57 !important',
    '& p': {
      color: '#5B5A57 !important',
    },
  },
  noPadding: {
    padding: 0,
    paddingTop: 30,
  },
  oneMargin: {
    margin: '1px 0'
  },
  noMargin: {
    margin: 0
  },
  profileImage: {
    backgroundColor: '#5B5A57',
    padding: 2,
    position: 'relative',
    margin: '10px 5px',
    marginRight: 18,
    marginTop: 0,
    marginLeft: 0,
    width: '50px',
    height: '50px',
    borderRadius: '100%',
    border: 'solid 1px #14C7FC',
    objectFit: 'contain',
    objectPosition: 'center',
    cursor: 'pointer'
  },
  primPadding: {
    padding: '5px 16px'
  },
  challForm: {
    textAlign: 'center'
  },
  status: {
    textTransform: 'uppercase'
  },
  link: {
    textAlign: 'left',
    marginBottom: '5px',
    marginRight: '2px',
    fontSize: 12,
    color: '#6E55D9',
    wordBreak: 'break-all',
    whiteSpace: 'normal',
    fontWeight: 700,
    textTransform: 'unset !important'
  },
  pageText: {
    textAlign: 'left',
    marginBottom: '5px',
    marginRight: '2px',
    fontSize: 12,
    color: '#5B5A57'
  },
  whiteBg: {
    color: '#fff'
  },
  challLineBreak: {
    opacity: .2
  },
  accepted: {
    '&::before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      zIndex: 1,
      bottom: 0,
      right: 0,
      width: 0,
      height: 0,
      borderBottom: '30px solid #22EC79',
      borderLeft: '30px solid transparent',
    },
  },
  completed: {
    background: '#7A5DF9'
  },
  gap: {
    padding: '5px 16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: '#5B5A57'
  },
  endDate: {
    justifyContent: 'flex-end',
    maxWidth: '50% !important',
    width: '100%',
    '& input': {
      width: '100%',
      color: '#5B5A57',
      fontSize: '12px',
      background: 'rgba(243,243,243,1)',
      textAlign: 'right'
    }
  },
  pointer: {
    cursor: 'pointer',
    color: '#5B5A57',
    '&:hover': {
      color: '#14C7FC'
    },
  },
  dialog: {
    borderRadius: 0,
    '& div': {
      borderRadius: 0,
      maxWidth: 'unset',
      '@media (max-width: 960px)': {
        overflowY: 'auto'
      },
    },
  },
  dialogPaper: {
    background: '#fff',
    '@media (max-width: 960px)': {
      maxHeight: 'unset',
      width: '100%',
      height: '100%',
      margin: 0
    },
  },
  modalWrap: {
    padding: 30,
    '@media (max-width: 960px)': {
      padding: 0,
      height: '100vh',
    },
  },
  modalJoint: {
    borderRadius: 0,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 960px)': {
      flexDirection: 'column',
      height: '100vh',
    },
    '& p': {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#5B5A57'
    },
    '& a': {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#5B5A57'
    },
    '& span': {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#5B5A57',
      display: 'flex',
      alignItems: 'center',
      '& img': {
        marginRight: 5
      }
    },

  },
  modalLeft: {
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 960px)': {
      height: '100%'
    },
  },
  modalContent: {
    '@media (max-width: 960px)': {
      paddingTop: 0
    },
  },
  rightSide: {
    minWidth: 330,
    position: 'relative',
    overflow: 'hidden',
    '@media (max-width: 960px)': {
      height: '100%'
    },
  },
  modalRight: {
    position: 'absolute',
    height: '100%',
    overflow: 'auto !important',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 960px)': {
      height: 'unset',
      '& div': {
        maxWidth: 'unset'
      },
    },
  },
  modalRight2: {
    display: 'block',
  },
  clips: {

  },
  twitch: {
    paddingTop: '56.25%',
    position: 'relative',
    height: 0
  },
  videoWrapModal: {
    position: 'relative',
    paddingTop: '56.25%',
    height: 0,
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0
    }
  },
  twitchVideo: {
    minWidth: 545,
    position: 'relative',
    paddingTop: '56.25%',
    height: 0,
    '@media (max-width: 960px)': {
      minWidth: 'unset',
    },
    '& iframe': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0
    }
  },
  clipList: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    paddingRight: 16
  },
  clipHead: {
    textTransform: 'uppercase !important',
    fontWeight: '500',
    cursor: 'pointer'
  },
  challengeClip: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    overflowX: 'hidden',
    marginBottom: 10,
    '& a': {
      '&:hover': {
        color: '#14C7FC !important'
      },
    }
  },
  berryThumb: {
    background: '#7A5DF9',
    width: 50,
    height: 50,
    padding: 10,
    marginLeft: 16
  },
  clipTitle: {
    fontWeight: '500 !important',
    padding: '0px 16px !important',
    whiteSpace: 'break-spaces',
  },
  clipDesc: {
    fontSize: '12px !important',
    padding: '0px 16px !important'
  },
  newTab: {
    width: 20,
    position: 'absolute',
    bottom: 0,
    right: 0
  },
  modalCard: {
    minWidth: 350,
    position: 'relative',
    backgroundColor: '#7A5DF9',
    '& p': {
      color: '#fff'
    },
    '& a': {
      color: '#fff'
    },
    '@media (max-width: 960px)': {
      minWidth: 'unset',
    },
    '@media (max-width: 600px)': {
      minWidth: 'unset',
      padding: 0
    },
    '& img': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#5B5A57',
      color: '#5B5A57',
      borderRadius: '50% !important',
      position: 'relative',
      width: '50px',
      height: '50px'
    }
  },
  logo: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
    '@media (max-width: 960px)': {
      right: 10,
      top: 10,
    },
    '& img': {
      width: 15,
    }
  },
  linkHover: {
    '&:hover': {
      color: '#14C7FC !important'
    },
  },
  progress: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: '50%',
    transform: 'translate(50%, -50%)',
    transition: 'none',
    animation: 'none'
  },
  opacityHigh: {
    opacity: 0.9,
    marginRight: 5
  },
  transparent: {
    background: 'transparent',
    boxShadow: 'none',
    borderTop: 'solid 1px #fff',
    '& p': {
      color: '#fff'
    }
  },
  accordion: {
    boxShadow: 'none',
    '&::before': {
      height: 0
    }
  },
  modOptions: {
    overflowX: 'hidden',
    width: '100%',
    padding: 0,
    background: '#5B5A57',
    color: '#fff',
    '& div': {
      width: '100%',
    },
    '& p': {
      width: '100%',
      color: '#fff',
      paddingRight: '16px !important'
    }
  },
  modOption: {
    display: 'flex',
    flexDirection: 'column',
    background: '#14C7FC',
    textAlign: 'right',
    color: '#fff',
    '& p': {
      cursor: 'pointer',
      color: '#fff'
    }
  },
  linkChall: {
    overflowY: 'auto !important',
    textAlign: 'center'
  },
  goldLine: {
    border: 'none',
    margin: '10px 12px',
    background: '#14C7FC',
    width: '100%',
    height: 2,
  },
  versus: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '0 16px',
    '& span': {
      margin: '0 10px',
      textTransform: 'uppercase',
      fontWeight: 500,
      maxWidth: 70,
      minWidth: 70,
      justifyContent: 'center'
    },

  },
  challengeTools: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    '& img': {
      cursor: 'pointer',
      margin: '10px 0px',
      width: '20px',
      height: '20px',
      paddingBottom: 10
    }
  },
  challengeToolsActive: {
    borderBottom: 'solid 1px #5B5A57'
  },
  navShare: {
    cursor: 'pointer'
  },
  voteButton: {
    background: '#14C7FC',
    color: '#fff',
    transition: 'all .4s',
    '&:hover': {
      opacity: 1,
      border: 'solid 1px #5B5A57',
      background: '#14C7FC'
    },
  },
};