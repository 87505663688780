// meed/src/pages/styles/donateStyles.js
const donateStyles = (theme) => ({
    ...theme.custom,
    form: {
      marginTop: 64,
      padding: 20,
      display: 'flex',
      justifyContent: 'center',
      background: '#fff',
      color: '#fff',
      width: '100%',
      textAlign: 'center',
      '@media (max-width: 600px)': {
        marginTop: 56,
      },
    },
    formLogin: { 
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      '@media (max-width: 960px)': {
        width: '100%'
      },
    },
    socials: {
      display: 'flex',
      justifyContent: 'center'
    },
    buttonDefault: {
      display: 'inline-flex',
      background: 'transparent',
      color: '#7B5DF9',
      margin: 10,
      border: 'solid 1px #CFCFCF',
      borderRadius: '50%',
      width: '55px',
      height: '55px',
      fontSize: '1rem',
      minWidth: 'unset'
    },
    buttonSecondary: {
      background: '#7B5DF9',
      color: '#fff',
      display: 'block',
      width: '100%',
      padding: '18.5px 14px',
      borderRadius: '35px',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    buttonThird: {
      background: '#D5D5D5',
      color: '#fff',
      display: 'block',
      width: '100%',
      padding: '5px 10px',
      margin: 5,
      borderRadius: '35px',
      '@media (max-width: 960px)': {
        minWidth: 'unset'
      },
    },
    buttonClicked: {
      background: '#7B5DF9',
    },
    label: {
      color: '#707070',
      marginBottom: 50
    },
    pageTitle2: {
      color: '#8C8C8C',
      textAlign: 'left',
      fontWeight: 500,
      width: '100%'
    },
    gang: {
      height: '100%',
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& label.Mui-focused': {
        color: '#fff',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#fff',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#fff',
        },
        '&:hover fieldset': {
          borderColor: '#fff',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#fff',
        },
      },
      '@media (max-width: 600px)': {
        height: '50vh'
      },
    },
    gang2: {
      '@media (max-width: 600px)': {
        height: 'unset',
        padding: 20
      },
    },
    userTopElem: {
      width: '100%',
    },
    cpiece: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      '@media (max-width: 960px)': {
        width: '100%'
      },
    },
    pageTitle: {
      fontSize: '4em',
      padding: '0 100px',
      fontWeight: 400,
      color: '#fff',
      '@media (max-width: 960px)': {
        fontSize: '3em',
        padding: '0 20px',
      },
    },
    modal: {
      textAlign: 'center'
    },
    bgImgTitle: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      backgroundImage: `linear-gradient(rgba(123, 93, 249, .76), rgba(123, 93, 249, .76)), url(https://firebasestorage.googleapis.com/v0/b/covenant-28c21.appspot.com/o/loginPic.jpg?alt=media&token=2cd939ff-b177-4f36-8c86-8c0585309a57)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    },
    extraAction: {
      color:'#707070'
    },
    extraAction2: {
      color:'#D8D8D8',
      margin: '0px 5px',
      fontSize: '1rem'
    },
    bold: {
      fontWeight: 500
    },
    purple: {
      color: '#7B5DF9'
    },
    challengeQueue: {
      display: 'flex',
      margin: '20px 0',
      padding: '5px 0',
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        width: '100%',
        height: 4
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        outline: '1px solid slategrey',
        borderRadius: 35,
        backgroundColor: '#7A5DF9',  
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#fff',  
        borderRadius: 35,
      },
    },
    sliderWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: '#7A5DF9',
      borderRadius: 35,
      padding: '0 10px',
      marginTop: 5,
      marginBottom: '20px',
      '& span': {
        fontSize: '.7rem',
        margin: '0 5px'
      }
    },
    donateInput: {
      margin: '20px 0',
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: 35,
          border: '1px solid #ced4da', // Add this line to set the border
        },
        '&:hover fieldset': {
          borderColor: '#007bff', // Optional: change border color when hovering
        },
        '&.Mui-focused fieldset': {
          borderColor: '#007bff', // Optional: change border color when the input is focused
        },
      },
    },
    donateInputNumber: {
      margin: '20px 0px',
      '& p': {
        fontSize: '1.75rem',
        textAlign: 'center',
        padding: '18.5px 14px',
      },
      '& input': {
        fontSize: '2rem',
        textAlign: 'center',
        padding: '18.5px 14px',
      }
    },
    buttonRow: {
      display: 'flex',
      flexDirection: 'row',
      margin: 20,
      '@media (max-width: 960px)': {
        margin: 10
      },
    },
    donateInputRow: {
      margin: '20px 0',
      border: 'solid 1px #C6C6C6',
      borderRadius: 35,
    },
    flexCenter: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    horizLine: {
      display: 'inline-block',
      height: 1,
      background: '#D8D8D8',
      width: '30%',
      margin: '3.5px 0'
    },
    downArrow: {
      position: 'absolute',
      bottom: -25,
      width: 50,
      height: 50,
      background: '#fff',
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 1,
      borderRadius: '50%',
      color: '#7B5DF9',
      fontSize: '2rem',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'none',
      '@media (max-width: 600px)': {
        display: 'flex',
      },
    },
    buttonActive: {
      background: '#7B5DF9'
    },
    toggleChallenges: {
      width: '100%',
      textAlign: 'left',
      '&.MuiSwitch-Thumb': {
        color: '#fff !important'
      }
    },
    toggleSwitch: {
      color: '#fff !important',
      '&.MuiSwitch-Thumb': {
        color: '#fff !important'
      }
    },
    hide: {
      display: 'none'
    }
  });

export default donateStyles;