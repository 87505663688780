// meed/src/pages/styles/integrationStyles.js
import thumb from '../../images/topBanner.jpg';

const integrationStyles = (theme) => ({
    ...theme.custom,
    team: {
      background: "#9322D4",
      width: "100%",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch", // Changed from "center" to "stretch"
    },
    reelWrap: {
      position: "relative",
      width: "100%",
      // Removed height and overflow to allow content to dictate the size
    },
    topJoint: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "50vh", // Allows content to expand beyond 50vh if necessary
      backgroundImage: `url(${thumb})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      position: "relative",
    },
    // Main Grid
      mainGrid: {
        height: "100%",
        position: "relative",
        padding: "50px 100px",
        "& div": {
          boxShadow: "none",
        },
        "@media (max-width: 960px)": {
          padding: 20,
        },
        "@media (max-width: 600px)": {
          width: "100%",
          textAlign: "center",
        },
      },
      cta: {
        width: "100%",
        maxWidth: "1200px",
        color: "#FFF",
        textAlign: "center",
        padding: "20px",
        // Removed absolute positioning
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& h1": {
          fontFamily: '"Roboto", sans-serif',
          fontSize: "3em",
          margin: 0,
          "@media (max-width: 960px)": {
            fontSize: "2em",
          },
          "@media (max-width: 600px)": {
            fontSize: "1.5em",
          },
        },
      },
    // CTA Text
    ctaText: {
      "& h1": {
        fontFamily: '"Roboto", sans-serif',
        fontSize: "4em", // Slightly larger for more impact
        fontWeight: 700,
        margin: 0,
        letterSpacing: "0.02em",
        textShadow: "2px 2px 4px rgba(0,0,0,0.5)", // Adds depth
        "@media (max-width: 960px)": {
          fontSize: "3em",
        },
        "@media (max-width: 600px)": {
          fontSize: "2.5em",
        },
      },
      "& h2": {
        fontFamily: '"Roboto", sans-serif',
        fontSize: "1.5em",
        fontWeight: 400,
        margin: "15px 0 0 0",
        opacity: 0.9, // Slightly less prominent than h1
        maxWidth: "80%", // Ensures the subtitle doesn't stretch too wide
        lineHeight: 1.4,
        "@media (max-width: 960px)": {
          fontSize: "1.2em",
          maxWidth: "90%",
        },
        "@media (max-width: 600px)": {
          fontSize: "1em",
          maxWidth: "100%",
        },
      },
      animation: "$fadeInUp 1.5s ease-out",
    },
  
    // If you want to add a subtle animation to draw attention
  "@keyframes fadeInUp": {
    from: {
      opacity: 0,
      transform: "translateY(20px)"
    },
    to: {
      opacity: 1,
      transform: "translateY(0)"
    }
  },

  // CTA Section
  ctaSection: {
    fontWeight: 700,
    justifyContent: "center",
    margin: "40px",
  },
    integrationsContainerFormat1: {
      paddingTop: '80px',
      backgroundColor: 'rgba(236,236,236,1)',
    },
    commandContainerFormat: {
      padding: 0,
      paddingLeft: '120px',
      paddingRight: '120px',
      justifyContent: 'center',
      '@media (max-width: 960px)': {
        paddingLeft: '20px',
        paddingRight: '20px',
      },
    },
    commandFormat2: {
      paddingLeft: '25px',
      paddingRight: '25px',
      background: '#fff',
      marginBottom: '20px',
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '@media (max-width: 960px)': {
        display: 'none'
      },
    },
    discordImageContainer: {
      '& svg': {
          fill: '#5165F6',
          width: 120,
          height: 120,
          padding: '5px',
      },
    },
    streamlabsImageContainer: {
      '& svg': {
          fill: '#000',
          width: 280,
          height: 280,
          padding: '5px',
      },
    },
    twitchImageContainer: {
      '& svg': {
          fill: '#9146FF',
          width: 120,
          height: 120,
          padding: '5px',
      },
    },
    integrationSection: {
      padding: '15px 90px',
      paddingBottom: '30px',
      marginBottom: '20px',
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      background: 'transparent linear-gradient(180deg, #7A5DF9 0%, #6C4FEA 100%) 0% 0% no-repeat padding-box',
      '@media (max-width: 960px)': {
        padding: '15px 20px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'unset',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    ctaTextCard: {
      width: "100%",
      zIndex: 1,
      color: "#fff", // Ensures white color for text
      fontSize: ".785em",
      textAlign: "left",
      "& img": {
        width: 50,
      },
      "& p": {
        textAlign: "left",
      },
    },
    // Submit Button
  submitButton: {
    minWidth: 100,
    background:
      "transparent linear-gradient(270deg, #00B4FF 0%, #565FFF 47%, #B600FF 100%) 0% 0% no-repeat padding-box",
    color: "#fff",
    padding: "16px 36px",
    opacity: 1,
    transition: "all .4s",
    borderRadius: 80,
    fontSize: ".75rem",
    "&:hover": {
      opacity: 0.9,
    },
    "@media (max-width: 960px)": {
      padding: "12px 24px",
      fontSize: ".7rem",
    },
    // Removed absolute positioning
    marginTop: "20px", // Added margin for spacing
  },
    mid: {
      height: '100%',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      justifyContent: 'center !important',
      '& p': {
        fontSize: '2em',
        '@media (max-width: 960px)': {
          fontSize: '1.05em',
        },
        '@media (max-width: 600px)': {
          fontSize: '1.5em',
        },
      },
      '@media (max-width: 600px)': {
        height: 'unset',
      },
    },
    thick: {
      fontSize: '3em !important',
      fontWeight: 600,
      margin: '15px 0px !important'
    },
    integrationDescription: {
      paddingBottom: '25px',
      fontSize: '1.2em !important',
      paddingRight: '50px'
    },
    startIntegrationButton: {
      minWidth: 200,
      background: 'transparent linear-gradient(270deg, #00B4FF 0%, #565FFF 47%, #B600FF 100%) 0% 0% no-repeat padding-box',
      color: '#fff',
      padding: '10px 20px',
      opacity: 1,
      transition: 'all .4s',
      borderRadius: 80,
      fontSize: '1rem',
      textTransform: 'none',
      marginRight: '10px', // Add this line
      marginBottom: '10px', // Add this line
      '&:hover': {
        opacity: 0.9,
      },
    },
    buttonIconContainer: {
      display: 'inline-block',
      marginRight: '10px',
      verticalAlign: 'middle',
      '& svg': {
        width: '20px',
        height: '20px',
        fill: 'currentColor',
      },
    },
    buttonTextFormat: {
      verticalAlign: 'middle',
    },
    contactContainerFormat: {
      justifyContent: 'center',
      backgroundColor:'rgba(236,236,236,1)',
      display: 'flex',
      width: '100%',
      height: '100%',
      textAlign: 'center',
      padding: 50,
    },
    pTextSizeBold: {
      fontFamily: '"Roboto", sans-serif',
      fontWeight: 700,
      textAlign: 'left',
      color: '#5c5c5c',
      fontSize:'1.5em',
    },
    gang: {
      '& label.Mui-focused': {
        color: '#fff',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#fff',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#fff',
        },
        '&:hover fieldset': {
          borderColor: '#fff',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#fff',
        },
      },
    },
    roundedRadius: {
      width: '100%',
      '& fieldset': {
        borderRadius: '25px',
      }
    },
    buttonDefault: {
      minWidth: 100,
      marginRight:'15px',
      marginBottom:'15px',
      padding:'0px',
      paddingLeft: '10px',
      background: '#7B5DF9',
      color: '#fff',
      opacity: 1,
      transition: 'all .4s',
      borderRadius: 80,
      fontSize: '1em',
      boxShadow: 'none',
      textTransform:'none'
    },
    sendIconContainer: {
      '& svg': {
          fill: '#FFF',
          width:17,
          height:17,
          padding:'5px',
          marginTop:'7px',
      },
    },
    progress: {
      color: '#7B5DF9', // Example style
    },
    // **Missing Styles** **
    ctaBtn: {
      // Define necessary styles
    },
    messageFormat: {
      // Define necessary styles
    },
    comingSoonText: {
      fontSize: '2rem',
      fontWeight: 'bold',
      color: '#FFFFFF', // Changed to white
      margin: '20px 0',
      textAlign: 'center',
      letterSpacing: '2px',
      textTransform: 'uppercase',
    },
    codeBox: {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      padding: '2px 4px',
      borderRadius: '3px',
      fontFamily: 'monospace',
    },
});

export default integrationStyles;
