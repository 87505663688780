// meed/src/redux/actions/dataActions.js
import { isValidDataType, isValidPlatform } from '../../types/proofTypes';
import { API_BASE_URL } from '../../util/config';
import handleError from '../../util/errorHandler';
import { ERROR_MAPPING } from '../errorMapping';
import {
  SET_CHALLENGES,
  LOADING_DATA,
  DELETE_CHALLENGE,
  SET_ERRORS,
  POST_CHALLENGE,
  POST_SUGGESTION,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_CHALLENGE,
  SET_ACCEPTING_USERS,
  SET_COMPLETED_CHALLENGES,
  SET_STAFF_CHALLENGES,
  GET_TOTAL_RAISED,
  SHOW_NAV,
  HIDE_NAV,
  ACCEPT_CHALLENGE,
  DECLINE_CHALLENGE,
  COMPLETE_CHALLENGE,
  FAIL_CHALLENGE,
  CANCEL_CHALLENGE,
  BOOST_CHALLENGE,
  REPORT_CHALLENGE,
  OTHERS_DECIDE_CHALLENGE,
  CAST_PASS_VOTE,
  CAST_FAIL_VOTE,
  REMOVE_VOTE,
  SHOW_TOAST,
  CLEAR_TOAST_MESSAGE,
  ADD_PROOF_DATA,
  CLEAR_PROOF_DATA,
  SUBMIT_PROOF_SUCCESS,
  SUBMIT_PROOF_FAIL,
  STOP_LOADING_UI,
  LOADED_DATA,
  SET_GAMES,
  CREATE_MASS_CHALLENGE,
  ACCEPT_MASS_CHALLENGE,
  DECLINE_MASS_CHALLENGE,
  LOADING_MASS_CHALLENGE,
  SET_MASS_CHALLENGE_DETAILS,
  LOADED_MASS_CHALLENGE,
  SET_PARTICIPANT_STATUS,
  SET_MASS_CHALLENGES,
} from '../types';
import axios from 'axios';
axios.defaults.withCredentials = true;
export const showNav = () => (dispatch) => {
  dispatch({ type: SHOW_NAV });
};

export const hideNav = () => (dispatch) => {
  dispatch({ type: HIDE_NAV });
};

export const showToastMessage = message => ({
  type: SHOW_TOAST,
  payload: message
});

export const clearToastMessage = () => ({
  type: CLEAR_TOAST_MESSAGE
});

/**
 * Fetches the list of users currently accepting challenges and dispatches an action to update the state.
 * 
 * @async
 * @function
 * @returns {Promise<void>} Nothing is returned, but the state will be updated with the retrieved data or an empty array if an error occurs.
 */
export const getAcceptingUsers = () => async (dispatch) => {
  dispatch({ type: LOADING_DATA });

  try {
    const res = await axios.get(`${API_BASE_URL}/challenges/accepting-users`);

    if (res && res.data) {
      dispatch({
        type: SET_ACCEPTING_USERS,
        payload: res.data
      });
    } else {
      dispatch({
        type: SET_ACCEPTING_USERS,
        payload: []
      });
    }
  } catch (error) {
    console.error("Error fetching accepting users:", error);
    handleError(error, dispatch, 'getAcceptingUsers');
    dispatch({
      type: SET_ACCEPTING_USERS,
      payload: []
    });
  } finally {
    dispatch({ type: LOADED_DATA });
  }
};

/**
 * Fetches the list of completed challenges, dispatches an action to update the state with the retrieved data, 
 * and returns the list of completed challenges.
 * 
 * @async
 * @function
 * @returns {Promise<Array<Object>|null>} The list of completed challenges or null if an error occurs.
 */
export const getCompletedChallenges = () => async (dispatch) => {
  dispatch({ type: LOADING_DATA });

  try {
    const res = await axios.get(`${API_BASE_URL}/challenges/completed`);

    if (res && res.data) {
      dispatch({
        type: SET_COMPLETED_CHALLENGES,
        payload: res.data
      });
      return res.data;
    }

    return null;
  } catch (error) {
    console.error("Error fetching completed challenges:", error);
    handleError(error, dispatch, 'getCompletedChallenges');
    dispatch({
      type: SET_COMPLETED_CHALLENGES,
      payload: []
    });
    return null;
  } finally {
    dispatch({ type: LOADED_DATA });
  }
};

/**
 * Fetch staff challenges and dispatch the appropriate action to update the state.
 *
 * @async
 * @function
 * @return {Function} A Redux Thunk action that dispatches either the action to set staff challenges 
 * or an action to set an empty list in case of an error.
 */
export const getStaffChallenges = () => async (dispatch) => {
  dispatch({ type: LOADING_DATA });

  try {
    const res = await axios.get(`${API_BASE_URL}/challenges/staff`);

    if (res && res.data) {
      dispatch({
        type: SET_STAFF_CHALLENGES,
        payload: res.data
      });
    }
  } catch (error) {
    console.error("Error fetching staff challenges:", error);
    handleError(error, dispatch, 'getStaffChallenges');
    dispatch({ type: SET_STAFF_CHALLENGES, payload: [] });
  } finally {
    dispatch({ type: LOADED_DATA });
  }
};

/**
 * Fetches a challenge based on its ID and returns the challenge data.
 * 
 * @async
 * @param {string} challengeId - The ID of the challenge to retrieve.
 * @function
 * @returns {Promise<Object|null>} The challenge data or null if an error occurs.
 */
export const getChallenge = (challengeId) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const res = await axios.get(`${API_BASE_URL}/challenges/${challengeId}`);

    if (res && res.data) {
      return res.data;
    }

    return null;
  } catch (error) {
    console.error('Error fetching challenge:', error);
    handleError(error, dispatch, 'getChallenge');
    dispatch({
      type: SET_CHALLENGE,
      payload: {}
    });
    return null;
  } finally {
    dispatch({ type: STOP_LOADING_UI });
  }
};

/**
 * Fetches a challenge based on its ID, dispatches an action to update the state 
 * with the retrieved challenge, and returns the challenge data.
 * 
 * @async
 * @param {string} challengeId - The ID of the challenge to retrieve.
 * @function
 * @returns {Promise<Object|null>} The challenge data or null if an error occurs.
 */
export const getChallengeById = (challengeId) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const res = await axios.get(`${API_BASE_URL}/challenges/${challengeId}`);

    if (res && res.data) {
      dispatch({
        type: SET_CHALLENGE,
        payload: res.data
      });
      return res.data;
    }

    return null;
  } catch (error) {
    console.error('Error fetching challenge by ID:', error);
    handleError(error, dispatch, 'getChallengeById');
    dispatch({
      type: SET_CHALLENGE,
      payload: {}
    });
    return null;
  } finally {
    dispatch({ type: STOP_LOADING_UI });
  }
};

/**
 * Posts a new challenge. The endpoint used for the post request depends on whether the challenge 
 * is for the home page or not. If the post is successful, it will dispatch an action to update the state 
 * with the new challenge and clear any existing errors. If the post fails, it will dispatch an action 
 * to set errors based on the response data.
 * 
 * @async
 * @function
 * @param {Object} newChallenge - The challenge data to be posted.
 * @param {boolean} isHomeChallenge - Whether this is a challenge for the home page.
 * @returns {Promise<Object|null>} The response data after posting the challenge or null if an error occurs.
 */
export const postChallenge = (newChallenge, isHomeChallenge = false) => async (dispatch) => {
  const postlink = isHomeChallenge ? `${API_BASE_URL}/challenges/home` : `${API_BASE_URL}/challenges`;
  dispatch({ type: LOADING_UI });

  try {
    const res = await axios.post(postlink, newChallenge);

    if (res && res.data) {
      dispatch({
        type: POST_CHALLENGE,
        payload: res.data
      });
      clearErrors(dispatch);
      return res.data;
    }

    return null;
  } catch (error) {
    console.error('Error posting the challenge:', error);
    handleError(error, dispatch, 'postChallenge');
    return null;
  } finally {
    dispatch({ type: STOP_LOADING_UI });
  }
};

/**
 * Initiates a donation process by sending a donation request to the server.
 * 
 * @async
 * @function donateMoney
 * @param {Object} donation - The donation details to be sent to the server.
 * @param {function} dispatch - The dispatch function from Redux to dispatch actions.
 * @returns {Promise<Object>} A promise that resolves to the response data from the server if the donation is successful.
 * @description
 * This function is used in the donation page to handle the donation process. It dispatches 
 * a loading action before making a POST request to the server with the donation details. 
 * If successful, it dispatches an action with the response data and clears any existing errors. 
 * In case of an error, it dispatches an error action with the error details.
 * 
 * Example usage:
 * ```
 * async callPostDonation(newDonation) {
 *   try {
 *     const { sessionId } = await this.props.donateMoney(newDonation);
 *     // further logic to handle successful donation
 *   } catch (err) {
 *     // handle errors
 *   }
 * }
 * ```
 */
export const donateMoney = (donation) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const res = await axios.post(`${API_BASE_URL}/payments/donate`, donation);
    
    if (res && res.data) {
      dispatch({
        type: POST_CHALLENGE,
        payload: res.data
      });
      clearErrors(dispatch);
      return res.data;
    }

    return null;
  } catch (error) {
    console.error("Error occurred during the donation process:", error);
    handleError(error, dispatch, 'donateMoney');
    return null;
  } finally {
    dispatch({ type: STOP_LOADING_UI });
  }
};

/**
 * Posts a new suggestion. If the post is successful, it will dispatch an action to update 
 * the state with the new suggestion and clear any existing errors. If the post fails, 
 * it will dispatch an action to set errors based on the response data.
 * 
 * @async
 * @function
 * @param {Object} newSuggestion - The suggestion data to be posted.
 * @returns {Promise<Object|null>} The response data after posting the suggestion or null if an error occurs.
 */
export const postSuggestion = (newSuggestion) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const res = await axios.post('/suggestion', newSuggestion);

    if (res && res.data) {
      dispatch({
        type: POST_SUGGESTION,
        payload: res.data
      });
      clearErrors(dispatch);
      return res.data;
    }
    return null;
  } catch (error) {
    console.error('Error posting the suggestion:', error);
    handleError(error, dispatch, 'postSuggestion');
    return null;
  } finally {
    dispatch({ type: STOP_LOADING_UI });
  }
};

/**
 * Searches for twitter users based on the provided challenge data. 
 * It will then filter and return only the verified users.
 * 
 * @async
 * @function
 * @param {Object} newChallenge - The challenge data to be used for searching Twitter users.
 * @returns {Promise<Array|null>} An array of verified twitter users or null if an error occurs.
 */
export const getTwitterUsers = (newChallenge) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const res = await axios.post(`${API_BASE_URL}/search/users/twitter`, newChallenge);

    if (res && res.data) {
      let verifiedUsers = JSON.parse(res.data).filter((user) => user.verified === true);
      return verifiedUsers;
    }
    return null;
  } catch (error) {
    console.error('Error fetching Twitter users:', error);
    handleError(error, dispatch, 'getTwitterUsers');
    return null;
  } finally {
    dispatch({ type: STOP_LOADING_UI });
  }
};

/**
 * Fetches the total funds raised for a particular challenge.
 * Once fetched, dispatches an action to update the state with the fetched amount.
 * 
 * @async
 * @function
 * @param {string} challengeId - The ID of the challenge.
 */
export const getTotalFundraised = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_BASE_URL}/challenges/${challengeId}/funds`);
    if (res && res.data) {
      dispatch({
        type: GET_TOTAL_RAISED,
        payload: { ...res.data, challengeId }
      });
    }
  } catch (error) {
    console.error('Error fetching total funds raised:', error);
    handleError(error, dispatch, 'getTotalFundraised');
  }
};

/**
 * Sends a request to accept a specific challenge.
 * Once the request is successful, dispatches an action to update the state with the challenge acceptance data.
 * 
 * @async
 * @function
 * @param {string} challengeId - The ID of the challenge.
 * @returns {Promise<Object|null>} The response data after accepting the challenge or null if an error occurs.
 */
export const acceptChallenge = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_BASE_URL}/challenges/${challengeId}/accept`);
    if (res && res.data) {
      dispatch({
        type: ACCEPT_CHALLENGE,
        payload: res.data
      });
      return res.data;
    }
    return null;
  } catch (error) {
    console.error('Error accepting the challenge:', error);
    handleError(error, dispatch, 'acceptChallenge');
    return null;
  }
};

/**
 * Decline a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to decline.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const declineChallenge = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_BASE_URL}/challenges/${challengeId}/decline`);

    if (res.data) {
      dispatch({
        type: DECLINE_CHALLENGE,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    console.error("Error while declining challenge:", error);
    handleError(error, dispatch, 'declineChallenge');
  }
};

/**
 * Let others decide on a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge for others to decide.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const othersDecideChallenge = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_BASE_URL}/challenges/${challengeId}/decide`);

    if (res.data) {
      dispatch({
        type: OTHERS_DECIDE_CHALLENGE,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    console.error("Error while letting others decide on challenge:", error);
    handleError(error, dispatch, 'othersDecideChallenge');
  }
};

/**
 * Complete a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to complete.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const completeChallenge = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_BASE_URL}/challenges/${challengeId}/complete`);

    if (res.data) {
      dispatch({
        type: COMPLETE_CHALLENGE,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    console.error("Error while completing challenge:", error);
    handleError(error, dispatch, 'completeChallenge');
  }
};

/**
 * Fail a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to mark as failed.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const failChallenge = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_BASE_URL}/challenges/${challengeId}/fail`);

    if (res.data) {
      dispatch({
        type: FAIL_CHALLENGE,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    console.error("Error while marking challenge as failed:", error);
    handleError(error, dispatch, 'failChallenge');
  }
};

/**
 * Cancel a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to cancel.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const cancelChallenge = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_BASE_URL}/challenges/${challengeId}/cancel`);

    if (res.data) {
      dispatch({
        type: CANCEL_CHALLENGE,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    console.error("Error while canceling challenge:", error);
    handleError(error, dispatch, 'cancelChallenge');
    return null;
  }
};

/**
 * Boost a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to boost.
 * @param {number} amount - The amount to boost the challenge by.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const boostChallenge = (challengeId, amount) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_BASE_URL}/challenges/${challengeId}/boost`, { amount });

    if (res.data) {
      dispatch({
        type: BOOST_CHALLENGE,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    console.error("Error while boosting challenge:", error);
    handleError(error, dispatch, 'boostChallenge');
    return null;
  }
};

/**
 * TODO: THIS IS DEPRECATED
 * Submit a clip for a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge.
 * @param {string} link - The link of the submitted clip.
 * @param {Function} dispatch - The dispatch function from Redux (currently not used, but kept for consistency).
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const submitClip = (challengeId, link) => async (dispatch) => {
  try {
    throw new Error("THIS IS DEPRECATED. GET ON THE SAME PAGE");
    const res = await axios.post(`/challenge/${challengeId}/submitClip`, { link });

    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.error("Error while submitting clip:", error);
    handleError(error, dispatch, 'submitClip');
    return null;
  }
};

/**
 * TODO: Investigate if thismore appropriate under a different route prefix and BE service
 * Report a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to report.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const reportChallenge = (challengeId, reason) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_BASE_URL}/challenges/${challengeId}/report`,  {reason} );

    if (res.data) {
      dispatch({
        type: REPORT_CHALLENGE,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    console.error("Error while reporting challenge:", error);
    handleError(error, dispatch, 'reportChallenge');
    return null;
  }
};

/**
 * Like a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to like.
 * @param {Function} dispatch - The dispatch function from Redux (not used, but kept for consistency).
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const likeChallenge = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_BASE_URL}/challenges/${challengeId}/like`);
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.error("Error while liking challenge:", error);
    handleError(error, dispatch, 'likeChallenge');
    return null;
  }
};

/**
 * Unlike a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to unlike.
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const unlikeChallenge = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_BASE_URL}/challenges/${challengeId}/unlike`);

    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.error("Error while unliking challenge:", error);
    handleError(error, dispatch, 'unlikeChallenge');
    return null;
  }
};

/**
 * Save a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to save.
 * @param {Function} dispatch - The dispatch function from Redux (not used, but kept for consistency).
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const saveChallenge = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_BASE_URL}/challenges/${challengeId}/save`);
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.error("Error while saving challenge:", error);
    handleError(error, dispatch, 'saveChallenge');
    return null;
  }
};

/**
 * Unsave a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to unsave.
 * @param {Function} dispatch - The dispatch function from Redux (not used, but kept for consistency).
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const unsaveChallenge = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_BASE_URL}/challenges/${challengeId}/unsave`);
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.error("Error while unsaving challenge:", error);
    handleError(error, dispatch, 'unsaveChallenge');
    return null;
  }
};

/**
 * Cast a vote on a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to vote on.
 * @param {string} voteType - The type of vote ('pass' or 'fail').
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const castVote = (challengeId, voteType) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_BASE_URL}/challenges/${challengeId}/vote`, { vote: voteType });

    if (res.data) {
      dispatch({
        type: voteType === 'pass' ? CAST_PASS_VOTE : CAST_FAIL_VOTE,
        payload: res.data,
      });
      return res.data;
    }
  } catch (error) {
    console.error(`Error while casting ${voteType} vote for challenge ${challengeId}:`, error);
    handleError(error, dispatch, 'castVote');
    return null;
  }
};

/**
 * Remove a vote on a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge from which the vote is removed.
 * @returns {Promise<Object>} - The data from the response, if successful.
 */
export const removeVote = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.delete(`${API_BASE_URL}/challenges/${challengeId}/vote`);

    if (res.data) {
      dispatch({
        type: REMOVE_VOTE,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    console.error(`Error while removing vote for challenge ${challengeId}:`, error);
    handleError(error, dispatch, 'removeVote');
  }
};

/**
 * Delete a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to be deleted.
 * @returns {Promise<Object>} - The data from the response, if successful.
 */
export const deleteChallenge = async (challengeId) => async (dispatch) => {
  try {
    const res = await axios.delete(`${API_BASE_URL}/challenges/${challengeId}`);

    if (res.data) {
      dispatch({ type: DELETE_CHALLENGE, payload: challengeId });
      return res.data;
    }
  } catch (error) {
    console.error(`Error while deleting challenge ${challengeId}:`, error);
    handleError(error, dispatch, 'deleteChallenge');
  }
};

/**
 * Get data for a specific user.
 *
 * @param {string} userHandle - The handle of the user whose data is to be fetched.
 * @returns {Promise<void>}
 */
export const getUserData = (userHandle) => async (dispatch) => {
  dispatch({ type: LOADING_DATA });

  try {
    const res = await axios.get(`${API_BASE_URL}/users/${userHandle}`);

    dispatch({
      type: SET_CHALLENGES,
      payload: res.data.challenges
    });
  } catch (error) {
    console.error(`Error while getting user data for ${userHandle}:`, error);
    handleError(error, dispatch, 'getUserData');
    dispatch({ type: SET_CHALLENGES, payload: null });
  } finally {
    dispatch({ type: LOADED_DATA });
  }
};

/**
 * Clear errors from the UI.
 *
 * @param {Function} dispatch - The dispatch function from Redux.
 */
export const clearErrors = (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

/**
 * Get checkout session from the server.
 *
 * @async
 * @function
 * @param {Object} sessionMeta - Metadata for the session.
 * @returns {Promise<Object>} - The checkout session data.
 */
export const getCheckoutSession = (sessionMeta) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const response = await axios.post('create-checkout-session', sessionMeta);

    if (response && response.data) {
      clearErrors(dispatch);
      return response.data;
    }
  } catch (error) {
    console.error('Error occurred while fetching the checkout session:', error);
    handleError(error, dispatch, 'getCheckoutSession');
    return null;
  } finally {
    dispatch({ type: STOP_LOADING_UI });
  }
};

/**
 * Fetch the configuration data.
 *
 * @async
 * @function
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The configuration data.
 * @throws {Error} When unable to fetch the configuration.
 */
export const fetchConfig = () => async (dispatch) => {
  try {
    const response = await axios.get('config');

    if (response && response.data) {
      clearErrors(dispatch);
      return response.data;
    }
  } catch (error) {
    console.error("Error fetching configuration data:", error);
    handleError(error, dispatch, 'fetchConfig');
    return null;
  }
};

/**
 * Fetches proof data from a selected source.
 *
 * @async
 * @param {string} platform - The selected platform (e.g., Steam, Fitbit).
 * @param {string} dataType - The type of data to fetch (e.g., activities, achievements).
 * @returns {Promise<Object|null>} The proof data or null if an error occurs.
 */
export const fetchProofData = (platform, dataType) => async (dispatch) => {
  if (!isValidPlatform(platform) || !isValidDataType(dataType)) {
    console.error('Invalid platform or data type');
    handleError(new Error('Invalid platform or data type'), dispatch, 'fetchProofData');
    return null;
  }

  dispatch({ type: LOADING_UI });

  try {
    const res = await axios.get(`${API_BASE_URL}/integrations/proof/${platform}/${dataType}`);

    if (res && res.data) {
      dispatch({ type: ADD_PROOF_DATA, payload: res.data });
      return res.data;
    }

    return null;
  } catch (error) {
    console.error('Error fetching proof data:', error);
    handleError(error, dispatch, 'fetchProofData');
    return null;
  } finally {
    dispatch({ type: STOP_LOADING_UI });
  }
};

/**
 * TODO: DELETE? ISN' THISDEPRECATED TO ALL HELL? WHEN DID IT SNEAK BACK IN
 * - MICHAEL "YUNG BIDNESS" CAMPBELL
 * 
 * Submits proof data for a challenge.
 *
 * @async
 * @param {string} challengeId - The ID of the challenge.
 * @param {Array} proofItems - The proof items to submit.
 * @param {Object} requirements - The requirements associated with the proof items.
 * @returns {Promise<void>}
 */
export const submitProof = (challengeId, proofItems, requirements) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const res = await axios.post(`/proof/submit`, {
      challengeId,
      proofItems,
      requirements,
    });

    if (res && res.data) {
      dispatch({ type: SUBMIT_PROOF_SUCCESS, payload: res.data });
      dispatch({ type: CLEAR_PROOF_DATA });  // Clear proof data after successful submission
    }
  } catch (error) {
    console.error('Error submitting proof data:', error);
    handleError(error, dispatch, 'submitProof');
    dispatch({ type: SUBMIT_PROOF_FAIL, payload: error.response ? error.response.data : 'Unknown error' });
    dispatch({ type: SET_ERRORS, payload: error.response ? error.response.data : 'Unknown error' });
  } finally {
    dispatch({ type: STOP_LOADING_UI });
  }
};

// Define your API endpoint
const TIKTOK_UPLOAD_ENDPOINT = '/api/tiktok/upload';

/**
 * Upload a TikTok video.
 *
 * @param {FormData} formData - The form data containing the video file and metadata.
 * @returns {Promise<Object>} - The data from the response, if successful.
 */
export const uploadTikTokVideo = (formData) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const response = await axios.post('/api/tiktok/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.data.success) {
      dispatch({
        type: SHOW_TOAST,
        payload: 'TikTok video uploaded successfully!',
      });
    } else {
      dispatch({
        type: SHOW_TOAST,
        payload: 'Failed to upload TikTok video.',
      });
      throw new Error('Failed to upload TikTok video.');
    }
  } catch (error) {
    console.error('Error uploading TikTok video:', error);
    handleError(error, dispatch, 'uploadTikTokVideo');
    dispatch({
      type: SHOW_TOAST,
      payload: 'Error uploading TikTok video.',
    });
  } finally {
    dispatch({ type: STOP_LOADING_UI });
  }
};

/**
 * Clears the proof data from the state.
 *
 * @function
 * @returns {Object} Action object with type CLEAR_PROOF_DATA.
 */
export const clearProofData = () => ({
  type: CLEAR_PROOF_DATA,
});

/**
 * Duplicate a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to duplicate.
 * @param {Object} userInfo - User information of the new challenge recipient.
 * @returns {Promise<Object>} - The data from the response, if successful.
 */
export const duplicateChallenge = (challengeId, userInfo) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const res = await axios.post(`${API_BASE_URL}/challenges/duplicate/${challengeId}`, { userInfo });
    if (res.data) {
      dispatch({
        type: SHOW_TOAST,
        payload: 'Challenge successfully duplicated! You can now complete it from your profile page!',
      });
      return res.data;
    }
  } catch (error) {
    console.error('Error while duplicating challenge:', error);
    handleError(error, dispatch, 'duplicateChallenge');
  } finally {
    dispatch({ type: STOP_LOADING_UI });
  }
};

/**
 * Fetches games based on a search query.
 *
 * @async
 * @param {string} query - The search query for games.
 * @param {number} [page=1] - The page number for pagination.
 * @param {number} [limit=20] - The number of results per page.
 * @returns {Promise<Object|null>} The game search response or null if an error occurs.
 * @typedef {Object} SearchableGame
 * @property {string} id - The game's unique identifier.
 * @property {string} name - The name of the game.
 * @property {boolean} [hasRepeatables] - Whether the game has repeatable challenges.
 * @property {boolean} [hasAchievements] - Whether the game has achievements.
 * @property {string[]} [platforms] - The platforms the game is available on.
 * @typedef {Object} GameSearchResponse
 * @property {SearchableGame[]} games - The list of games matching the search query.
 * @property {number} page - The current page number.
 * @property {number} limit - The number of results per page.
 * @property {number} total - The total number of matching games.
 */
export const fetchGames = (query, page = 1, limit = 20) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const res = await axios.get(`${API_BASE_URL}/search/games`, {
      params: { query, page, limit }
    });

    if (res && res.data) {
      dispatch({ type: SET_GAMES, payload: res.data.games });
      return res.data;
    }

    return null;
  } catch (error) {
    console.error('Error fetching games:', error);
    handleError(error, dispatch, 'fetchGames');
    return null;
  } finally {
    dispatch({ type: STOP_LOADING_UI });
  }
};

/**
 * Creates a new mass challenge.
 *
 * @param {Object} massChallengeData - The data for the mass challenge to be created.
 * @returns {Function} A Redux Thunk function that dispatches actions based on the API call result.
 */
export const createMassChallenge = (massChallengeData) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const res = await axios.post(`${API_BASE_URL}/mass-challenges`, massChallengeData);

    if (res && res.data) {
      dispatch({
        type: CREATE_MASS_CHALLENGE,
        payload: res.data.massChallengeId,
      });
      dispatch({ type: CLEAR_ERRORS });
      return res.data;
    }

    return null;
  } catch (error) {
    console.error('Error creating mass challenge:', error);
    handleError(error, dispatch, 'createMassChallenge');
    return null;
  } finally {
    dispatch({ type: STOP_LOADING_UI });
  }
};

/**
 * Accepts a mass challenge.
 *
 * @param {string} massChallengeId - The ID of the mass challenge to accept.
 * @returns {Function} A Redux Thunk function that dispatches actions based on the API call result.
 */
export const acceptMassChallenge = (massChallengeId) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const res = await axios.post(`${API_BASE_URL}/mass-challenges/${massChallengeId}/accept`);

    if (res && res.data) {
      dispatch({
        type: ACCEPT_MASS_CHALLENGE,
        payload: massChallengeId,
      });
      dispatch({ type: CLEAR_ERRORS });
      return res.data;
    }

    return null;
  } catch (error) {
    console.error('Error accepting mass challenge:', error);
    handleError(error, dispatch, 'acceptMassChallenge');
    return null;
  } finally {
    dispatch({ type: STOP_LOADING_UI });
  }
};

/**
 * Declines a mass challenge.
 *
 * @param {string} massChallengeId - The ID of the mass challenge to decline.
 * @returns {Function} A Redux Thunk function that dispatches actions based on the API call result.
 */
export const declineMassChallenge = (massChallengeId) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const res = await axios.post(`${API_BASE_URL}/mass-challenges/${massChallengeId}/decline`);

    if (res && res.data) {
      dispatch({
        type: DECLINE_MASS_CHALLENGE,
        payload: massChallengeId,
      });
      dispatch({ type: CLEAR_ERRORS });
      return res.data;
    }

    return null;
  } catch (error) {
    console.error('Error declining mass challenge:', error);
    handleError(error, dispatch, 'declineMassChallenge');
    return null;
  } finally {
    dispatch({ type: STOP_LOADING_UI });
  }
};

/**
 * Fetches the details of a mass challenge.
 *
 * @param {string} massChallengeId - The ID of the mass challenge to retrieve.
 * @returns {Function} A Redux Thunk function that dispatches actions based on the API call result.
 */
export const getMassChallengeDetails = (massChallengeId) => async (dispatch) => {
  dispatch({ type: LOADING_MASS_CHALLENGE });

  try {
    const res = await axios.get(`${API_BASE_URL}/mass-challenges/${massChallengeId}`);

    if (res && res.data) {
      dispatch({
        type: SET_MASS_CHALLENGE_DETAILS,
        payload: res.data.massChallenge,
      });
      return res.data.massChallenge;
    }

    return null;
  } catch (error) {
    console.error('Error fetching mass challenge details:', error);
    handleError(error, dispatch, 'getMassChallengeDetails');
    dispatch({
      type: SET_MASS_CHALLENGE_DETAILS,
      payload: {},
    });
    return null;
  } finally {
    dispatch({ type: LOADED_MASS_CHALLENGE });
  }
};

/**
 * Fetches the participant's status in a mass challenge.
 *
 * @param {string} massChallengeId - The ID of the mass challenge.
 * @param {string} participantId - The ID of the participant.
 * @returns {Function} A Redux Thunk function that dispatches actions based on the API call result.
 */
export const getParticipantStatus = (massChallengeId, participantId) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const res = await axios.get(
      `${API_BASE_URL}/mass-challenges/${massChallengeId}/participants/${participantId}`
    );

    if (res && res.data) {
      dispatch({
        type: SET_PARTICIPANT_STATUS,
        payload: res.data.participantStatus,
      });
      return res.data.participantStatus;
    }

    return null;
  } catch (error) {
    console.error('Error fetching participant status:', error);
    handleError(error, dispatch, 'getParticipantStatus');
    dispatch({
      type: SET_PARTICIPANT_STATUS,
      payload: {},
    });
    return null;
  } finally {
    dispatch({ type: STOP_LOADING_UI });
  }
};

/**
 * Fetches a list of mass challenges.
 *
 * @returns {Function} A Redux Thunk function that dispatches actions based on the API call result.
 */
export const getMassChallenges = () => async (dispatch) => {
  dispatch({ type: LOADING_DATA });

  try {
    const res = await axios.get(`${API_BASE_URL}/mass-challenges`);

    if (res && res.data) {
      dispatch({
        type: SET_MASS_CHALLENGES,
        payload: res.data.massChallenges,
      });
      return res.data.massChallenges;
    }

    return null;
  } catch (error) {
    console.error('Error fetching mass challenges:', error);
    handleError(error, dispatch, 'getMassChallenges');
    dispatch({
      type: SET_MASS_CHALLENGES,
      payload: [],
    });
    return null;
  } finally {
    dispatch({ type: LOADED_DATA });
  }
};

/**
 * Duplicate a specific challenge for multiple users.
 *
 * @param {string} challengeId - The ID of the challenge to duplicate.
 * @param {string[]} userIds - An array of user IDs to send the duplicated challenge to.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object[]>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
/*
export const duplicateChallengeForMultipleUsers = (challengeId, userIds) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const res = await axios.post(`${API_BASE_URL}/challenges/duplicate-multiple/${challengeId}`, { userIds });
    if (res.data) {
      dispatch({ type: CLEAR_ERRORS });
      dispatch({
        type: SHOW_TOAST,
        payload: 'Challenge successfully duplicated for multiple users!',
      });
      return res.data;
    }
  } catch (error) {
    console.error('Error while duplicating challenge for multiple users:', error);
    handleError(error, dispatch, 'duplicateChallengeForMultipleUsers');
  } finally {
    dispatch({ type: STOP_LOADING_UI });
  }
};

/**
 * Rate a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to rate.
 * @param {number} rating - The rating value (1-5).
 * @returns {Promise<Object>} - The data from the response, if successful.
 */
/*
export const rateChallenge = (challengeId, rating) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_BASE_URL}/challenges/${challengeId}/rate`, { rating });
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.error("Error while rating challenge:", error);
    handleError(error, dispatch, 'rateChallenge');
  }
};
*/