// meed/src/components/challenge/PostChallenge.js
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { getAnalytics, logEvent } from "firebase/analytics";
// MUI Stuff
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
// Redux stuff
import { connect } from 'react-redux';
import { postChallenge, clearErrors, fetchConfig } from '../../redux/actions/dataActions';
import Input from '@mui/material/Input'
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
// import Filter from 'bad-words';
import postChallengeStyles from './styles/postChallengeStyles';
import { getRandomChallenge } from '../../util/challengeGenerator';

const analytics = getAnalytics();

// const filter = new Filter();
class PostChallenge extends Component {
  state = {
    payAmount: 0,
    open: false,
    body: '',
    endDate: 'xxxxlarge',
    completeByDate: 'xxxxlarge',
    errors: {},
    twitterUsers: [],
    handle: '',
    timeout: null,
    loading: false,
    userHandleUrl: null,
    challengeCost: 0,
    mpChecked: true,
    disabled: false,
    goal: false,
    votingType: 'default',
    visibilityState: 'public',
    challengeType: 'user',
    randomChallenge: '',
  };
  async componentDidMount() {
    this.setState({
      randomChallenge: getRandomChallenge(), // Set the random challenge when the component mounts
    });
    try {
      const res = await this.props.fetchConfig();

      if (res) {
        const { publicKey, unitAmount, currency } = res;
        this.setState({
          unitAmount,
          currency,
          stripe: await loadStripe(publicKey),
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.twitterUsers) {
      this.setState({
        twitterUsers: nextProps.twitterUsers
      });
    }
  }
  handleChange = (event) => {
    if (event.target.name === 'payAmount' && event.target.value < 0) {
      event.target.value = 0
    }

    this.setState({ [event.target.name]: event.target.value });
  };
  handleSubmit = async (event) => {
    event.preventDefault();

    if (!this.state.title) {
      this.setState({
        disabled1: true,
        errors: { ...this.state.errors, title: 'Please enter a title' }
      });
      setTimeout(() => this.setState({ disabled1: false }), 5000);
      return;
    }

    this.setState({ disabled: true });

    try {
      const res = await this.props.postChallenge({
        challengeName: this.state.title,
        description: this.state.body,
        influencerHandle: this.props.userHandle,
        endDate: this.state.endDate,
        completeByDate: this.state.completeByDate,
        initialCreatorPaymentAmount: parseInt(this.state.payAmount),
        goal: this.state.goal,
        votingType: this.state.votingType,
        challengeType: this.state.challengeType,
        visibilityState: this.state.visibilityState,
      });

      this.handleResponse(res);
    } catch (err) {
      toast.error('Challenge not submitted!');
    } finally {
      setTimeout(() => this.setState({ disabled: false }), 5000);
    }

    logEvent(analytics, 'post_challenge', {
      currentUserHandle: this.props.user.authenticated ? this.props.user.credentials.handle : 'anon'
    });
  };

  handleResponse = (res) => {
    if (res && res.challengeData) {
      let chall = res.challengeData;
      if (chall.challengeName) {
        toast.success('SUCCESS: Challenge submitted!');
        this.props.viewChall(chall);
        setTimeout(() => this.props.refresh(this.props.userHandle), 1000);
      } else {
        toast.error('Challenge not submitted!');
        this.displayError(res);
      }
    } else {
      this.displayError(res);
    }
  };

  displayError = (res) => {
    toast.error('Challenge not submitted!');
    if (res && res.error) {
      toast.error(res.error);
    }
  };

  render() {
    const { errors } = this.state;
    const {
      classes,
      user: {
        authenticated,
        credentials: { handle }
      },
      influencerId, // Ensure this prop is passed from the parent component
      bannedBy // This comes from Redux state
    } = this.props;

    // Check if the current user is banned by the influencer by looking for a matching influencerId in the bannedBy entries
    let isBanned;
    if (bannedBy) {
      isBanned = bannedBy.some(banEntry => banEntry.influencerId === influencerId);
    }

    return (
      <Fragment>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <form onSubmit={this.handleSubmit}>
              <Typography variant="body1" className={classes.pageText}>
                Challenge Title
              </Typography>
              <TextField
                variant="standard"
                InputProps={{ disableUnderline: true, maxLength: 40, }}
                name="title"
                type="text"
                multiline
                rows="3"
                // TODO: Put the character limit here?
                placeholder={this.state.randomChallenge}
                inputProps={{
                  maxLength: 80,
                  disableUnderline: true,
                  className: classes.whiteTxt
                }}
                helperText={errors.title}
                error={errors.title ? true : false}
                className={classes.textField}
                onChange={this.handleChange}
                fullWidth />
              <br></br>
              <br></br>
              <hr className={classes.horizLine}></hr>
              <br></br>
              <br></br>
              <Grid className={classes.wrapper} container>
                <Grid className={classes.gridSpacingNone} item sm={6} xs={6}>
                  <Tooltip title='This sets how much time this user will have to complete your challenge once it is accepted' placement="top">
                    <Typography variant="body1" className={classes.pageText}>
                      Time to Complete
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid className={classes.gridSpacingNone} item sm={6} xs={6}>
                  <Select
                    variant="standard"
                    disableUnderline={true}
                    className={`${classes.endDate} ${classes.textField}`}
                    id="date"
                    name='completeByDate'
                    value={this.state.completeByDate}
                    onChange={this.handleChange}>
                    <MenuItem value={'small'}>30 Minutes</MenuItem>
                    <MenuItem value={'medium'}>1 hour</MenuItem>
                    <MenuItem value={'large'}>6 hours</MenuItem>
                    <MenuItem value={'xlarge'}>12 hours</MenuItem>
                    <MenuItem value={'xxlarge'}>1 Day</MenuItem>
                    <MenuItem value={'xxxlarge'}>3 Days</MenuItem>
                    <MenuItem value={'xxxxlarge'}>6 Days</MenuItem>
                  </Select>
                </Grid>
                <Grid className={classes.gridSpacingNone} item sm={6} xs={6}>
                  <Tooltip title='This sets the voting type for your challenge' placement="top">
                    <Typography variant="body1" className={classes.pageText}>
                      Voting Type
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid className={classes.gridSpacingNone} item sm={6} xs={6}>
                  <Select
                    variant="standard"
                    disableUnderline={true}
                    className={`${classes.endDate} ${classes.textField}`}
                    id="votingType"
                    name='votingType'
                    value={this.state.votingType}
                    onChange={this.handleChange}>
                    <MenuItem value={'default'}>Default</MenuItem>
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={'both'}>Both</MenuItem>
                    <MenuItem value={'boost'}>Boost</MenuItem>
                    <MenuItem value={'creator'}>Creator</MenuItem>
                    {/* <MenuItem value={'custom'}>Custom. Only users selected by the creator get an official vote.</MenuItem>*/}
                  </Select>
                </Grid>
                <Grid className={classes.gridSpacingNone} item sm={6} xs={6}>
                  <Tooltip title={this.state.goal === 'No' ?
                    `Send your money to this user! This sets how much money you will give this user when the challenge is completed.
                   If the challenge expires or is failed, your money will be added back to your account.`
                    : `Create a goal for others to see! Let everyone know how much it takes to do this challenge.`
                  } placement="top">
                    <Typography variant="body1" className={classes.pageText}>
                      {this.state.goal === 'No' ?
                        `Dollars Promised` :
                        `Goal Amount`
                      }
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid className={classes.gridSpacingNone} item sm={6} xs={6}>
                  <Input
                    disableUnderline={true}
                    className={`${classes.endDate} ${classes.textField}`}
                    placeholder="$10"
                    name='payAmount'
                    onChange={this.handleChange}
                    error={errors.body ? true : false}
                    type="number"
                  />
                </Grid>
              </Grid>
              <br></br>
              <br></br>
              <hr className={classes.horizLine}></hr>
              <br></br>
              <br></br>

              {!authenticated ? (
                <Button variant="contained" className={classes.submitButton} component={Link} to={`/signup`}>
                  Sign in to Create
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submitButton}
                  disabled={isBanned || this.state.disabled || this.state.disabled1 || this.state.disabled2} // Add isBanned to disable condition
                >
                  {isBanned ? ( // Check if the user is banned
                    "You are banned from creating challenges for this user"
                  ) : (
                    handle && this.props.userHandle && this.props.userHandle === handle ? (
                      this.state.goal === 'Yes' ? (
                        `Create Challenge, ${this.state.payAmount && parseInt(this.state.payAmount) !== 0 ? '$' + this.state.payAmount : '$0'} goal`
                      ) : (
                        `Create Challenge ${this.state.payAmount && parseInt(this.state.payAmount) !== 0 ? '$' + this.state.payAmount : ''}`
                      )
                    ) : (
                      `Create Challenge for $${this.state.challengeCost + parseInt(this.state.payAmount ? this.state.payAmount : 0)}`
                    )
                  )}
                </Button>
              )}
            </form>
          </CardContent>
        </Card>
      </Fragment>
    );
  }
}

PostChallenge.propTypes = {
  postChallenge: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  fetchConfig: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  UI: state.UI,
  user: state.user,
  stripeSession: state.stripeSession,
  bannedBy: state.user.bannedBy,
});

export default connect(
  mapStateToProps,
  { postChallenge, clearErrors, fetchConfig }
)(withStyles(postChallengeStyles)(PostChallenge));
