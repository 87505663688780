// meed/src/pages/styles/signupStyles.js
const signupStyles = (theme) => ({
    ...theme.custom,
    form: {
        marginTop: 64,
        background: '#fff',
        color: '#fff',
        width: '100%',
        height: 'calc(100vh - 64px)',
        textAlign: 'center',
        '@media (max-width: 600px)': {
          display: 'flex',
          flexWrap: 'wrap-reverse',
          height: 'unset',
          marginTop: 56,
        },
      },
      formLogin: { 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        maxWidth: '80%',
        '@media (max-width: 960px)': {
          width: '100%'
        },
      },
      socials: {
        display: 'flex',
        justifyContent: 'center'
      },
      buttonDefault: {
        display: 'inline-flex',
        background: 'transparent',
        color: '#7B5DF9',
        margin: 10,
        border: 'solid 1px #CFCFCF',
        borderRadius: '50%',
        width: '55px',
        height: '55px',
        fontSize: '1rem',
        minWidth: 'unset'
      },
      buttonSecondary: {
        background: '#7B5DF9',
        color: '#fff',
        display: 'block',
        width: '100%',
        padding: '18.5px 14px',
        borderRadius: '35px',
        marginLeft: 'auto',
        marginRight: 'auto'
      },
      label: {
        color: '#707070',
        marginBottom: 50
      },
      pageTitle2: {
        color: '#707070',
        textAlign: 'left',
        fontWeight: 500,
        minWidth: 420
      },
      gang: {
        height: '100%',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& label.Mui-focused': {
          color: '#fff',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#fff',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#fff',
          },
          '&:hover fieldset': {
            borderColor: '#fff',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#fff',
          },
        },
        '@media (max-width: 600px)': {
          height: '50vh'
        },
      },
      gang2: {
        '@media (max-width: 600px)': {
          height: 'unset',
          padding: 20
        },
      },
      cpiece: {
        display: 'flex',
        flexDirection: 'column',
        '@media (max-width: 960px)': {
          width: '100%'
        },
      },
      pageTitle: {
        fontSize: '4em',
        padding: '0 100px',
        fontWeight: 400,
        color: '#fff',
        '@media (max-width: 960px)': {
          fontSize: '3em',
          padding: '0 20px',
        },
      },
      modal: {
        textAlign: 'center'
      },
      bgImgTitle: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundImage: `linear-gradient(rgba(123, 93, 249, .76), rgba(123, 93, 249, .76)), url(https://firebasestorage.googleapis.com/v0/b/covenant-28c21.appspot.com/o/loginPic.jpg?alt=media&token=2cd939ff-b177-4f36-8c86-8c0585309a57)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      },
      extraAction: {
        color:'#707070'
      },
      extraAction2: {
        color:'#D8D8D8',
        margin: '0px 5px',
        fontSize: '1rem'
      },
      bold: {
        fontWeight: 500
      },
      purple: {
        color: '#7B5DF9'
      },
      remember: {
        display: 'flex',
        justifyContent: 'space-between'
      },
      loginInput: {
        margin: '20px 0',
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderRadius: 35,
            border: '1px solid #ced4da', // Add this line to set the border
          },
          '&:hover fieldset': {
            borderColor: '#007bff', // Optional: change border color when hovering
          },
          '&.Mui-focused fieldset': {
            borderColor: '#007bff', // Optional: change border color when the input is focused
          },
        },
      },      
      flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      horizLine: {
        display: 'inline-block',
        height: 1,
        background: '#D8D8D8',
        width: '30%',
        margin: '3.5px 0'
      },
      downArrow: {
        position: 'absolute',
        bottom: -25,
        width: 50,
        height: 50,
        background: '#fff',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1,
        borderRadius: '50%',
        color: '#7B5DF9',
        fontSize: '2rem',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'none',
        '@media (max-width: 600px)': {
          display: 'flex',
        },
      }
});

export default signupStyles;