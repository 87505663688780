/**
 * @file ErrorBoundary.tsx
 * 
 * A styled React component that serves as an error boundary to catch JavaScript errors in the component tree.
 * It displays a user-friendly error page with a background image, logs the errors, and provides navigation options
 * for the user to return home or contact support.
 * 
 * @module ErrorBoundary
 */

import React, { Component, ErrorInfo, ReactNode } from 'react';
import { withStyles, WithStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import { createStyles } from '@mui/styles';
import berryBg from '../../images/berryBg-removebg.png';

/**
 * Styles for the ErrorBoundary component.
 * @param {Theme} theme - The MUI theme object for styling.
 * @returns {Record<string, any>} The styles for the component.
 */
const styles = (theme: Theme) => createStyles({
  errorContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    top: 0,
    width: '100%',
    height: '100vh',
    textAlign: 'center',
    padding: theme.spacing(3),
    backgroundImage: `url(${berryBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  textContainer: {
    backgroundColor: '#fff',
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    maxWidth: '75%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  errorTitle: {
    fontSize: '3rem',
    fontWeight: 'bold',
    color: theme.palette.error.main,
    marginBottom: theme.spacing(4),
  },
  errorMessage: {
    fontSize: '1.5rem',
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(4),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: '20px',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    textTransform: 'none',
    color: '#fff',
  },
  homeButton: {
    backgroundColor: '#5E2A84',
    '&:hover': {
      backgroundColor: '#3C2872',
    },
  },
  contactButton: {
    backgroundColor: '#7289DA',
    '&:hover': {
      backgroundColor: '#5E76BA',
    },
  },
});

/**
 * Props for the ErrorBoundary component.
 * @interface Props
 * @extends {WithStyles<typeof styles>}
 */
interface Props extends WithStyles<typeof styles> {
  /**
   * The children components that will be rendered inside the ErrorBoundary.
   * @type {ReactNode}
   */
  children: ReactNode;
}

/**
 * State for the ErrorBoundary component.
 * @interface State
 */
interface State {
  /**
   * Indicates if an error has been caught.
   * @type {boolean}
   */
  hasError: boolean;

  /**
   * The error object that was caught, or null if no error.
   * @type {Error | null}
   */
  error: Error | null;
}

/**
 * ErrorBoundary component that catches JavaScript errors in the component tree,
 * logs them, and displays a styled fallback UI with navigation options.
 * 
 * @example
 * ```tsx
 * <ErrorBoundary>
 *   <YourComponent />
 * </ErrorBoundary>
 * ```
 * 
 * @class ErrorBoundary
 * @extends {Component<Props, State>}
 */
class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null
  };

  /**
   * Updates the component state when an error is thrown.
   * @param {Error} error - The error thrown.
   * @returns {State} The updated state.
   */
  public static getDerivedStateFromError(error: Error): State {
    return {
      hasError: true,
      error
    };
  }

  /**
   * Lifecycle method for catching errors. Logs the error and error information.
   * @param {Error} error - The error thrown.
   * @param {ErrorInfo} errorInfo - Additional information about the error.
   */
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    // TODO: Send to error reporting service
  }

  /**
   * Renders the fallback UI if an error is caught, otherwise renders the children.
   * @returns {ReactNode} The fallback UI or children.
   */
  public render() {
    const { classes } = this.props;

    if (this.state.hasError) {
      return (
        <div className={classes.errorContainer}>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.textContainer}>
                <Typography variant="h2" className={classes.errorTitle}>
                  Oops! Something went wrong
                </Typography>
                <Typography variant="h5" className={classes.errorMessage}>
                  {this.state.error?.message || 'An unexpected error occurred. Our team has been notified.'}
                </Typography>
                <div className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/"
                    className={`${classes.button} ${classes.homeButton}`}
                  >
                    Return Home
                  </Button>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/contact"
                    className={`${classes.button} ${classes.contactButton}`}
                  >
                    Contact Support
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withStyles(styles)(ErrorBoundary);
