// meed/src/components/challenge/styles/postChallengeStyles.js
const postChallengeStyles = (theme) => ({
    ...theme.custom,
    card: {
      background: '#7A5DF9',
      position: 'absolute',
      marginBottom: 20,
      borderRadius: '0px',
      maxWidth: '550px',
      margin: '0 auto',
      textTransform: 'uppercase',
      fontSize: 16,
      overflowX: 'hidden',
      overflow: 'auto',
      height: '100%',
      color: "#fff",
      '&::-webkit-scrollbar': {
        width: '100%',
        height: 4
      },
      '@media (max-width: 600px)': {
        position: 'relative'
      }
    },
    content: {
      padding: 25,
      objectFit: 'cover'
    },
    endDate: {
      width: '100%',
      fontSize: 12,
      '& ::after': {
        content: ''
      }
    },
    legalText: {
      color: "#828282",
      textAlign: 'center'
    },
    submitButton: {
      width: '100%',
      backgroundColor: "#22C7FC",
      color: 'white',
      borderRadius: '20px',
      marginBottom: '15px'
    },
    pageText: {
      marginBottom: '5px',
      fontSize: 12,
      color: '#fff'
    },
    progress: {
      position: 'absolute',
      right: '2rem'
    },
    twitterUsersList: {
      background: '#fff',
      opacity: '1',
      zIndex: '1',
      left: '0',
      width: '100%'
    },
    hidden: {
      display: 'none'
    },
    textField: {
      borderRadius: 0,
      background: '#6E55D9',
      color: '#fff',
      fill: '#fff',
      padding: '0px 5px',
      '& svg': {
        color: '#fff'
      },
    },
    horizLine: {
      borderTop: 'solid .5px rgba(90,91,87,.3)'
    },
    whiteBg: {
      background: '#fff'
    },
    whiteTxt: {
      color: '#fff'
    },
    checkbox: {
      padding: 5
    },
    goal: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      color: '#fff',
      '& span': {
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: '12px',
        color: '#fff'
      }
    }
  });

export default postChallengeStyles;