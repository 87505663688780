// meed/src/util/ProfileSkeleton.js
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import NoImg from '../images/no-img.png';
// MUI
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
// Icons
import LocationOn from '@mui/icons-material/LocationOn';
import LinkIcon from '@mui/icons-material/Link';
import CalendarToday from '@mui/icons-material/CalendarToday';

const styles = (theme) => ({
  ...theme.custom,
  paper: {
    height: 0,
    paddingTop: '56.25%',
    background: '#000',
    borderRadius: 0
  },
  handle: {
    height: 20,
    backgroundColor: theme.palette.primary.main,
    width: 60,
    margin: '0 auto 7px auto'
  },
  fullLine: {
    height: 15,
    backgroundColor: 'rgba(0,0,0,0.6)',
    width: '100%',
    marginBottom: 10
  },
  halfLine: {
    height: 15,
    backgroundColor: 'rgba(0,0,0,0.6)',
    width: '50%',
    marginBottom: 10
  },
  logout: {
    textAlign: 'center',
    marginTop: '10px',
    background: '#F1B6D4',
    color: '#fff'
  },
  home: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    minWidth: 545,
    '@media (max-width: 600px)': {
      minWidth: 'unset',
    },
    '& div': {
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    '& p': {
      color: '#fff',
      whiteSpace: 'nowrap',
      textAlign: 'center'
    }
  }
});

const ProfileSkeleton = (props) => {
  const { classes } = props;
  return (
    <Paper className={`${classes.paper} ${props.home ? classes.home : ''}`}>
      {props.home ? 
        <div className={classes.profile}>
          <p>Add a Clip or Video from Twitch!</p>
        </div>
        : <div></div>
      }
    </Paper>
  );
};

ProfileSkeleton.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProfileSkeleton);
