// meed/src/components/challenge/styles/searchStyles.js
const searchStyles = {
    active: {
        background: '#FFDF57 !important',
        color: '#5B5A57 !important'
    },
    searchHeader: {
        background: '#B464FA',
        color: '#fff',
        fontWeight: 700,
        textTransform: 'uppercase',
        width: '100%',
        marginTop: 64,
        display: 'flex',
        flexDirection: 'row',
        padding: '10px 32px',
        '& div': {
            padding: '6px 8px'
        },
        '& button': {
            marginLeft: 10,
            background: '#5D5294',
            color: '#B464FA',
            borderRadius: 0
        },
    },
    card: {
        position: 'relative',
        display: 'flex',
        marginBottom: 20,
        borderRadius: '20px'
    },
    image: {
        minWidth: 200
    },
    liveTip: {
        backgroundColor: '#FFDF57',
        position: 'absolute',
        left: '32px',
        top: '52px',
        padding: '5px',
        fontWeight: '700'
    },
    challWrap: {
        width: '100%',
        position: 'absolute',
        height: '100%',
    },
    challs: {
        width: '100%',
        flexDirection: 'column',
        height: '100%',
    },
    challsBox: {
        marginTop: 20,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        // '&::-webkit-scrollbar': {
        //   width: '100%',
        //   height: 4
        // },
        // '&::-webkit-scrollbar-track': {
        //   '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        //   outline: '1px solid slategrey'
        // },
        // '&::-webkit-scrollbar-thumb': {
        //   backgroundColor: '#B464FA',
        //   outline: '1px solid slategrey'
        // }
    },
    userCard: {
        backgroundColor: '#B464FA',
        position: 'relative',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 20,
        marginRight: 20,
        borderRadius: '0px',
        maxWidth: '350px',
        minWidth: '350px',
        width: '100%',
        color: '#fff',
    },
    yellowCircle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFDF57',
        color: '#5B5A57',
        borderRadius: '50%',
        position: 'absolute',
        top: '10px',
        right: '10px',
        width: '50px',
        height: '50px'
    },
    hideOverflow: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#fff',
        paddingRight: '55px',
        fontWeight: '700'
    },
    verifiedUser: {
        display: 'flex',
        marginBottom: '5px',
        color: '#fff',
        textTransform: 'uppercase'
    },
    profileImage: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        width: '50px',
        height: '50px',
        borderRadius: '100%',
        objectFit: 'cover',
        cursor: 'pointer'
    },
    content: {
        objectFit: 'cover',
        overflow: 'hidden',
        paddingBottom: '6px !important',
        padding: '16px',
        color: '#fff'
    },
    horizLine: {
        background: '#B464FA',
        width: '100%',
        height: 2
    },
    homeTitle: {
        padding: '5px 20px',
        fontSize: 20,
        textTransform: 'uppercase',
        marginBottom: 0,
        color: '#FFDF57'
    },
    searchUser: {
        background: '#B464FA',
        padding: 20,
        color: '#fff',
        height: '100%',
        textAlign: 'center'
    },
    searchInputDad: {
        width: '100%',
        textAlign: 'left',
    },
    searchInput: {
        padding: '0 20px',
        '& li': {
            width: '100%'
        },
        '& .ais-Hits-list': {
            listStyleType: 'none !important',
            margin: 0,
            padding: 0,
        },
        '& .ais-Hits-item': {
            width: 'unset'
        },
        '& button': {
            display: 'none'
        },
        '& input': {
            border: 'solid 1px rgba(0, 0, 0, 0.23)',
            background: '#ECEAE6',
            height: 30,
            width: '100%',
            padding: '5px',
            margin: 10,
            marginLeft: 0,
            borderRadius: 4,
            outline: 'none',
            '&::placeholder': {
                color: 'white',
                opacity: 1 /* Firefox */
            },
            '@media (max-width: 600px)': {
                width: '100%'
            },
        }
    },
    results: {
        display: 'flex',
        flexDirection: 'column',
        background: '#5B5A57',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '100%',
            height: 4
        },
    }
};

export default searchStyles;