//meed/src/config/redirects.ts

/**
 * @file Centralized configuration for URL redirects in the Meed application.
 * @summary Defines a map of source URLs to their corresponding destination URLs for redirection.
 */

/**
 * Type definition for redirect entries.
 */
type RedirectEntry = {
    from: string;
    to: string;
  };
  
  /**
   * Array of redirect rules.
   * Each entry defines a 'from' path and a 'to' path for redirection.
   */
  export const redirects: RedirectEntry[] = [
    { from: '/discord', to: '/integrations/discord' },
    { from: '/Discord', to: '/integrations/discord' },
    { from: '/DISCORD', to: '/integrations/discord' },
    { from: '/discordintegration', to: '/integrations/discord' },
    { from: '/discord-integration', to: '/integrations/discord' },
    // { from: '/twitch', to: '/integrations/twitch' }, // Commented out as it's not live yet
    // Add more redirects here as needed
  ];
  
  /**
   * A map of redirect rules for efficient lookups.
   */
  export const redirectMap: Map<string, string> = new Map(
    redirects.map(({ from, to }) => [from.toLowerCase(), to])
  );